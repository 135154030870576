import React from "react";
import { Offcanvas, Modal, Button as RbButton, Spinner } from "react-bootstrap";
import Button from "../../../../../components/Button";
import { useNavigate } from "react-router-dom";
import { getApi } from "../../../../../utils/ServiceManger";
import styles from "../../../bedDetails.module.scss";

const DischargeConfirmationModal = ({
  setShowDischargeConfirmationModal,
  showDischargeConfirmationModal,
  handleCloseDischargeConfirmationModal,
  patient,
}) => {
  const navigate = useNavigate();

  const handleDischargeConfirmed = () => {
    getApi(
      `patients/discharge?id=${patient?.id}`,
      (res) => {
        //Request succeeded, don't know if the operation succeeded

        if (res.status) {
          //Discharge succeeded

          //close the DischargeConfirmationModal
          setShowDischargeConfirmationModal(false);
          //TODO show success toast
          console.log("Discharge succeeded");
          //TODO should we clear all the details or just navigate to home?
          navigate("/");
        } else {
          //Discharge could not be done
          console.log("Discharge could not be done. Error:", res.message);

          //TODO show discharge failure toast
        }
      },
      (err) => {
        console.log(err?.message);
        //TODO show network failure toast
      }
    );
  };
  return (
    <Modal
      show={showDischargeConfirmationModal}
      onHide={handleCloseDischargeConfirmationModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={`p-3 gap-3 ${styles.discardModel}`}
      className={`${styles.discardModel1}`}
    >
      <>
        <Modal.Header bsPrefix="d-flex justify-content-center">
          <p className="typography--class-caption grey70 m-0">
            Are you sure you want to discharge the patient?
          </p>
        </Modal.Header>
        <Modal.Body bsPrefix="d-flex justify-content-center gap-4">
          <Button
            style={{
              width: "98px",
              fontSize: "14px",
            }}
            onClick={handleDischargeConfirmed}
            text="Yes"
          />
          <Button
            style={{
              width: "98px",
              fontSize: "14px",
            }}
            text="No"
            variant="secondary"
            onClick={handleCloseDischargeConfirmationModal}
          />
        </Modal.Body>
      </>
    </Modal>
  );
};

export default DischargeConfirmationModal;
