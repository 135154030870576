import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Backdrop from "../../components/Header/components/backdrop/BackDrop";
import SideDrawer from "../../components/Header/components/sidemenu/SideDrawer";
import downArrow from "../../assets/images/downArrow.png";
import Toolbar from "../../components/Header/Toolbar";
import styles from "./kpiDashboard.module.scss";
import { Skeleton } from "@mui/material";
import { getApi } from "../../utils/ServiceManger";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function KpiDashboard() {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [bedPreventData, setBedPreventData] = useState([]);
  const [fallAlertData, setFallAlertData] = useState([]);
  const [nurseSaveTime, setNurseSaveTime] = useState();
  const [repoSave, setRepoSave] = useState();
  const [repoAdherence, setRepoAdherence] = useState();

  const [totalOfflineHours, setTotalOfflineHours] = useState([]);
  const [loading, setLoading] = useState(false);

  const [rooms, setRooms] = useState([]);

  const [shift, setShift] = useState();

  const getRoomData = () => {
    setLoading(true);
    getApi(
      "rooms",
      (res) => {
        setLoading(false);
        if (res?.status) {
          setRooms(res?.data?.rooms);
        } else if (res?.message === "Not valid token, Please log in first") {
          navigate("/login");
        }
      },
      (err) => {
        if (err?.message === "Request failed with status code 403") {
          navigate("/login");
        }
      }
    );
  };
  const [selectedItem, setSelectedItem] = useState(
    rooms.length > 0
      ? { id: rooms[0].id, name: rooms.map((room) => room.name)[0] }
      : {}
  );

  const [showDropDown, setShowDropDown] = useState(false);
  const [selected, setSelected] = useState(false);

  function handleSelectItem(value) {
    const selectedItem = rooms.find((room) => room.name === value);
    if (selectedItem) {
      setSelected(true);
    }
    setSelectedItem({ id: selectedItem.id, name: selectedItem.name });
    setShowDropDown(false);
  }

  const popoverContent = (
    <Popover
      style={{ border: "none", borderRadius: "10px" }}
      id="popover-basic"
    >
      <Popover.Body className={styles.calendar}>
        {rooms.map((room, index) => {
          return (
            <Dropdown.Item
              key={index}
              className={`${styles.range}
               ${
                 selectedItem && selectedItem.name === room.name
                   ? styles.selected
                   : rooms.length == 1
                   ? styles.selectedData
                   : ""
               } 
              ${!selected ? styles.firstItem : ""} 
              `}
              onClick={(e) => handleSelectItem(room.name)}
            >
              {room.name}
            </Dropdown.Item>
          );
        })}
      </Popover.Body>
    </Popover>
  );

  const roomData =
    Object.keys(selectedItem).length === 0
      ? rooms.map((room) => room)[0]
      : selectedItem;

  useEffect(() => {
    getRoomData();
  }, []);

  //Dropdown

  // Yesterday
  const todayStartDate = new Date();
  todayStartDate.setHours(0, 0, 0, 0);

  const todDayEndDate = new Date();
  todDayEndDate.setHours(23, 59, 59, 999);

  // Yesterday
  const yesterdayStartDate = new Date();
  yesterdayStartDate.setDate(yesterdayStartDate.getDate() - 1);
  yesterdayStartDate.setHours(0, 0, 0, 0);

  const yesterdayEndDate = new Date();
  yesterdayEndDate.setDate(yesterdayEndDate.getDate() - 1);
  yesterdayEndDate.setHours(23, 59, 59, 999);

  //This Week
  const today = new Date();
  const dayOfWeek = today.getDay();
  const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
  const startOfWeek = new Date(today.setDate(diff));
  startOfWeek.setHours(0, 0, 0, 0);

  //This Month
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  startOfMonth.setHours(0, 0, 0, 0);

  const [startDate, setStartDate] = useState(todayStartDate);
  const [endDate, setEndDate] = useState(todDayEndDate);
  const [selectedRange, setSelectedRange] = useState("Today");
  const [show, setShow] = useState(false);

  const handleRangeClick = (range) => {
    setSelectedRange(range);
    setShow(false);

    if (range === "Today") {
      setStartDate(todayStartDate);
      setEndDate(todDayEndDate);
    } else if (range === "Yesterday") {
      setStartDate(yesterdayStartDate);
      setEndDate(yesterdayEndDate);
    } else if (range === "This Week") {
      setStartDate(startOfWeek);
      setEndDate(new Date());
    } else if (range === "This Month") {
      setStartDate(startOfMonth);
      setEndDate(new Date());
    }
  };

  const popoverDate = (
    <Popover
      style={{ border: "none", borderRadius: "10px" }}
      id="popover-basic"
    >
      <Popover.Body className={styles.calendar}>
        <Dropdown.Item
          onClick={() => handleRangeClick("Today")}
          className={`${styles.range} ${
            selectedRange === "Today" ? styles.selectedFirst : ""
          }`}
        >
          Today
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => handleRangeClick("Yesterday")}
          className={`${styles.range} ${
            selectedRange === "Yesterday" ? styles.selectedSecond : ""
          }`}
        >
          Yesterday
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => handleRangeClick("This Week")}
          className={`${styles.range} ${
            selectedRange === "This Week" ? styles.selectedSecond : ""
          }`}
        >
          This week
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => handleRangeClick("This Month")}
          className={`${styles.range} ${
            selectedRange === "This Month" ? styles.selectedThird : ""
          }`}
        >
          This Month
        </Dropdown.Item>
      </Popover.Body>
    </Popover>
  );

  const getData = () => {
    setLoading(true);
    getApi(
      `rooms/beds?room_id=${roomData?.id}`,
      (res) => {
        if (res?.status) {
          setData(res?.data?.beds);
          //for REPOSITION api
        } else if (res?.message === "Not valid token, Please log in first") {
          navigate("/login");
        }
      },
      (err) => {
        if (err?.message === "Request failed with status code 403") {
          navigate("/login");
        }
      }
    );
  };

  // total patient Monitored
  const arr = data.map((d) => d.is_occupied == true);
  const totalPatient = arr.filter((value) => value === true);

  // total monitoring hours
  const room = rooms.find((room) => room.id === roomData?.id);
  const minDate = new Date(room?.createdAt);

  const now = new Date();
  const timeDiffMs = now.getTime() - minDate.getTime();
  const hoursDiff = timeDiffMs / 3600000;

  const getTotalMonitoringHours = () => {
    setLoading(true);
    getApi(
      `statistics/totalOfflineHours/${roomData?.id}`,
      (res) => {
        if (res?.status) {
          setTotalOfflineHours(res.data.logs);
          setLoading(false);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  //total offline hr
  let totalTimeDiffMs = 0;
  for (let i = 0; i < totalOfflineHours.total_OfflineHoursCount; i++) {
    const createdAt = new Date(
      totalOfflineHours.total_OfflineHours[i].created_at
    );
    const responseTime = new Date(
      totalOfflineHours.total_OfflineHours[i].response_time
    );
    const timeDiffMs = responseTime.getTime() - createdAt.getTime();
    totalTimeDiffMs += timeDiffMs;
  }
  const totalTimeDiffSec = totalTimeDiffMs / 1000;

  const hospital_id = localStorage.getItem("Id");

  const getShift = (e) => {
    getApi(
      `shifts/room/${hospital_id}`,
      (res) => {
        if (res) {
          setShift(res?.data?.shift);
        } else if (res?.message === "Not valid token, Please log in first") {
          navigate("/login");
        }
      },
      (err) => {
        if (err?.message === "Request failed with status code 403") {
          navigate("/login");
        }
      }
    );
  };

  useEffect(() => {
    getTotalMonitoringHours();
    getData();
    getShift();
  }, [startDate, endDate, roomData]);

  //all Statistics

  const [selectedOption, setSelectedOption] = useState("Overall");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const getBedExitLogs = (startDate, endDate) => {
    getApi(
      `statistics/bedExitPrevent/${selectedOption}?room_id=${
        roomData?.id
      }&start_date=${moment(startDate).format(
        "DD/MM/YYYY hh:mm:ss a"
      )}&end_date=${moment(endDate).format("DD/MM/YYYY hh:mm:ss a")}`,
      (res) => {
        if (res?.status) {
          setBedPreventData(res.data.logs);
          setLoading(false);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getFallAlertLogs = (startDate, endDate) => {
    getApi(
      `statistics/fallAlertPrevent/${selectedOption}?room_id=${
        roomData?.id
      }&start_date=${moment(startDate).format(
        "DD/MM/YYYY hh:mm:ss a"
      )}&end_date=${moment(endDate).format("DD/MM/YYYY hh:mm:ss a")}`,
      (res) => {
        if (res?.status) {
          setFallAlertData(res.data.logs);
          setLoading(false);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getSavedNurseTime = (startDate, endDate) => {
    setLoading(true);
    getApi(
      `statistics/savedNurseTime/${selectedOption}?room_id=${
        roomData?.id
      }&start_date=${moment(startDate).format(
        "DD/MM/YYYY hh:mm:ss a"
      )}&end_date=${moment(endDate).format("DD/MM/YYYY hh:mm:ss a")}`,
      (res) => {
        if (res?.status) {
          setNurseSaveTime(res.data.logs.total_savedTime);
          setLoading(false);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getRepoDetails = () => {
    getApi(
      `statistics/repoSaved/${selectedOption}?room_id=${
        roomData?.id
      }&start_date=${moment(startDate).format(
        "DD/MM/YYYY hh:mm:ss a"
      )}&end_date=${moment(endDate).format("DD/MM/YYYY hh:mm:ss a")}`,
      (res) => {
        if (res?.status) {
          setRepoAdherence(res.data.logs.total_repoAdherence);
          setRepoSave(res.data.logs.total_RepoSaved);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getRepoDetails(startDate, endDate);
    getSavedNurseTime(startDate, endDate);
    getFallAlertLogs(startDate, endDate);
    getBedExitLogs(startDate, endDate);
  }, [startDate, endDate, roomData, selectedOption]);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  const backDropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  let backdrop;

  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backDropClickHandler} />;
  }

  return (
    <>
      <Toolbar drawerToggleClickHandler={drawerToggleClickHandler} />
      <SideDrawer show={sideDrawerOpen} />
      {backdrop}
      <section>
        <div className="d-flex flex-column flex-md-row">
          <div
            className={`col-12 col-md-3 py-4 border-end-0 ${styles.headingLeft}`}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                className={`d-flex justify-content-between align-items-center `}
              >
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popoverContent}
                  show={showDropDown}
                  onHide={() => setShowDropDown(false)}
                >
                  <h5
                    style={{ cursor: "pointer" }}
                    className={`typography--class-h5 font-face-gr text-center cureousGrey`}
                    onClick={() => setShowDropDown(!showDropDown)}
                  >
                    {selectedItem && !roomData
                      ? selectedItem.name
                      : roomData?.name}
                    <img
                      src={downArrow}
                      className={`ms-3 mb-1 ${styles.dropdownArrow}`}
                    />
                  </h5>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className={`col-12 col-md-9 ${styles.heading}`}>
            <div className="d-flex flex-column flex-md-row px-4 py-3 py-md-0 align-items-center justify-content-between h-100">
              <div className="d-flex flex-column mt-2">
                <p
                  className={`typography--class-caption fw-normal mb-1 mb-md-2 grey50`}
                >
                  Summary View
                </p>
                <h5
                  className={`typography--class-h5 font-face-gr text-center cureousGrey`}
                >
                  Statistics
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.overall}>
          <div>
            <h5
              className={`typography--class-h5 font-face-gr ${styles.overallHeader}`}
            >
              Key Performance Indicators (KPI)
            </h5>
            <p className={`typography--class-caption ${styles.headerPara}`}>
              Key Performance Indicators in your unit with Insights to Improve
              the standard of care.
            </p>
          </div>

          <div className={`gap-2 ${styles.headerBox}`}>
            <div className={styles.overallBox}>
              {totalTimeDiffMs == 0 ? (
                <h3
                  className={`typography--class-h3 font-face-gr ${styles.overallBoxHeader}`}
                >
                  <Skeleton style={{ width: "68px" }} />
                </h3>
              ) : (
                <h3
                  className={`typography--class-h3 font-face-gr ${styles.overallBoxHeader}`}
                >
                  {hoursDiff && totalTimeDiffSec !== 0
                    ? Math.floor(hoursDiff) -
                      Math.floor(totalTimeDiffSec / 3600)
                    : 0}
                </h3>
              )}
              <p
                style={{ width: "93px" }}
                className={`typography--class-caption ${styles.headerBoxPara}`}
              >
                Total monitoring Hours
              </p>
            </div>
            <div className={styles.overallBox}>
              {totalPatient == undefined ? (
                <h3
                  className={`typography--class-h3 font-face-gr ${styles.overallBoxHeader}`}
                >
                  <Skeleton style={{ width: "35px" }} />
                </h3>
              ) : (
                <h3
                  className={`typography--class-h3 font-face-gr ${styles.overallBoxHeader}`}
                >
                  0{totalPatient.length}
                </h3>
              )}

              <p
                style={{ width: "64px" }}
                className={`typography--class-caption ${styles.headerBoxPara}`}
              >
                Patients Monitored
              </p>
            </div>
          </div>
        </div>

        <div className={styles.bgGrey}>
          <div>
            <div
              className={`d-flex justify-content-between align-items-center `}
            >
              <div
                className={`d-flex justify-content-between align-items-center `}
              >
                <h6
                  className={`typography--class-caption ${
                    selectedOption === "Overall"
                      ? styles.boxShiftClicked
                      : styles.boxShiftHeader
                  }`}
                  onClick={() => handleOptionClick("Overall")}
                >
                  Overall
                </h6>
                {shift && (
                  <>
                    <h6
                      className={`typography--class-caption ${
                        selectedOption === "Morning"
                          ? styles.boxShiftClicked
                          : styles.boxShiftHeader
                      }`}
                      onClick={() => handleOptionClick("Morning")}
                    >
                      Morning
                    </h6>
                    {shift?.total_shift > 2 && (
                      <h6
                        className={`typography--class-caption ${
                          selectedOption === "Evening"
                            ? styles.boxShiftClicked
                            : styles.boxShiftHeader
                        }`}
                        onClick={() => handleOptionClick("Evening")}
                      >
                        Evening
                      </h6>
                    )}
                    <h6
                      className={`typography--class-caption  ${
                        selectedOption === "Night"
                          ? styles.boxShiftClicked
                          : styles.boxShiftHeader
                      }`}
                      onClick={() => handleOptionClick("Night")}
                    >
                      Night
                    </h6>
                  </>
                )}
              </div>
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={popoverDate}
                show={show}
                onHide={() => setShow(false)}
              >
                <button
                  className="typography--class-caption grey50"
                  onClick={() => setShow(!show)}
                >
                  {selectedRange}
                  <img src={downArrow} className={`ms-3 ${styles.downArrow}`} />
                </button>
              </OverlayTrigger>
            </div>
          </div>
        </div>
        <div className={styles.background}>
          <div>
            <div className={`gap-2 ${styles.body}`}>
              <div className={styles.box}>
                <h4
                  className={`typography--class-h5 font-face-gr ${styles.boxHeader}`}
                >
                  BED EXIT PREVENTED
                </h4>
                <div className={styles.borderBottom}></div>
                <div className={styles.box1}>
                  {bedPreventData.length == 0 ? (
                    <h3
                      className={`typography--class-h3 font-face-gr ${styles.boxHeader1}`}
                    >
                      <Skeleton style={{ width: "30px" }} />
                    </h3>
                  ) : (
                    <h3
                      className={`typography--class-h3 font-face-gr  ${styles.boxHeader1}`}
                    >
                      {bedPreventData.total_bedPrevent}
                    </h3>
                  )}
                  <p className={`typography--class-caption ${styles.boxPara}`}>
                    Potential Bed Exits Prevented
                  </p>
                </div>
              </div>
              <div className={styles.box}>
                <h4
                  className={`typography--class-h5 font-face-gr ${styles.boxHeader}`}
                >
                  ROLL FALLS PREVENTED
                </h4>
                <div className={styles.borderBottom}></div>
                <div className={styles.box1}>
                  {fallAlertData.length == 0 ? (
                    <h3
                      className={`typography--class-h3 font-face-gr ${styles.boxHeader1}`}
                    >
                      <Skeleton style={{ width: "30px" }} />
                    </h3>
                  ) : (
                    <h3
                      className={`typography--class-h3 font-face-gr  ${styles.boxHeader1}`}
                    >
                      {fallAlertData.total_fallPrevent}
                    </h3>
                  )}

                  <p className={`typography--class-caption ${styles.boxPara}`}>
                    Bed Roll Falls Prevented
                  </p>
                </div>
              </div>
            </div>

            <div className={`gap-2 ${styles.body}`}>
              <div className={styles.box}>
                <h4
                  className={`typography--class-h5 font-face-gr ${styles.repositionBoxHeader}`}
                >
                  REPOSITION Saved
                </h4>
                <div className={styles.borderBottom}></div>
                <div className={styles.box1}>
                  {repoSave == undefined ? (
                    <h3
                      className={`typography--class-h3 font-face-gr ${styles.boxHeader1}`}
                    >
                      <Skeleton style={{ width: "34px" }} />
                    </h3>
                  ) : (
                    <h3
                      className={`typography--class-h3 font-face-gr ${styles.boxHeader1}`}
                    >
                      0{repoSave}
                    </h3>
                  )}

                  <p
                    style={{ width: "100px" }}
                    className={`typography--class-caption ${styles.boxPara}`}
                  >
                    Repositions Saved
                  </p>
                </div>
              </div>
              <div className={styles.box}>
                <h4
                  className={`typography--class-h5 font-face-gr ${styles.repositionBoxHeader}`}
                >
                  REPOSITION Adherence
                </h4>
                <div className={styles.borderBottom}></div>
                <div className={styles.boxBody}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className={styles.box1}>
                      {repoAdherence == undefined ? (
                        <h3
                          className={`typography--class-h3 font-face-gr ${styles.boxHeader1}`}
                        >
                          <Skeleton style={{ width: "34px" }} />
                        </h3>
                      ) : (
                        <h3
                          className={`typography--class-h3 font-face-gr ${styles.boxHeader1}`}
                        >
                          {repoAdherence % 1 !== 0
                            ? repoAdherence.toFixed(2) + `%`
                            : "NA"}
                        </h3>
                      )}

                      <p
                        style={{ width: "75px" }}
                        className={`typography--class-caption ${styles.RepoBoxPara}`}
                      >
                        Turn Adherence
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`gap-2 ${styles.body}`}>
              <div className={styles.box}>
                <h4
                  className={`typography--class-h5 font-face-gr ${styles.nursingBoxHeader}`}
                >
                  SAVED NURSING Hours{" "}
                </h4>
                <div className={styles.borderBottom}></div>
                <div className={styles.box1}>
                  {nurseSaveTime == undefined ? (
                    <h3
                      className={`typography--class-h3 font-face-gr ${styles.boxHeader1}`}
                    >
                      <Skeleton style={{ width: "36px" }} />
                    </h3>
                  ) : (
                    <h3
                      className={`typography--class-h3 font-face-gr ${styles.boxHeader1}`}
                    >
                      {nurseSaveTime == 0
                        ? nurseSaveTime
                        : (nurseSaveTime / 60).toFixed(1)}
                    </h3>
                  )}

                  <p className={`typography--class-caption ${styles.boxPara}`}>
                    Nursing Hours Saved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default KpiDashboard;
