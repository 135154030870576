import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "../src/containers/Home/home.module.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./containers/Login";
import Home from "./containers/Home";
import BedDetails from "./containers/BedDetails";
import History from "./containers/History";
import ProtectedRoute from "./components/ProtectedRoute";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { BACKEND_SERVER_MAP } from "./utils/constants";
import KpiDashboard from "./containers/KpiDashboard";
import { fetchToken, onMessageListener } from "./firebase";
import { toast, ToastContainer } from "react-toastify";
import useSound from "use-sound";
import beep from "./beep.mp3";
import logo from "./assets/images/repositionTimer.png";
import errorTriangle from "./assets/images/errorTriangle.png";
import nurseCreate from "./assets/images/nurseCreate.png";
import DeviceWifiSetting from "./containers/DeviceWifiSetting";
import moment from "moment";

// export const socket = io(`${process.env.REACT_APP_BACKEND_BASE_URL}`, {
export const socket = io(`${BACKEND_SERVER_MAP[window.location.origin]}`, {
  transports: ["websocket", "polling"],
});
const App = () => {
  const [nextTimeData, setNextTimeData] = useState("");

  function handleData(newData) {
    setNextTimeData(newData);
  }

  const [play, { stop }] = useSound(beep);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected to server");
    });
  }, []);

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({
    title: "",
    body: "",
  });
  const [isTokenFound, setTokenFound] = useState(false);
  fetchToken(setTokenFound);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      // console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  const successToast = () =>
    toast.error(
      <div className="d-flex align-items-center gap-3">
        <div className="w-100" style={{ paddingLeft: "1px" }}>
          <div className="justify-content-between border-0 pb-0 mb-1">
            <div className="d-flex align-items-center gap-2">
              <p className="typography--class-body cureousGrey fw-bold m-1">
                {notification.title.substring(0, 3)}
              </p>
              <div className={`rounded-2 ${styles.toastStatus}`}>
                <p className="typography--class-footer dYellow m-1">
                  {notification.title.substring(5)}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="typography--class-caption m-1">
              {notification.body}
              {/* <span className="green"></span> */}
            </p>
          </div>
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        toastId: 1,
      }
    );

  const offlineToast = () =>
    toast.error(
      <div className="d-flex align-items-center gap-3">
        <div className="w-100" style={{ paddingLeft: "1px" }}>
          <div className="justify-content-between border-0 pb-0 mb-1">
            <div className="d-flex align-items-center gap-2">
              <p className="typography--class-body cureousGrey fw-bold m-1">
                {notification.title.substring(0, 3)}
              </p>
              <div className={`rounded-2 ${styles.toastStatus}`}>
                <p className="typography--class-footer dYellow m-1">
                  {notification.title.substring(5)}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="typography--class-caption m-1">
              {notification.body}
              {/* <span className="green"></span> */}
            </p>
          </div>
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        toastId: 2,
      }
    );

  const repositionToast = () =>
    toast.warn(
      <div className="d-flex align-items-center gap-3">
        <div className="w-100" style={{ paddingLeft: "1px" }}>
          <div className="justify-content-between border-0 pb-0 mb-1">
            <div className="d-flex align-items-center gap-2">
              <p className="typography--class-body cureousGrey fw-bold m-1">
                {notification.title.substring(0, 3)}
              </p>
              <div className={`rounded-2 ${styles.toastStatus}`}>
                <p className="typography--class-footer dYellow m-1">
                  {notification.title.substring(5)}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="typography--class-caption m-1">
              {notification.body}
              {/* <span className="green"></span> */}
            </p>
          </div>
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        toastId: 3,
      }
    );

  const edgeToast = () =>
    toast.error(
      <div className="d-flex align-items-center gap-3">
        <div className="w-100" style={{ paddingLeft: "1px" }}>
          <div className="justify-content-between border-0 pb-0 mb-1">
            <div className="d-flex align-items-center gap-2">
              <p className="typography--class-body cureousGrey fw-bold m-1">
                {notification.title.substring(0, 3)}
              </p>
              <div className={`rounded-2 ${styles.toastStatus}`}>
                <p className="typography--class-footer dYellow m-1">
                  {notification.title.substring(5)}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="typography--class-caption m-1">
              {notification.body}
              {/* <span className="green"></span> */}
            </p>
          </div>
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        toastId: 4,
      }
    );

  const leavingToast = () =>
    toast.error(
      <div className="d-flex align-items-center gap-3">
        <div className="w-100" style={{ paddingLeft: "1px" }}>
          <div className="justify-content-between border-0 pb-0 mb-1">
            <div className="d-flex align-items-center gap-2">
              <p className="typography--class-body cureousGrey m-1">
                {notification.title.substring(0, 3)}
              </p>
              <div className={`rounded-2 ${styles.toastStatus}`}>
                <p className="typography--class-footer dYellow m-1">
                  {notification.title.substring(5)}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="typography--class-caption m-1">
              {notification.body}
              {/* <span className="green"></span> */}
            </p>
          </div>
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        toastId: 5,
      }
    );

  const callToast = () =>
    toast.info(
      <div className="d-flex align-items-center gap-3">
        <div className="w-100" style={{ paddingLeft: "1px" }}>
          <div className="justify-content-between border-0 pb-0 mb-1">
            <div className="d-flex align-items-center gap-2">
              <p className="typography--class-body cureousGrey fw-bold m-0">
                {notification.title.substring(0, 3)}
              </p>
              <div className={`rounded-2 ${styles.toastStatus}`}>
                <p className="typography--class-footer dYellow m-0">
                  {notification.title.substring(5)}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="typography--class-caption m-0">
              {notification.body}
              {/* <span className="green"></span> */}
            </p>
          </div>
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        toastId: 6,
      }
    );

  const RDoneToast = () =>
    toast.success(
      <div className="d-flex align-items-center gap-3">
        <div className="w-100" style={{ paddingLeft: "1px" }}>
          <div className="justify-content-between border-0 pb-0 mb-1">
            <div className="d-flex align-items-center gap-2">
              <p className="typography--class-body cureousGrey fw-bold m-0">
                {notification.title.substring(0, 3)}
              </p>
              <div className={`rounded-2 ${styles.toastStatusRE}`}>
                <p className="typography--class-footer dBlue m-0">
                  REPOSITION COMPLETE
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="typography--class-caption m-0">
              Patient has been successfully repositioned!
            </p>
          </div>
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        toastId: 7,
        className: styles.customToastNurse,
        progressClassName: styles.customProgressBar,
      }
    );

  const nextRepoTime = moment(notification.body.slice(18), "hh:mm A");

  const RRToast = () =>
    toast.success(
      <div className="d-flex align-items-center gap-3">
        <div className="w-100" style={{ paddingLeft: "1px" }}>
          <div className="justify-content-between border-0 pb-0 mb-1">
            <div className="d-flex align-items-center gap-2">
              <p className="typography--class-body cureousGrey fw-bold m-0">
                {notification.title.substring(0, 3)}
              </p>
              <div className={`rounded-2 ${styles.toastStatusRE}`}>
                <p className="typography--class-footer dBlue m-1">
                  REPOSITION RESCHEDULED
                </p>
              </div>
              <p className="typography--class-footer dBlue m-1">
                {`${moment(nextRepoTime)
                  .subtract(Number(notification.body.slice(10, 12)), "minutes")
                  .format("hh:mm A")} --> ${notification.body.slice(18)}`}
              </p>
            </div>
          </div>
          <div>
            <p className={`typography--class-caption  m-1 `}>
              Patient have substantially moved / assisted relieving high
              pressure regions.
            </p>
          </div>
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        icon: <img src={logo} style={{ width: "16px", height: "16px" }} />,
        toastId: 8,
        className: styles.customToastRR,
        progressClassName: styles.customProgressBar,
      }
    );

  const nurseToast = () =>
    toast.success(
      <div className="d-flex align-items-center gap-3">
        <div className="w-100" style={{ paddingLeft: "1px" }}>
          <div className="justify-content-between border-0 pb-0 mb-1">
            <div className="d-flex align-items-center gap-2">
              <p className="typography--class-body cureousGrey fw-bold m-0">
                {notification.body}
              </p>
              <div className={`rounded-2 ${styles.toastNurseStatus}`}>
                <p className="typography--class-footer dGreen m-1">
                  NEW NURSE ADDED
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className={`typography--class-caption `}>
              {notification.body} has been successfully added to the Nurse List
            </p>
          </div>
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        icon: (
          <img src={nurseCreate} style={{ width: "16px", height: "16px" }} />
        ),
        toastId: 9,
        className: styles.customNurseToast,
      }
    );

  const allocationToast = () => {
    toast.success(
      <div className="d-flex align-items-center gap-3">
        <div className="w-100" style={{ paddingLeft: "10px" }}>
          <div className="justify-content-between border-0 pb-0 mb-1">
            <div className="d-flex align-items-center gap-2">
              <p className="typography--class-body cureousGrey fw-bold m-0">
                Nurse Allocation Reminder
              </p>
              <div className={`rounded-2 ${styles.toastStatusNurse1}`}>
                <p className="typography--class-footer toastRed m-1">
                  {notification.title.substring(25)}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className={`typography--class-caption  m-0`}>
              {notification.body}
            </p>
          </div>
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        icon: false,
        toastId: 10,
        className: styles.customToastNurse,
        progressClassName: styles.customProgressBar,
      }
    );
  };

  const offlineRemainderToaster = () =>
    toast.success(
      <div className="d-flex align-items-center gap-3">
        <div className="w-100" style={{ paddingLeft: "1px" }}>
          <div className="justify-content-between border-0 pb-0 mb-1">
            <div className="d-flex align-items-center gap-2">
              <p className="typography--class-body cureousGrey fw-bold m-0">
                {notification.title.substring(0, 3)}
              </p>
              <div className={`rounded-2 ${styles.toastStatusOffline}`}>
                <p className="typography--class-footer offlineOrange m-0">
                  DEVICE OFFLINE - REMINDER
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="typography--class-caption m-0">
              1. Contact the IT Administrator for checking the internet
              connection.
            </p>
            <p className="typography--class-caption m-0">
              2. If the Wi-Fi is working fine, Contact Cureous for
              troubleshooting any hardware issue.
            </p>
          </div>
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        icon: (
          <img src={errorTriangle} style={{ width: "18px", height: "18px" }} />
        ),
        toastId: 11,
        className: styles.customToastNurse,
        progressClassName: styles.customProgressBar,
      }
    );

  useEffect(() => {
    if (notification.body == "Offline") {
      offlineToast();
    } else if (
      notification.body === "Bed Exit" ||
      notification.body === "Fall Alert"
    ) {
      successToast();
      play();
    } else if (notification.body === "Edge") {
      edgeToast();
      play();
    } else if (notification.body === "Reposition Alert") {
      repositionToast();
      play();
    } else if (notification.body === "Reposition Done") {
      RDoneToast();
    } else if (notification.body === "Patient Call") {
      callToast();
      play();
    } else if (
      notification.body === "Sitting Up" ||
      notification.body === "Leaving"
    ) {
      leavingToast();
    } else if (notification.body.substring(0, 9) == "Save time") {
      RRToast();
    } else if (notification.title == "NEW NURSE ADDED") {
      nurseToast();
    } else if (
      notification.body == "Please allot nurses to the remaining patients"
    ) {
      allocationToast();
    } else if (notification.body === "Offline Remainder") {
      offlineRemainderToaster();
    }
  }, [notification, play]);

  const Id = localStorage.getItem("Id");
  const room_id = localStorage.getItem("room_id");

  const allowedId = "1";

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<ProtectedRoute component={Home} />} />
          {!room_id && (
            <Route
              path="/kpi"
              element={<ProtectedRoute component={KpiDashboard} />}
            />
          )}

          {Id === allowedId && (
            <Route
              path="/device/wifi-setting"
              element={<ProtectedRoute component={DeviceWifiSetting} />}
            />
          )}

          <Route
            path="/bed/:id"
            element={
              <ProtectedRoute component={BedDetails} onData={handleData} />
            }
          />
          <Route
            path="/bed/:id/history"
            element={<ProtectedRoute component={History} />}
          />
          <Route path="*" element={<ProtectedRoute component={Home} />} />
        </Routes>
      </BrowserRouter>

      <ToastContainer
        position="bottom-right"
        toastStyle={{
          marginLeft: "100px",
          borderRadius: "8px",
          border: "1px solid lightgray",
        }}
        autoClose={10000}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;
