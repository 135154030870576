import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../Button";
import ForgotPassword from "../ForgotPassword";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./changePass.module.scss";
import Input from "../../../Input";
import { useFormik } from "formik";
import { postApi } from "../../../../utils/ServiceManger";

const ChangePassword = ({ showChangePass, setShowChangePass }) => {
  const [showForgotPass, setShowForgotPass] = useState(false);
  const [error, setError] = useState();

  const room_id = localStorage.getItem("room_id");

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      roomId: room_id,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("roomId", values.roomId);
      formData.append("currentPassword", values.currentPassword);
      formData.append("newPassword", values.newPassword);
      formData.append("confirmNewPassword", values.confirmNewPassword);
      postApi(
        "change-password",
        formData,
        (res) => {
          if (res?.status) {
            setShowChangePass(false);
            resetForm({ values: "" });
          } else {
            setError(res?.message);
          }
        },
        (err) => setError(err?.message)
      );
    },
  });

  const handleSubmit = async () => {
    if (formik.values.newPassword !== formik.values.confirmNewPassword) {
      formik.setFieldError("confirmNewPassword", "Passwords do not match");
      return;
    }

    formik.handleSubmit();
  };

  return (
    <div>
      <Modal
        show={showChangePass}
        onHide={() => {
          formik.resetForm();
          setShowChangePass(false);
        }}
        scrollable={true}
        size="lg"
        centered
        className={styles.Offcanvas1AddNurse1}
      >
        <Modal.Header className={styles.OffcanvasHeaderAddNurse}>
          <Modal.Title
            className={`typography--class-h1 font-face-gr ${styles.offCanvasTitleAddNurse}`}
          >
            Change Password
          </Modal.Title>
          <CloseIcon
            onClick={() => {
              setShowChangePass(false);
            }}
            style={{
              cursor: "pointer",
              color: "#8D8D8D",
            }}
          />
        </Modal.Header>
        <div className={`${styles.modelBody}`}>
          <div style={{ paddingBottom: "10px" }}>
            <Input
              value={formik.values.currentPassword}
              error={formik.errors.currentPassword}
              onChange={(e) => {
                formik.handleChange(e);
                setError("");
              }}
              onBlur={formik.handleBlur}
              label="Old Password"
              id="currentPassword"
              placeholder="**********"
              autocomplete="new-password"
            />
          </div>
          <div style={{ paddingBottom: "10px" }}>
            <Input
              value={formik.values.newPassword}
              error={formik.errors.newPassword}
              onChange={(e) => {
                formik.handleChange(e);
                setError("");
              }}
              onBlur={formik.handleBlur}
              label="New Password"
              id="newPassword"
              autocomplete="new-password"
              placeholder="**********"
            />
          </div>
          <div style={{ paddingBottom: "10px" }}>
            <Input
              value={formik.values.confirmNewPassword}
              onChange={(e) => {
                formik.handleChange(e);
                setError("");
              }}
              onBlur={formik.handleBlur}
              label="Confirm New Password"
              id="confirmNewPassword"
              autocomplete="new-password"
              placeholder="**********"
            />
          </div>

          <h5
            onClick={() => {
              setShowChangePass(false);
              setShowForgotPass(true);
            }}
            className={`typography--class-caption ${styles.keyPara}`}
          >
            Forgot Password?
          </h5>
          {error ? (
            <small className={`typography--class-footer red`}>
              {error == "Request failed with status code 500"
                ? "Password must contain at least one uppercase letter, digit and special character."
                : error}
            </small>
          ) : (
            <></>
          )}
          {formik.errors.confirmNewPassword && (
            <small className={`typography--class-footer red`}>
              {formik.errors.confirmNewPassword}
            </small>
          )}
        </div>
        <Modal.Footer
          className={`d-flex justify-content-evenly  ${styles.footer}`}
        >
          <Button type="submit" text="Save" onClick={handleSubmit} />
          <Button
            text="Cancel"
            variant="secondary"
            onClick={() => {
              formik.resetForm();
              setShowChangePass(false);
            }}
          />
        </Modal.Footer>
      </Modal>
      <ForgotPassword
        showForgotPass={showForgotPass}
        setShowForgotPass={setShowForgotPass}
      />
    </div>
  );
};

export default ChangePassword;
