import React, { useEffect, useRef, useState } from "react";
import styles from "../../bedDetails.module.scss";
import { useLocation } from "react-router-dom";
import { OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import downArrow from "../../../../assets/images/downArrow.png";
import { getApi } from "../../../../utils/ServiceManger";
import Calendar from "react-calendar";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import { socket } from "../../../../App";
import { Skeleton } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import xrange from "highcharts/modules/xrange";
import TooltipComponent from "../../../../components/Tooltip";
xrange(Highcharts);

let internalChartMobility = null;
let internalChartPressure = null;

const MobilityGraph = ({ patient, bedStatus }) => {
  const yesterdayDate = new Date();
  yesterdayDate.setHours(yesterdayDate.getHours() - 12);

  const location = useLocation();

  const [startDateGraph, setStartDateGraph] = useState(yesterdayDate);

  const [endDateGraph, setEndDateGraph] = useState(new Date());
  const [dateRange, setDateRange] = useState(endDateGraph);

  const chartComponent = useRef(null);
  const [mobilityToShow, setMobilityToShow] = useState(0);
  const [pressureReliefValue, setPressureReliefValue] = useState(0);

  const [chartOptions, setChartOptions] = useState([]);
  const [pressureRelief, setPressureRelief] = useState([]);
  const [history, setHistory] = useState([]);
  const [history1, setHistory1] = useState([]);
  const [data, setData] = useState([]);

  const interval = 12;
  const preprocessedData = [];
  for (let i = 0; i < chartOptions.length; i += interval) {
    const group = chartOptions.slice(i, i + interval);
    const sum = group.reduce((total, [timestamp, value]) => total + value, 0);
    const average = sum / group.length;
    const timestamp = group[group.length - 1][0];
    preprocessedData.push([timestamp, average]);
  }

  const filterLogs = (logs) => {
    // Filter logs to include only Out of Bed events
    return logs.filter((log) => log.log === "Out of Bed");
  };

  const sortLogsByTime = (logs) => {
    // Sort logs by alert_time field
    return logs.sort((a, b) => new Date(a.alert_time) - new Date(b.alert_time));
  };

  const getStartEndTimes = (logs) => {
    const startEndTimes = [];
    // Iterate through filtered and sorted logs to create an array of start and end times
    for (let i = 0; i < logs.length; i++) {
      const { alert_time, response_dateTime } = logs[i];

      if (response_dateTime) {
        const start = new Date(alert_time).getTime();
        const end = new Date(response_dateTime).getTime();
        const timeDifference = end - start;

        // Check if the time difference is greater than one minute (60000 milliseconds)
        if (timeDifference > 2 * 60 * 1000) {
          startEndTimes.push({
            start,
            end,
          });
        }
      }
    }
    return startEndTimes;
  };

  const filteredLogs = filterLogs(data);
  const sortedLogs = sortLogsByTime(filteredLogs);
  const startEndTimes = getStartEndTimes(sortedLogs);

  const filterOfflineLogs = (logs) => {
    // Filter logs to include only Out of Bed events
    return logs.filter((log) => log.log === "Offline");
  };

  const getStartEndTimesOffline = (logs) => {
    const startEndTimes = [];
    // Iterate through filtered and sorted logs to create array of start and end times

    for (let i = 0; i < logs.length; i++) {
      const { alert_time, response_dateTime, response_time } = logs[i];
      if (response_dateTime && Number(response_time.toString().slice(14, 16))) {
        startEndTimes.push({
          start: new Date(alert_time).getTime(),
          end: new Date(response_dateTime).getTime(),
        });
      }
    }
    return startEndTimes;
  };

  const filteredOfflineLogs = filterOfflineLogs(data);
  const sortedOfflineLogs = sortLogsByTime(filteredOfflineLogs);
  const startEndTimesOffline = getStartEndTimesOffline(sortedOfflineLogs);

  const options = {
    chart: {
      animation: false,
      height: "220px",
      opacity: 0.1,
      marginBottom: 70,
    },

    time: {
      useUTC: false,
    },

    tooltip: {
      xDateFormat: "%d-%m-%y, %l:%M:%S %p",
      shared: true,
      style: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 500,
      },
    },

    title: {
      text: "",
    },

    exporting: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    legend: {
      enabled: true,
      layout: "horizontal",
      align: "right",
      verticalAlign: "bottom",
      y: 30,
      symbolWidth: 0,
      symbolHeight: 0,
      squareSymbol: false,
      navigation: {
        enabled: false, // remove pagination text from legend
      },
      itemHoverStyle: {
        color: "#8D8D8D", // disable hover effect on legend items
      },
      itemStyle: {
        cursor: "pointer",
        color: "#8D8D8D",
        alignItems: "center",
        paddingLeft: "10px",
        fontWeight: 500,
        fontFamily: "Inter",
      },
      useHTML: true,
      labelFormatter: function () {
        var imageSrc = "";
        switch (this.name) {
          case "Reposition Done":
            imageSrc =
              "https://cureous.s3.us-east-2.amazonaws.com/repositionDone.png";
            break;
          case "Reposition Alert":
            imageSrc =
              "https://cureous.s3.us-east-2.amazonaws.com/repositionAlert.png";
            break;
          case "Bed Exit":
            imageSrc =
              "https://cureous.s3.us-east-2.amazonaws.com/outofbedicon.png";
            break;
          case "Offline":
            imageSrc = "https://cureous.s3.us-east-2.amazonaws.com/offline.png";
            break;
          default:
            break;
        }
        return (
          `<div class="${styles.dLImageLegendDiv}">` +
          `<img  src="${imageSrc}" class="${
            this.name == "Bed Exit"
              ? styles.dLImageLegendBed
              : this.name == "Offline"
              ? styles.dLOfflineImage
              : styles.dLImageLegend
          }" />` +
          `<p class=${styles.dLP1}>` +
          this.name +
          `</p>` +
          "</div>"
        );
      },
    },

    plotOptions: {
      series: {
        // findNearestPointBy: "xy",
        events: {
          legendItemClick: function () {
            const bedExitSeries = this.chart.get("bedExitSeries");
            const offlineSeries = this.chart.get("offlineSeries");

            if (this.name === "Offline") {
              // Toggle visibility of the "Bed Exit" series

              if (offlineSeries.visible) {
                bedExitSeries.update({ pointWidth: 300, maxPointWidth: 212 });
              } else {
                bedExitSeries.update({ pointWidth: 300, maxPointWidth: 190 });
              }
            } else if (this.name === "Bed Exit") {
              // Toggle visibility of the "Bed Exit" series

              if (bedExitSeries.visible) {
                offlineSeries.update({ pointWidth: 27, maxPointWidth: 4 });
              } else {
                offlineSeries.update({ pointWidth: 27, maxPointWidth: 25 });
              }
            }
            // Allow toggling visibility for other series
            return true;
          },
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },

    lang: {
      xrange: "My X-range Series Type",
    },
    series: [
      {
        // tooltip: {
        //   pointFormatter: function () {
        //     return '<span style="font-family: Inter;  font-weight: 500; font-size: 12px;">Mobility Factor</span>';
        //   },
        // },
        data: chartOptions.length > 10000 ? preprocessedData : chartOptions,
        type: "areaspline",
        lineWidth: 1,
        name: "Mobility Factor",
        states: {
          hover: {
            enabled: false,
          },
        },
        label: {
          style: {
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: 600,
          },
        },
        showInLegend: false,
        color: "#39b35a",
        fillOpacity: 0.1,
        marker: {
          enabled: false,
        },
        dataLabels: {
          useHTML: true,
          enabled: false,
          y: -5,
          formatter: function () {
            // console.log(this);
            if (this.y == 100) {
              return (
                // `<div class = ${styles.dL} >` +
                // `<p class = ${styles.dLP}>` +
                // "R" +
                // `</p>` +
                // "</div>"
                ""
              );
            } else if (this.y < 100 && this.y > 1) {
              return (
                // `<div class = ${styles.dL1} >` +
                // `<p class = ${styles.dLP}>` +
                // "R" +
                // `</p>` +
                // "</div>"
                ""
              );
            }
          },
        },
      },
      {
        data: pressureRelief,
        states: {
          hover: {
            enabled: false,
          },
        },
        type: "spline",
        name: "Pressure Relief",
        label: {
          style: {
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: 600,
          },
        },
        lineWidth: 1,
        dashStyle: "solid",
        showInLegend: false,
        color: "#FFAF2B",
        marker: {
          enabled: false,
        },
        dataLabels: {
          useHTML: true,
          enabled: false,
          y: -5,
          formatter: function () {
            // console.log(this);
            if (this.y == 100) {
              return (
                // `<div class = ${styles.dL} >` +
                // `<p class = ${styles.dLP}>` +
                // "R" +
                // `</p>` +
                // "</div>"
                ""
              );
            } else if (this.y < 100 && this.y > 1) {
              return (
                // `<div class = ${styles.dL1} >` +
                // `<p class = ${styles.dLP}>` +
                // "R" +
                // `</p>` +
                // "</div>"
                ""
              );
            }
          },
        },
      },
      {
        data: history,
        states: {
          hover: {
            enabled: false,
          },
        },
        tooltip: {
          pointFormatter: function () {
            return '<span style="font-family: Inter;  font-weight: 500; font-size: 12px;">Reposition Done</span>';
          },
        },
        type: "column",
        pointWidth: 0,
        name: "Reposition Done",
        color: "#39b35a",
        fillOpacity: 1,
        marker: {
          enabled: false,
        },
        dataLabels: {
          useHTML: true,
          enabled: true,
          y: -2,
          formatter: function () {
            // console.log(this)
            if (this.y == 300) {
              return (
                `<div class = "${styles.dL} ${styles.overlap}" >` +
                `<p class = ${styles.dLP}>` +
                "R" +
                `</p>` +
                "</div>"
              );
            } else if (this.y < 100 && this.y > 1) {
              return (
                `<div class = "${styles.dL1} ${styles.overlap}">` +
                `<p class = ${styles.dLP}>` +
                "R" +
                `</p>` +
                "</div>"
                // ""
              );
            }
          },
        },
      },
      {
        data: history1,
        states: {
          hover: {
            enabled: false,
          },
        },
        tooltip: {
          pointFormatter: function () {
            return '<span style="font-family: Inter;  font-weight: 500; font-size: 12px;">Reposition Alert</span>';
          },
        },
        type: "column",
        pointWidth: 0,
        name: "Reposition Alert",
        color: "#8ac0fe",
        fillOpacity: 1,
        marker: {
          enabled: false,
        },
        dataLabels: {
          useHTML: true,
          enabled: true,
          y: -2,
          formatter: function () {
            // console.log(this)
            if (this.y == 300) {
              return (
                `<div class = "${styles.dLAlert} ">` +
                `<p class = ${styles.dLP}>` +
                "R" +
                `</p>` +
                "</div>"
              );
            } else if (this.y < 100 && this.y > 1) {
              return (
                `<div class ="${styles.dL1}" >` +
                `<p class = ${styles.dLP}>` +
                "R" +
                `</p>` +
                "</div>"
                // ""
              );
            }
          },
        },
      },
      {
        tooltip: {
          pointFormatter: function () {
            return '<span style="font-family: Inter;  font-weight: 500; font-size: 12px;">Device Offline</span>';
          },
        },
        type: "xrange",
        name: "Offline",
        states: {
          hover: {
            enabled: false,
          },
        },
        id: "offlineSeries",
        marker: {
          symbol: "none",
        },
        borderRadius: 5,
        borderColor: "#EB5500",
        data: startEndTimesOffline.map((event) => ({
          x: event.start,
          x2: event.end,
          y: 0,
          color: "#EB5500",
        })),
        opacity: 0.9,
        pointWidth: 27,
        maxPointWidth: 25,
        pointPadding: 0,
        dataLabels: {
          useHTML: true,
          enabled: true,
          formatter: function () {
            return (
              `<div class="${styles.dLOfflineImageInsideDiv}">` +
              `<img src="https://cureous.s3.us-east-2.amazonaws.com/offline.png" class="${styles.dLOfflineImage}" />` +
              "</div>"
            );
          },
        },
      },
      {
        tooltip: {
          pointFormatter: function () {
            return '<span style="font-family: Inter;  font-weight: 500; font-size: 12px;">Bed Exit</span>';
          },
        },
        type: "xrange",
        states: {
          hover: {
            enabled: false,
          },
        },
        name: "Bed Exit",
        id: "bedExitSeries",
        marker: {
          symbol: "none",
        },
        borderRadius: 5,
        data: startEndTimes.map((event) => ({
          x: event.start,
          x2: event.end,
          y: 0,
          color: "#CF3636",
        })),
        pointWidth: 300,
        maxPointWidth: 190,
        opacity: 0.7,
        pointPadding: 0.1, // Padding between bars within a group
        groupPadding: 0.2,
        dataLabels: {
          useHTML: true,
          enabled: true,
          formatter: function () {
            return (
              `<div class="${styles.dLImageInsideDiv} ${styles.overlapBedExit}">` +
              `<img src="https://cureous.s3.us-east-2.amazonaws.com/outofbedicon.png" class="${styles.dLImage}" />` +
              "</div>"
            );
          },
        },
      },
    ],
    xAxis: [
      {
        type: "datetime",
        tickPositioner: function (min, max) {
          var ticks = this.tickPositions,
            newTicks,
            ticksLength = ticks.length - 1,
            range = ticks[1] - ticks[0],
            distance = 0.5;

          ticks[0] = moment(min).startOf("hour").add(10, "minute").valueOf(); // set first tick as start of hour + 1 minute
          // ticks[ticksLength] = max;

          // Prevent overlapping:
          // If left ticks are too close, remove one:
          if (ticks[1] - ticks[0] < range * distance) {
            ticks.splice(1, 1);
            ticksLength--;
          }
          if (this.toPixels(ticks[1]) - this.toPixels(ticks[0]) < 50) {
            newTicks = this.tickPositions.filter(function (el, i) {
              return i % 4 === 0;
            });

            newTicks.info = {
              unitName: "day",
              higherRanks: {},
              totalRange: newTicks[newTicks.length - 1] - newTicks[0],
            };
            return newTicks;
          }

          // If right ticks are too close, remove one:
          // if (ticks[ticksLength] - ticks[ticksLength - 1] < range * distance) {
          //   ticks.splice(ticksLength - 1, 1);
          // }
          return ticks;
        },
        tickInterval: 3600 * 1000,
        tickCount: 12,
        labels: {
          format: "{value:%l:%M %p }",
          overflow: "justify",
          style: {
            fontFamily: "Inter",
            fontSize: "11px",
            fontWeight: 500,
            color: "#8D9294",
          },
        },
      },
    ],

    yAxis: [
      {
        title: {
          text: null,
        },
        labels: {
          enabled: false,
        },
        softMax: 1,
        max: 300,
      },
    ],
  };

  useEffect(() => {
    socket.on("web/patient/mobility/change", (socketData) => {
      if (JSON.parse(socketData).bed_id === location?.state?.id) {
        internalChartMobility?.addPoint([
          new Date().getTime(),
          JSON.parse(socketData).factor,
        ]);
        setMobilityToShow(JSON.parse(socketData).factor);
      }
    });
  }, [internalChartMobility]);

  useEffect(() => {
    socket.on("web/patient/relief", (socketData) => {
      if (JSON.parse(socketData).bed_id === location?.state?.id) {
        internalChartPressure?.addPoint([
          new Date().getTime(),
          JSON.parse(socketData).pressureRelief,
        ]);
        setPressureReliefValue(JSON.parse(socketData).pressureRelief);
      }
    });
  }, [internalChartPressure]);

  const getLogs = (id, startDateGraph, endDateGraph) => {
    getApi(
      `patients/logs/mobility-factor?id=${id}&start_date=${moment(
        startDateGraph
      ).format("DD/MM/YYYY hh:mm:ss a")}&end_date=${moment(endDateGraph).format(
        "DD/MM/YYYY hh:mm:ss a"
      )}`,
      (res) => {
        if (res?.status) {
          setChartOptions(
            res?.data?.logs.map(({ alert_time, mobility_factor }) => [
              alert_time,
              Math.round((mobility_factor / 1000) * 200),
            ])
          );
        }
      },
      (err) => console.log(err)
    );
  };

  useEffect(() => {
    if (patient?.id) {
      getLogs(patient?.id, startDateGraph, endDateGraph);
    }
  }, [startDateGraph, endDateGraph, patient?.id]);

  const getPressureReliefLogs = (id, startDateGraph, endDateGraph) => {
    getApi(
      `patients/logs/pressure-relief?id=${id}&start_date=${moment(
        startDateGraph
      ).format("DD/MM/YYYY hh:mm:ss a")}&end_date=${moment(endDateGraph).format(
        "DD/MM/YYYY hh:mm:ss a"
      )}`,
      (res) => {
        if (res?.status) {
          setPressureRelief(
            res?.data?.logs.map(({ alert_time, pressure_relief }) => [
              alert_time,
              pressure_relief,
            ])
          );
        }
      },
      (err) => console.log(err)
    );
  };

  useEffect(() => {
    if (patient?.id) {
      getPressureReliefLogs(patient?.id, startDateGraph, endDateGraph);
    }
  }, [startDateGraph, endDateGraph, patient?.id]);

  const getHistory = (id, startDateGraph, endDateGraph) => {
    getApi(
      `patients/logs/history?id=${id}&start_date=${moment(
        startDateGraph
      ).format("DD/MM/YYYY hh:mm:ss a")}&end_date=${moment(endDateGraph).format(
        "DD/MM/YYYY hh:mm:ss a"
      )}&filter[]=graph`,
      (res) => {
        if (res?.status) {
          setData(res?.data?.logs);
          setHistory(
            res?.data?.logs.map(({ alert_time, log }) => [
              Date.parse(alert_time.toString()),
              log == "Reposition Done" && 300,
            ])
          );
          setHistory1(
            res?.data?.logs.map(({ alert_time, log }) => [
              Date.parse(alert_time.toString()),
              log == "Reposition Alert" && 300,
            ])
          );
        }
      },
      (err) => console.log(err)
    );
  };

  useEffect(() => {
    if (patient?.id) {
      getHistory(patient?.id, startDateGraph, endDateGraph);
    }
  }, [startDateGraph, endDateGraph, patient?.id]);

  const popoverDateGraph = (
    <Popover id="popover-basic">
      <Popover.Body className={styles.calendar}>
        <Calendar
          className={"border-0 rounded-3"}
          onChange={(res) => {
            setDateRange(res);
            setStartDateGraph(res[0]);
            setEndDateGraph(res[1]);
          }}
          selectRange={true}
          allowPartialRange={true}
          value={dateRange}
          tileClassName={"typography--class-body grey60"}
        />
      </Popover.Body>
    </Popover>
  );

  const afterChartCreated = (chart) => {
    if (!chart.options.chart.forExport) {
      internalChartMobility = chart.series[0];
      internalChartPressure = chart.series[1];
    }
  };

  const [lastData, setLastData] = useState("");
  const [latestData, setLatestData] = useState("");

  const getLastHistory = (id, startDateGraph, endDateGraph) => {
    getApi(
      `patients/logs/history?id=${id}&start_date=${moment(
        startDateGraph
      ).format("DD/MM/YYYY hh:mm:ss a")}&end_date=${moment(endDateGraph).format(
        "DD/MM/YYYY hh:mm:ss a"
      )}&filter[]=first`,
      (res) => {
        if (res?.status) {
          setLatestData(res?.data?.logs);
        }
      },
      (err) => console.log(err)
    );
  };

  useEffect(() => {
    if (patient?.id) {
      getLastHistory(patient?.id, startDateGraph, endDateGraph);
    }
  }, [startDateGraph, endDateGraph, patient?.id, lastData]);

  useEffect(() => {
    const updateInterval = setInterval(() => {
      setLastData(latestData);
    }, 1000);

    return () => clearInterval(updateInterval);
  }, [latestData]);

  function formatTimeDifference(lastAlertTime, currentTime) {
    const timeDifference = moment.duration(currentTime.diff(lastAlertTime));

    if (timeDifference.asSeconds() < 60) {
      return `${Math.floor(timeDifference.asSeconds())} sec`;
    } else if (timeDifference.asMinutes() < 60) {
      return `${Math.floor(timeDifference.asMinutes())} min`;
    } else if (timeDifference.asHours() < 24) {
      return `${Math.floor(timeDifference.asHours())} hr`;
    } else {
      return `${Math.floor(timeDifference.asDays())} days`;
    }
  }

  const lastAlertTime = moment(lastData?.alert_time);
  const currentTime = moment();

  const formattedTimeDifference = formatTimeDifference(
    lastAlertTime,
    currentTime
  );

  return (
    <>
      <div
        style={{ position: "relative", marginTop: "35px" }}
        className="col-12"
      >
        <div className="d-flex justify-content-between align-items-center px-3">
          <div className="d-flex  align-items-center">
            <h6 className="typography--class-h6 font-face-gr grey70 m-0">
              Activity Graph
            </h6>
            <TooltipComponent
              title={
                <>
                  <div style={{ paddingBottom: "12px" }}>
                    <span className={styles.tooltipTitle}>Activity-Graph </span>
                    shows the patient's last 12 hours of mobility-related
                    activities.
                  </div>
                  <div style={{ paddingBottom: "12px" }}>
                    <span className={styles.tooltipTitle}>
                      Significant Pressure Relief{" "}
                    </span>
                    line (Orange) is directly correlated with the long-duration
                    pressure region removal.
                  </div>
                  <div>
                    <span className={styles.tooltipTitle}>Mobility </span>
                    line (Green) displays the user's recent, significant
                    movements in real time.
                  </div>
                </>
              }
              detail={"graph"}
              infoIcon={styles.infoIcon}
            />
          </div>
          <div>
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={popoverDateGraph}
              rootClose
            >
              <button className="typography--class-caption grey50 px-0">
                Filter
                <img src={downArrow} className={`ms-1 ${styles.downArrow}`} />
              </button>
            </OverlayTrigger>
            {/* <img className={styles.infoIcon1} src={InfoIcon} /> */}
          </div>
        </div>

        {bedStatus ? (
          <div className={`bg-white mt-3 ${styles.box}`}>
            <div className="d-flex justify-content-between px-4 ">
              <div className="d-flex gap-2 pt-3">
                <div className="d-flex gap-2 align-item-centre pt-1">
                  <div
                    className={
                      lastData?.log == "Offline"
                        ? styles.redDot
                        : styles.greenDot
                    }
                  />
                </div>
                <p className="typography--class-footer grey70 m-0">
                  {lastData?.log == "Offline"
                    ? "Last Updated " + formattedTimeDifference + " ago"
                    : "Live"}
                </p>
              </div>
              <div className={`d-flex pt-3 ${styles.boxLegend}`}>
                <div className={` d-flex ${styles.boxLegendFirst}`}>
                  {/* <div className={`${styles.dashedLine}`} /> */}
                  <p
                    style={{ paddingTop: "0.7px" }}
                    className="typography--class-footer grey70 px-2 m-0"
                  >
                    Significant Pressure Relief
                  </p>
                  <h6
                    style={{ width: "10px" }}
                    className="m-0 green text-end fw-bold"
                  >
                    {pressureReliefValue}
                  </h6>
                </div>
                {/* <div className="d-flex" style={{ paddingLeft: "44px" }}>
                  <h6
                    style={{ width: "15px" }}
                    className="m-0 green text-end fw-bold"
                  >
                    {mobilityToShow}
                  </h6>
                  <div className={`${styles.MobilityLine}`} />
                  <p
                    style={{ paddingTop: "1px" }}
                    className="typography--class-footer grey70 px-2 m-0"
                  >
                    Mobility
                  </p>
                </div> */}
              </div>

              <div className="d-flex  pt-3">
                <p
                  style={{ paddingTop: "0.5px" }}
                  className="typography--class-footer grey70 px-2 m-0"
                >
                  Mobility
                </p>
                <h6
                  style={{ width: "10px" }}
                  className="m-0 green text-end fw-bold"
                >
                  {Math.floor(mobilityToShow)}
                </h6>
              </div>
            </div>
            <HighchartsReact
              containerProps={{
                style: { height: "230px" },
              }}
              // constructorType={"stockChart"}
              highcharts={Highcharts}
              ref={chartComponent}
              callback={afterChartCreated}
              options={options}
            />
          </div>
        ) : (
          <div className={`bg-white mt-3 ${styles.box}`}>
            <Skeleton
              variant="rounder"
              animation="wave"
              sx={{
                borderRadius: "8px",
                fontSize: "1rem",
                height: "265px",
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MobilityGraph;
