import React from "react";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "../../assets/images/infoIcon.png";

const TooltipComponent = ({ infoIcon, title, detail }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div onMouseOver={handleTooltipOpen} onMouseOut={handleTooltipClose}>
      <Tooltip
        title={title}
        arrow
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "#0762c8",
              color: "#BFD5EF",
              fontSize: "11px",
              fontWeight: 500,
              width: detail == "graph" ? "295px" : "265px",
              fontFamily: "Inter",
              borderRadius: "8px",
              padding: "10px",
            },
          },
          arrow: {
            sx: {
              color: "#0762c8",
            },
          },
        }}
      >
        <img
          onClick={handleTooltipOpen}
          className={infoIcon}
          src={InfoIcon}
          alt="Info Icon"
        />
      </Tooltip>
    </div>
  );
};

export default TooltipComponent;
