import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../Button";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./forget.module.scss";

const ForgotPassword = ({ showForgotPass, setShowForgotPass }) => {
  return (
    <div>
      <Modal
        show={showForgotPass}
        onHide={() => {
          setShowForgotPass(false);
        }}
        scrollable={true}
        size="lg"
        centered
        className={styles.Offcanvas1AddNurse1}
      >
        <Modal.Header className={styles.OffcanvasHeaderAddNurse}>
          <Modal.Title
            className={`typography--class-h1 font-face-gr ${styles.offCanvasTitleAddNurse}`}
          >
            Forgot Password
          </Modal.Title>
          <CloseIcon
            onClick={() => {
              setShowForgotPass(false);
            }}
            style={{
              cursor: "pointer",
              color: "#8D8D8D",
            }}
          />
        </Modal.Header>
        <div className={`${styles.modelBody}`}>
          <h5 className={`typography--class-caption ${styles.keyPara}`}>
            Please contact the Cureous Admin team to get your temporary
            password. Use the temporary password in the old password section and
            then enter your desired password.
          </h5>
          <div style={{ paddingTop: "20px" }}>
            <h5 className={`typography--class-caption ${styles.keyPara}`}>
              Email ID:{" "}
              <span className={` ${styles.valuePara}`}>support@cureous.in</span>
            </h5>
            <h5 className={`typography--class-caption ${styles.keyPara}`}>
              Phone Number:{" "}
              <span className={` ${styles.valuePara}`}>+91 8590153969</span>
            </h5>
          </div>
        </div>
        <Modal.Footer
          className={`d-flex justify-content-evenly  ${styles.footer}`}
        >
          <Button
            type="submit"
            text="Okay"
            onClick={() => setShowForgotPass(false)}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
