import React, { useEffect, useState } from "react";
import styles from "../../../bedDetails.module.scss";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import { Skeleton } from "@mui/material";
import { socket } from "../../../../../App";
import { useLocation } from "react-router-dom";
import TooltipComponent from "../../../../../components/Tooltip";

const Reposition = ({ patient, reposition, onData }) => {
  const [showText, setShowText] = useState(false);
  const [regions, setRegions] = useState();
  const location = useLocation();

  function handleMouseOver() {
    setShowText(true);
  }

  function handleMouseOut() {
    setShowText(false);
  }

  useEffect(() => {
    socket.on("device/plot/region", (socketData) => {
      let data = JSON.parse(socketData);
      if (data.bed_id == location?.state?.id) {
        setRegions(data.regions);
      }
    });
  }, []);

  const arr = regions ? JSON.parse(regions) : [];

  const result = arr.map((innerArr) => Math.floor(innerArr[2] / 60));

  const getNextDataTime = (nextTime) => {
    nextTime = new Date();
    nextTime.setMinutes(
      nextTime.getMinutes() +
        (patient.reposition_period == 30
          ? patient.reposition_period - result[0]
          : patient.reposition_period * 60 - result[0])
    );
    return nextTime;
  };

  const [nextDataTime, setNextDataTime] = useState(getNextDataTime());

  const remainingMin = patient.save_time % 60;
  const extraHr = Math.floor(patient.save_time / 60);

  const isTimeToHighlight = moment(patient.next_repo_time, "hh:mm A")
    .add(10, "minutes")
    .isBefore(moment());

  function handleClick() {
    onData(nextDataTime);
  }

  useEffect(() => {
    setNextDataTime(getNextDataTime());
    handleClick();
  }, [result[0], patient]);

  return (
    <div className="col-12 col-lg-5 pe-lg-3">
      <>
        <div className="d-flex  align-items-center px-3">
          <h6
            style={{ marginTop: "8px" }}
            className="typography--class-h6 font-face-gr grey70"
          >
            Reposition
          </h6>
          <TooltipComponent
            title={
              "Displays real-time repositioning data like the number of reps completed since the day's beginning at 12:00 AM, the time of the last repositioning to determine the interval between repositions, and the time of the next anticipated reposition."
            }
            infoIcon={styles.infoIcon}
          />
        </div>
        <div
          style={{ marginTop: "14px" }}
          className={`bg-white d-flex flex-column align-items-center  ${styles.box} `}
        >
          <div
            className={`d-flex flex-row gap-2 py-0 text-center ${styles.borderB1}`}
          >
            {reposition ? (
              <div
                className={`d-flex flex-column gap-2 py-3 text-center ${
                  reposition && styles.borderB2
                }`}
              >
                <h5 className="font-face-gr grey70 m-0">
                  {`${
                    reposition?.total_reposition !== undefined
                      ? reposition?.total_reposition
                      : "-"
                  } ${
                    reposition?.total_reposition !== undefined &&
                    reposition?.total_reposition > 1
                      ? "Reps"
                      : "Rep"
                  }`}
                </h5>
                <p className="typography--class-caption cureousGrey opacity-50 m-0">
                  Repositions done
                </p>
              </div>
            ) : (
              <div
                className={`d-flex flex-column gap-2 py-3 text-center ${styles.borderB2}`}
              >
                <h5 className="font-face-gr grey70 m-0">
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{
                      width: "100px",
                      margin: "0 auto",
                    }}
                  />
                </h5>
                <p className="typography--class-caption cureousGrey opacity-50 m-0">
                  Repositions done
                </p>
              </div>
            )}
            {reposition ? (
              <div
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                className={`d-flex flex-column gap-2 py-3 text-center ${
                  reposition && styles.borderB2
                }`}
              >
                {showText ? (
                  <h5
                    style={{ paddingRight: "6px" }}
                    className=" font-face-gr grey70 m-0"
                  >
                    {reposition?.last_reposition_time?.created_at !== undefined
                      ? moment(
                          reposition?.last_reposition_time?.created_at
                        ).format("DD MMM | hh:mm A")
                      : "-"}
                  </h5>
                ) : (
                  <h5 className=" font-face-gr grey70 m-0">
                    {reposition?.last_reposition_time?.created_at !== undefined
                      ? moment(
                          reposition?.last_reposition_time?.created_at
                        ).format("hh:mm A")
                      : "-"}
                  </h5>
                )}

                <p className="typography--class-caption cureousGrey opacity-50 m-0">
                  Last Reposition time
                </p>
              </div>
            ) : (
              <div
                style={{
                  height: "80px",
                }}
                className={`d-flex flex-column gap-2 py-3 text-center ${styles.borderB2}`}
              >
                <h5 className="font-face-gr grey70 m-0">
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{
                      width: "100px",
                      margin: "0 auto",
                    }}
                  />
                </h5>
                <p className="typography--class-caption cureousGrey opacity-50 mt-0">
                  Last Reposition time
                </p>
              </div>
            )}
          </div>

          {reposition ? (
            <div
              className={`d-flex flex-column gap-2 py-3 text-center ${
                reposition && styles.borderB1
              }`}
            >
              {result[0] == undefined ? (
                "-"
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5
                    className={`font-face-gr grey70 m-0 ${
                      isTimeToHighlight ? styles.redText : ""
                    }`}
                  >
                    {patient.next_repo_time}
                  </h5>

                  {patient.save_time > 0 && (
                    <div className={` ${styles.nextTime}`}>
                      <h5 className={` ${styles.nextTimeData} `}>
                        {patient.save_time >= 60
                          ? `+${extraHr}hr ${remainingMin}min`
                          : `+${patient.save_time}min`}
                      </h5>
                    </div>
                  )}
                </div>
              )}

              <p className="typography--class-caption cureousGrey opacity-50 m-0">
                Next Reposition time
              </p>
            </div>
          ) : (
            <div
              style={{
                height: "80px",
              }}
              className={`d-flex flex-column gap-2 py-3 text-center ${
                reposition && styles.borderB1
              }`}
            >
              <h5 className="font-face-gr grey70 m-0">
                <Skeleton variant="text" animation="wave" sx={{}} />
              </h5>
              <p className="typography--class-caption cureousGrey opacity-50 mt-0">
                Next Reposition time
              </p>
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default Reposition;
