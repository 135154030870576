import React, { useEffect, useState } from "react";
import styles from "./home.module.scss";
import search from "../../assets/images/search.png";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import { Modal, Spinner } from "react-bootstrap";
import Button from "../../components/Button";
import Card from "../../components/Card";
import "react-toastify/dist/ReactToastify.css";
import { getApi } from "../../utils/ServiceManger";
import { socket } from "../../App";
import { Skeleton } from "@mui/material";
import Toolbar from "../../components/Header/Toolbar";
import SideDrawer from "../../components/Header/components/sidemenu/SideDrawer";
import Backdrop from "../../components/Header/components/backdrop/BackDrop";
import AssignNurse from "./components/AssignNurse";
import AddPatient from "./components/AddPatient";
import logo from "../../assets/images/logoPressure.png";
import Heatmap from "./components/Heatmap";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import downArrow from "../../assets/images/downArrow.png";
import Header from "../../components/Header";

const Home = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const [bedId, setBedId] = useState();
  const [deviceUniqueId, setDeviceUniqueId] = useState();
  const [bedStatus, setBedStatus] = useState();
  const [bedTimerStartTime, setBedTimerStartTime] = useState();
  const [bedIdFromSocket, setBedIdFromSocket] = useState();
  const navigate = useNavigate();
  const [rooms, setRooms] = useState([]);

  const getRoomData = () => {
    getApi(
      "rooms",
      (res) => {
        if (res?.status) {
          setRooms(res?.data?.rooms);
        } else if (res?.message === "Not valid token, Please log in first") {
          navigate("/login");
        }
      },
      (err) => {
        if (err?.message === "Request failed with status code 403") {
          navigate("/login");
        }
      }
    );
  };

  const [selectedItem, setSelectedItem] = useState(
    rooms.length > 0
      ? { id: rooms[0].id, name: rooms.map((room) => room.name)[0] }
      : {}
  );

  const [showDropDown, setShowDropDown] = useState(false);
  const [selected, setSelected] = useState(false);

  function handleSelectItem(value) {
    const selectedItem = rooms.find((room) => room.name === value);
    if (selectedItem) {
      setSelected(true);
    }
    setSelectedItem({ id: selectedItem.id, name: selectedItem.name });
    setShowDropDown(false);
  }

  const popoverContent = (
    <Popover
      style={{ border: "none", borderRadius: "10px" }}
      id="popover-basic"
    >
      <Popover.Body className={styles.calendar}>
        {rooms.map((room, index) => {
          return (
            <Dropdown.Item
              key={index}
              className={`${styles.range}
               ${
                 selectedItem && selectedItem.name === room.name
                   ? styles.selected
                   : rooms.length == 1
                   ? styles.selectedData
                   : ""
               } 
              ${!selected ? styles.firstItem : ""} 
              `}
              onClick={(e) => handleSelectItem(room.name)}
            >
              {room.name}
            </Dropdown.Item>
          );
        })}
      </Popover.Body>
    </Popover>
  );

  const room_id = localStorage.getItem("room_id");
  const room_name = localStorage.getItem("room_name");

  const roomData =
    Object.keys(selectedItem).length === 0
      ? rooms.map((room) => room)[0]
      : selectedItem;

  useEffect(() => {
    getRoomData();
  }, []);

  useEffect(() => {
    socket.on("web/patient/status", (socketData) => {
      let data = JSON.parse(socketData);
      // console.log("socket::", data);
      setBedIdFromSocket(data.bed_id);
      setBedTimerStartTime(data.timerStartTime);
      setBedStatus(data.status[0]);
    });
  }, []);

  const getData = () => {
    // socket.emit("web/patient/status");
    setLoading(true);
    getApi(
      `rooms/beds?room_id=${room_id ? room_id : roomData?.id}`,
      (res) => {
        if (res?.status) {
          setData(res?.data?.beds);
          setLoading(false);
        } else if (res?.message === "Not valid token, Please log in first") {
          navigate("/login");
        }
      },
      (err) => {
        if (err?.message === "Request failed with status code 403") {
          navigate("/login");
        }
      }
    );
  };

  useEffect(() => {
    getData();
  }, [roomData, bedStatus]);

  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = data.filter((d) => {
    const nameMatch =
      d.is_occupied == true &&
      d.patient.name.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0;
    const bedIdMatch =
      d.bed_id.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0;
    const statusMatch =
      d.status[0].toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0;
    return nameMatch || bedIdMatch || statusMatch;
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isOpen, setIsOpen] = useState(false);
  const [dataId, setDataId] = useState();
  const handleModalClose = () => setIsOpen(false);
  const handleModalShow = (id) => {
    setDataId(id);
    setIsOpen(true);
  };

  const toggleModal = React.useCallback(
    (id) => () => {
      getApi(
        `patients/removeCaremode?id=${id}&room_id=${
          room_id ? room_id : roomData?.id
        }`,
        (res) => {
          if (res.status) {
            handleModalClose();
            navigate("/");
          }
        },
        (err) => {
          console.log(err?.message);
        }
      );
    },
    []
  );

  const MyVerticallyCenteredModal = (props) => {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName={`p-3 gap-3 ${styles.searchModal}`}
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Modal.Header bsPrefix="d-flex justify-content-center">
              <p
                className={`typography--class-caption grey70 m-0 ${styles.headerModal}`}
              >
                Before Confirming, kindly ensure that the issue has been
                resolved.
              </p>
            </Modal.Header>
            <Modal.Body bsPrefix="d-flex justify-content-center gap-4">
              <Button
                style={{
                  width: "98px",
                  fontSize: "14px",
                }}
                text="Confirm"
                variant="primary"
                onClick={toggleModal(dataId)}
              />
              <Button
                style={{ width: "98px", fontSize: "14px" }}
                text="No"
                variant="secondary"
                onClick={() => {
                  handleModalClose();
                }}
              />
            </Modal.Body>
          </>
        )}
      </Modal>
    );
  };

  //show heatmap
  const [patient, setPatient] = useState({});
  const [showPatientHeatmapModal, setShowPatientHeatmapModal] = useState(false);

  const handleShowHeatMap = (patientData) => {
    setShowPatientHeatmapModal(true);
    setPatient(patientData);
  };

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  const backDropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  let backdrop;

  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backDropClickHandler} />;
  }

  const Id = localStorage.getItem("Id");

  return (
    <>
      {room_id ? (
        <Header />
      ) : (
        <>
          <Toolbar drawerToggleClickHandler={drawerToggleClickHandler} />
          <SideDrawer show={sideDrawerOpen} />
          {backdrop}
        </>
      )}

      <section>
        <div className="d-flex flex-column flex-md-row">
          <div
            className={`col-12 col-md-3 py-4 border-end-0 ${styles.headingLeft}`}
          >
            {room_name ? (
              <h3
                className={`typography--class-h5 font-face-gr text-center cureousGrey`}
              >
                {room_name}
              </h3>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className={`d-flex justify-content-between align-items-center `}
                >
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popoverContent}
                    show={showDropDown}
                    onHide={() => setShowDropDown(false)}
                  >
                    <h5
                      style={{ cursor: "pointer" }}
                      className={`typography--class-h5 font-face-gr text-center cureousGrey`}
                      onClick={() => setShowDropDown(!showDropDown)}
                    >
                      {selectedItem && !roomData
                        ? selectedItem.name
                        : roomData?.name}
                      <img
                        src={downArrow}
                        className={`ms-3 mb-1 ${styles.downArrow}`}
                      />
                    </h5>
                  </OverlayTrigger>
                </div>
              </div>
            )}
          </div>
          <div className={`col-12 col-md-9 ${styles.heading}`}>
            <div className="d-flex flex-column flex-md-row px-4 py-3 py-md-0 align-items-center justify-content-between h-100">
              <div className="d-flex flex-column mt-2">
                <p
                  className={`typography--class-caption fw-normal mb-1 mb-md-2 grey50`}
                >
                  Aerial View
                </p>
                <h5
                  //testing purpose for shafi
                  style={{ cursor: Id == "1" && "pointer" }}
                  onClick={
                    Id == "1" && (() => navigate("/device/wifi-setting"))
                  }
                  className={`typography--class-h5 font-face-gr text-center cureousGrey`}
                >
                  Dashboard
                </h5>
              </div>
              <div>
                <div className="d-flex gap-2 align-items-center">
                  <Input
                    id="query"
                    placeholder="Patient name, Bed ID...."
                    //TODO should this be className?
                    classname={styles.inputDiv}
                    value={searchQuery}
                    autocomplete="nope"
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <button type="submit">
                    <img src={search} className={styles.search} alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.bgGrey}`}>
          <div
            className={`d-flex flex-wrap gap-3 gap-md-5 justify-content-center py-5 mx-md-5`}
          >
            {loading && !bedStatus ? (
              <>
                {[0, 1, 2, 3, 4, 5].map((x) => {
                  return (
                    <div key={x} className="h-100">
                      <h5
                        className={`typography--class-h5 font-face-gr text-center`}
                      >
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{
                            fontSize: "1rem",
                            borderRadius: "8px",
                          }}
                        />
                      </h5>
                      <p
                        className={`typography--class-body cureousGrey m-0 ${styles.patientName}`}
                      >
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{
                            fontSize: "1rem",
                            borderRadius: "8px",
                          }}
                        />
                      </p>
                      <Skeleton
                        variant="rounder"
                        animation="wave"
                        sx={{
                          fontSize: "1rem",
                          borderRadius: "8px",
                        }}
                        width="132px"
                        height="180px"
                      />
                      {!Id && (
                        <Skeleton
                          variant="rounder"
                          animation="wave"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "4px",
                            cursor: "pointer",
                            borderRadius: "8px",
                          }}
                          width="132px"
                          height="34px"
                        />
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {filteredData.length > 0 ? (
                  filteredData?.map((x) => {
                    return (
                      <div key={x?.id} className="h-100">
                        <h5
                          className={`typography--class-h5 font-face-gr text-center ${
                            x?.status[0] === "On Bed" ||
                            !x?.status[0] ||
                            x?.status[0] === "Unoccupied" ||
                            (x?.id === bedIdFromSocket &&
                              (bedStatus === "On Bed" || !bedStatus))
                              ? "grey20"
                              : ""
                          }`}
                        >
                          {x?.bed_id}
                        </h5>
                        <p
                          className={`typography--class-body cureousGrey m-0 ${styles.patientName}`}
                        >
                          {x?.patient?.is_discharged == 0
                            ? x?.patient?.name
                            : "Add Patient"}
                        </p>
                        {!x.is_occupied ? (
                          <div
                            onClick={() => {
                              setDeviceUniqueId(x.deviceUniqueId);
                              setBedId(x?.bed_id);
                              setId(x?.id);
                              handleShow();
                            }}
                            className={`d-flex justify-content-center align-items-center ${styles.primary} ${styles.smallCard} ${styles.border}`}
                          >
                            <h6 className="typography--class-h1 font-face-gr grey20">
                              +
                            </h6>
                          </div>
                        ) : (
                          <Card
                            secondLeft={
                              x?.id === bedIdFromSocket
                                ? bedTimerStartTime
                                : x?.timerStartTime
                            }
                            patient={x.patient}
                            bedTimerStartTime={bedTimerStartTime}
                            status={
                              //show socket status if available else show the one gotten from api
                              x?.id === bedIdFromSocket
                                ? bedStatus
                                : x?.status[0]
                            }
                            link={`/bed/${x?.id}`}
                            linkState={{
                              room: room_id
                                ? { id: Number(room_id), name: room_name }
                                : roomData,
                              id: x?.id,
                              isBedOccupied: x.is_occupied, //show settings modal, etc if bed is unoccupied
                            }}
                            onClick={() => {
                              setBedId(x?.bed_id);
                              setId(x?.id);
                              handleShow();
                            }}
                          />
                        )}
                        {!Id && (
                          <>
                            {(
                              x?.id !== bedIdFromSocket
                                ? x?.status[0] === "Caremode"
                                : bedStatus === "Caremode"
                            ) ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "4px",
                                  paddingTop: "7px",
                                  cursor: "pointer",
                                  height: "34px",
                                  border: "1px solid #0762c8",
                                  borderRadius: "8px",
                                }}
                                onClick={() => handleModalShow(x?.deviceId)}
                              >
                                <p
                                  className={`typography--class-body cureousGrey  m-0 ${styles.cardFooter}`}
                                >
                                  MONITOR
                                </p>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        )}

                        {(
                          x?.id !== bedIdFromSocket
                            ? x?.status[0] === "Reposition Alert"
                            : bedStatus === "Reposition Alert"
                        ) ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "4px",
                              padding: "8px",
                              paddingTop: "7px",
                              cursor: "pointer",
                              height: "34px",
                              border: "1px solid #0762c8",
                              borderRadius: "8px",
                            }}
                            onClick={() => handleShowHeatMap(x.patient)}
                          >
                            <img
                              src={logo}
                              alt=""
                              className={` ${styles.logo}`}
                            />
                            <p
                              className={`typography--class-body cureousGrey  m-0 ${styles.cardFooter}`}
                            >
                              PRESSURE MAP
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p className="typography--class-body grey70 m-0">
                    No Data Found
                  </p>
                )}
              </>
            )}
          </div>
        </div>

        {showPatientHeatmapModal ? (
          <Heatmap
            showPatientHeatmapModal={showPatientHeatmapModal}
            setPatient={setPatient}
            patient={patient} // Pass the bed data to the Heatmap component
            setShowPatientHeatmapModal={setShowPatientHeatmapModal}
          />
        ) : (
          <></>
        )}
      </section>
      <div>
        <AssignNurse
          roomData={roomData}
          // show={nurseShow}
          // handleShow={handleNurseShow}
          // handleClose={handleNurseClose}
        />
        <AddPatient
          room_id={room_id ? room_id : roomData?.id}
          show={show}
          bedId={bedId}
          deviceUniqueId={deviceUniqueId}
          id={id}
          handleClose={handleClose}
          navigate={navigate}
        />
      </div>
      <MyVerticallyCenteredModal
        show={isOpen}
        onHide={() => setIsOpen(false)}
      />
    </>
  );
};

export default Home;
