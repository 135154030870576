import React, { useEffect, useState } from "react";
import styles from "../assignNurse.module.scss";
import { Modal, Spinner } from "react-bootstrap";
import Button from "../../../../../components/Button";
import Input from "../../../../../components/Input";
import CloseIcon from "@mui/icons-material/Close";
import { postApi } from "../../../../../utils/ServiceManger";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MorningIcon from "../../../../../assets/images/morningIcon.png";
import EveningIcon from "../../../../../assets/images/EveningIcon.png";
import NightIcon from "../../../../../assets/images/NightIcon.png";
import downArrow from "../../../../../assets/images/downArrow.png";
import upArrow from "../../../../../assets/images/upArrow.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import TooltipComponent from "../../../../../components/Tooltip";

function AllotNurse({
  beds,
  setBeds,
  show,
  handleClose,
  nurses,
  setNurses,
  shift,
  roomData,
  selectedOption,
  handleOptionClick,
}) {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const nurseNames = nurses.map((nurse) => nurse.name);

  const [initialBeds, setInitialBeds] = useState([]);

  const handleNurseNameChange = (index, value) => {
    const updatedBeds = [...beds];
    updatedBeds[index].nurse_name = value;
    setBeds(updatedBeds);
  };

  const filterOptions = (options, { inputValue }) => {
    const input = inputValue.toLowerCase();
    return options.filter((option) => option.toLowerCase().startsWith(input));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const bedAssignments = beds.map((bed) => ({
      bed_id: bed.id,
      nurseName: bed.nurse_name || "",
    }));
    postApi(
      `nurses/assign-nurse`,
      { bedAssignments },
      (res) => {
        if (res) {
          handleClose();
          formik.resetForm();
          setShowDropdown(false);
        } else if (res?.message === "Not valid token, Please log in first") {
          navigate("/login");
        } else {
          setError(res?.message);
        }
      },
      (err) => setError(err?.message.toString())
    );
  };

  const handleModalClose = () => {
    setBeds(initialBeds);
    handleClose();
  };

  useEffect(() => {
    setInitialBeds(beds);
  }, []);

  const room_id = localStorage.getItem("room_id");

  const formik = useFormik({
    initialValues: {
      name: "",
      room_id: Yup.number,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required("Please enter the nurse name"),
    }),
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append(
        "name",
        values.name
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      );
      formData.append("room_id", room_id ? room_id : roomData?.id);
      setLoading(true);
      postApi(
        "nurses/add",
        formData,
        (res) => {
          if (res !== "Name required") {
            setNurses((prevNurses) => [...prevNurses, res]);
            setLoading(false);
            resetForm({ values: "" });
            setError("");
          } else if (res === "Name required") {
            setLoading(false);
          }
        },
        (err) => {
          setLoading(false);
          setError(err?.message.toString());
        }
      );
    },
  });

  function capitalizeFirstLetter(sentence) {
    const words = sentence.split(" ");

    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return "";
    });

    return capitalizedWords.join(" ");
  }

  return (
    <div>
      <Modal
        show={show}
        onHide={() => {
          handleModalClose();
          setShowDropdown(false);
        }}
        scrollable={true}
        size="xl"
        centered
        className={styles.Offcanvas1AddNurse1}
      >
        <Modal.Header className={styles.OffcanvasHeaderAddNurse}>
          <Modal.Title
            className={`typography--class-h5 font-face-gr ${styles.offCanvasTitleAddNurse}`}
          >
            Patient - Nurse Allotment
          </Modal.Title>
          <CloseIcon
            onClick={() => {
              handleModalClose();
              setShowDropdown(false);
            }}
            style={{
              cursor: "pointer",
              color: "#8D8D8D",
            }}
          />
        </Modal.Header>
        <div className={`${showDropdown && styles.modelBody}`}>
          {room_id && (
            <>
              <div
                className={`${styles.modelBodyHeaderAddNurse}`}
                onClick={handleDropdownToggle}
              >
                <div className="d-flex  ">
                  <p
                    className={` typography--class-caption ${styles.modelBodyHeaderP1}`}
                  >
                    Add New Nurse
                  </p>
                  <TooltipComponent
                    title={
                      "Adds a new nurse to the list. The added nurse can be selected from the drop down list from the allotted nurse section."
                    }
                    infoIcon={styles.infoIcon}
                  />
                </div>
                {showDropdown ? (
                  <div style={{ paddingRight: "30px", paddingTop: "6px" }}>
                    <img src={upArrow} className={styles.downArrow} />
                  </div>
                ) : (
                  <div style={{ paddingRight: "30px", paddingTop: "6px" }}>
                    <img src={downArrow} className={styles.downArrow} />
                  </div>
                )}
              </div>
              {showDropdown && (
                <div className={`${styles.modelBodyHeaderAddNurse1}`}>
                  <Input
                    className={`${
                      formik.errors.name || error
                        ? styles.modelBodyHeaderAddNurseInputErr
                        : styles.modelBodyHeaderAddNurseInput
                    }`}
                    placeholder="Enter the Nurse Name"
                    error={error ? "Name already exists" : formik.errors.name}
                    value={capitalizeFirstLetter(formik.values.name)}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (
                        /^[A-Za-z\s]+$/.test(inputValue) ||
                        inputValue === ""
                      ) {
                        formik.setFieldValue("name", inputValue);
                      }
                    }}
                    label="Name"
                    id="name"
                  />
                  {!loading ? (
                    <Button
                      onClick={formik.handleSubmit}
                      className={`typography--class-body  ${styles.modelBodyHeaderAddNurseButton}`}
                      type="submit"
                      text="Add"
                    />
                  ) : (
                    <div
                      style={{ padding: "4px", paddingLeft: "25px" }}
                      className={`typography--class-body  ${styles.modelBodyHeaderAddNurseButton}`}
                    >
                      <Spinner size="sm" animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          <div className={`${styles.modelBodyHeader}`}>
            <div className="d-flex  ">
              <p
                className={` typography--class-caption ${styles.modelBodyHeaderP1}`}
              >
                Shift
              </p>
              <TooltipComponent
                title={
                  "Shifts will be automatically selected according to your organization’s scheduled timing. Please allot the nurses according to the shift."
                }
                infoIcon={styles.infoIcon}
              />
            </div>
          </div>

          <div className={`${styles.modelBodyHeader2}`}>
            <div
              className={`${
                selectedOption === "Morning"
                  ? styles.clicked
                  : styles.modelBodyImage1
              }`}
            >
              <img src={MorningIcon} className={styles.morningIcon} />
              <p
                className={` typography--class-caption ${styles.modelBodyImageP}`}
              >
                Morning
              </p>
            </div>
            {shift?.total_shift > 2 && (
              <div
                className={`${
                  selectedOption === "Evening"
                    ? styles.clicked
                    : styles.modelBodyImage1
                }`}
              >
                <img src={EveningIcon} className={styles.morningIcon} />
                <p className={`${styles.modelBodyImageP}`}>Evening</p>
              </div>
            )}

            <div
              className={`${
                selectedOption === "Night"
                  ? styles.clicked
                  : styles.modelBodyImage1
              }`}
            >
              <img src={NightIcon} className={styles.morningIcon} />
              <p
                className={`typography--class-caption  ${styles.modelBodyImageP}`}
              >
                Night
              </p>
            </div>
          </div>
          <div className={`${styles.modelBodyHeader3}`}>
            <p
              className={` typography--class-caption ${styles.modelBodyHeaderP1}`}
            >
              Bed No
            </p>
            <p
              className={` typography--class-caption ${styles.modelBodyHeaderP2}`}
            >
              Patient Name
            </p>
            <p
              className={` typography--class-caption ${styles.modelBodyHeaderP2}`}
            >
              Allotted Nurse
            </p>
          </div>
        </div>

        <div style={{ overflow: "scroll", height: "400px" }}>
          {beds.map((bed, index) => {
            return (
              <div className={`${styles.modelBodyHeader4}`}>
                <h5
                  className={`typography--class-h6 ${styles.modelBodyHeaderP3}`}
                >
                  {bed.bed_id}
                </h5>
                <h5
                  className={`typography--class-h6 ${styles.modelBodyHeaderP4}`}
                >
                  {bed.patient.name}
                </h5>
                <Autocomplete
                  freeSolo={!room_id}
                  options={nurseNames}
                  filterOptions={filterOptions}
                  value={bed.nurse_name || ""}
                  onChange={(e, value) => handleNurseNameChange(index, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{
                        border: "none",
                        textDecoration: "none",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        className: `typography--class-caption ${styles.modelBodyInput}`,
                        disableUnderline: true,
                        readOnly: !room_id,
                        style: { fontSize: 14 },
                      }}
                    />
                  )}
                  ListboxComponent={(props) => (
                    <ul {...props} style={{ fontSize: 14 }} />
                  )}
                  noOptionsText={
                    <span style={{ fontSize: 14 }}>Not available</span>
                  }
                  disableClearable={!room_id}
                  disabled={!room_id}
                />
              </div>
            );
          })}
        </div>

        <Modal.Footer
          className={`d-flex justify-content-evenly  ${styles.footer}`}
        >
          <Button type="submit" text="Save" onClick={handleSubmit} />
          <Button
            text="Cancel"
            variant="secondary"
            onClick={() => {
              formik.handleReset();
              setError("");
              setShowDropdown(false);
              handleModalClose();
            }}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AllotNurse;
