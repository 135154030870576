/* This maps frontend url to backend url.
 * WHY? so that we can avoid having an ENV defining it.
 * WHY? so we can deploy to all environments (localhost,staging,production) with only one folder on the server
 * WHEN TO MOVE AWAY FROM THIS? When we have 3 different frontend folders on server anyway for other reasons. Then, this can be an ENV var.
 */
export const BACKEND_SERVER_MAP = {
  "http://localhost:3000": "http://localhost:8080",
  "http://cureous-bb895.othersideofthewall.co":
    "http://cureous-bb895.othersideofthewall.co",
  "http://3.7.242.77": "http://3.7.242.77",
  "https://sensepod.cureous.in": "https://sensepod.cureous.in",
  "https://staging.cureous.in": "https://staging.cureous.in",
};
