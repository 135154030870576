import React, { useEffect, useState } from "react";
import styles from "../../history.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import discharge from "../../../../assets/images/discharge.png";
import weakSignal from "../../../../assets/images/weakSignal.png";
import fairSignal from "../../../../assets/images/fairSignal.png";
import goodSignal from "../../../../assets/images/goodSignal.png";
import excellentSignal from "../../../../assets/images/excellentSignal.png";
import { getApi, postApi } from "../../../../utils/ServiceManger";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import { socket } from "../../../../App";
import DischargeConfirmationModal from "./component";

const Settings = ({
  room_id,
  bedStatus,
  showPatientSettingsModal,
  handleClose,
  patient,
  bedId,
  internalBedId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [showDischargeConfirmationModal, setShowDischargeConfirmationModal] =
    useState(false);

  const [selectedPeriod, setSelectedPeriod] = useState();
  const [selectedPosition, setSelectedPosition] = useState();
  const [selectedSittingUpAlert, setSelectedSittingUpAlert] = useState();
  const [selectedNurseCall, setSelectedNurseCall] = useState();

  useEffect(() => {
    setSelectedPeriod(`${patient?.reposition_period} hrs`);
    setSelectedPosition(`${patient?.restricted_side}`);
    setSelectedSittingUpAlert(
      patient?.is_siting_up_alert == 0 ? "Disable" : "Enable"
    );
    setSelectedNurseCall(
      patient?.nurse_assistance_call == 0 ? "Disable" : "Enable"
    );
  }, [patient]);

  const handleCloseDischargeConfirmationModal = () =>
    setShowDischargeConfirmationModal(false);

  const handleDischargeConfirmed = () => {
    getApi(
      `patients/discharge?id=${patient?.id}`,
      (res) => {
        //Request succeeded, don't know if the operation succeeded

        if (res.status) {
          //Discharge succeeded

          //close the DischargeConfirmationModal
          setShowDischargeConfirmationModal(false);
          //TODO show success toast
          console.log("Discharge succeeded");
          //TODO should we clear all the details or just navigate to home?
          navigate("/");
        } else {
          //Discharge could not be done
          console.log("Discharge could not be done. Error:", res.message);

          //TODO show discharge failure toast
        }
      },
      (err) => {
        console.log(err?.message);
        //TODO show network failure toast
      }
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: patient?.name,
      id: patient?.patient_id,
      deviceId: patient?.device_id,
      age: patient?.age,
      weight: patient?.weight,
      date: moment(patient?.admission_date, "DD/MM/YYYY").format("YYYY-MM-DD"),
      contactNumber: patient?.contact_no,
      repositionPeriod: patient?.reposition_period,
      restrictedSide: patient?.restricted_side,
      sitingUpAlert: patient?.is_siting_up_alert,
      nurseAssistanceCall: patient?.nurse_assistance_call,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required("Please enter patient name"),
      id: Yup.string().required("Please provide patient's ID"),
      age: Yup.number().required("Please enter age of the patient"),
      weight: Yup.number().required("Please enter weight of the patient"),
      date: Yup.date().required("Please enter admission date of the patient"),
      contactNumber: Yup.string()
        .required("Please provide contact no")
        .matches(/^[6-9]{1}[0-9]{9}$/, "Please provide valid Indian no."),
      repositionPeriod: Yup.string().required(
        "Please select reposition period"
      ),
      restrictedSide: Yup.string().required("Please select restricted side"),
      sitingUpAlert: Yup.string().required("Please select sitingUpAlert"),
      nurseAssistanceCall: Yup.string().required(
        "Please select nurseAssistanceCall"
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("id", patient?.id);
      formData.append("room_id", room_id);
      formData.append("bed_id", internalBedId);
      formData.append("name", values.name);
      formData.append("patient_id", values.id);
      formData.append("contact_no", values.contactNumber);
      formData.append("age", values.age);
      formData.append("weight", values.weight);
      formData.append(
        "reposition_period",
        values.repositionPeriod === patient?.reposition_period
          ? values.repositionPeriod
          : values.repositionPeriod?.match(/\d+/)[0]
      );
      formData.append("restricted_side", values.restrictedSide);
      formData.append("is_siting_up_alert", values.sitingUpAlert);
      formData.append("nurse_assistance_call", values.nurseAssistanceCall);
      formData.append(
        "admission_date",
        moment(values?.date).format("DD/MM/YYYY")
      );
      formData.append("is_bed_occupied", location.state?.isBedOccupied);
      postApi(
        "patients/edit",
        formData,
        (res) => {
          if (res?.status) {
            // status=true. This means patient update was successful
            resetForm({ values: "" });
            handleClose();
            setError("");

            //this updates the display on the current page (patient details page)
            let newPatient = {
              ...patient,
              adminssion_date: moment(values?.date).format("DD/MM/YYYY"),
              age: values.age,
              contact_no: values.contactNumber,
              device_id: patient.device_id,
              name: values.name,
              patient_id: values.id,
              reposition_period:
                values.repositionPeriod === patient?.reposition_period
                  ? values.repositionPeriod
                  : values.repositionPeriod?.match(/\d+/)[0],
              restricted_side: values.restrictedSide,
              weight: values.weight,
              is_siting_up_alert: values.sitingUpAlert,
              nurse_assistance_call: values.nurseAssistanceCall,
            };
            setData({
              ...data,
              patient: newPatient,
            });
          } else {
            setError(res?.message);
          }
        },
        (err) => setError(err?.message.toString())
      );
    },
  });

  const handleDischargeButtonClick = () => {
    setShowDischargeConfirmationModal(true);
  };

  const [wifiNetwork, setWifiNetwork] = useState();

  useEffect(() => {
    socket.on("web/device/rssi", (socketData) => {
      let data = JSON.parse(socketData);
      if (data.bed_id == location?.state?.id) {
        setWifiNetwork(data.rssi);
      }
    });
  }, []);

  const getSignalStrength = () => {
    if (wifiNetwork >= -50) {
      return "Excellent";
    } else if (wifiNetwork >= -60) {
      return "Good";
    } else if (wifiNetwork >= -70) {
      return "Fair";
    } else {
      return "Weak";
    }
  };

  const getSignalStrengthImage = () => {
    if (wifiNetwork >= -50) {
      return excellentSignal;
    } else if (wifiNetwork >= -60) {
      return goodSignal;
    } else if (wifiNetwork >= -70) {
      return fairSignal;
    } else {
      return weakSignal;
    }
  };

  const getSignalStrengthClassName = () => {
    if (wifiNetwork >= -50) {
      return styles.excellent;
    } else if (wifiNetwork >= -60) {
      return styles.good;
    } else if (wifiNetwork >= -70) {
      return styles.fair;
    } else {
      return styles.weak;
    }
  };

  return (
    <>
      <Offcanvas
        placement="end"
        show={showPatientSettingsModal}
        onHide={() => {
          formik.handleReset();
          setError("");
          handleClose();
        }}
        className={styles.Offcanvas1}
        backdropClassName={styles.Offcanvas}
      >
        <Offcanvas.Header bsPrefix={styles.OffcanvasHeader} closeButton>
          <Offcanvas.Title
            className={`typography--class-h5 font-face-gr  d-flex flex-row ${styles.offCanvasTitle}`}
          >
            {bedId}
          </Offcanvas.Title>
          {wifiNetwork && (
            <div
              className={` ${
                styles.wifiSignal
              } ${getSignalStrengthClassName()}`}
            >
              <img
                src={getSignalStrengthImage()}
                className={styles.wifiSignalImage}
                alt=""
              />
              <h5
                className={`typography--class-footer ${styles.wifiSignalData}`}
              >
                {getSignalStrength() + " " + "(" + wifiNetwork + " dBm)"}
              </h5>
            </div>
          )}
        </Offcanvas.Header>
        <Offcanvas.Body bsPrefix="d-flex flex-column flex-md-row h-100">
          <div
            className={`col-12 col-md-6 p-4 p-lg-5 d-flex flex-column gap-1 gap-lg-4`}
          >
            <h6 className="typography--class-h6 font-face-gr grey70">
              Patient Details
            </h6>
            <Input
              label={`*Patient Name`}
              id="name"
              placeholder="Name"
              value={formik.values.name}
              error={formik.errors.name}
              onChange={formik.handleChange}
            />
            <Input
              label="*Patient ID"
              id="id"
              type="text"
              placeholder="Enter Patient ID"
              value={formik.values.id}
              error={formik.errors.id}
              onChange={formik.handleChange}
            />
            <Input
              predefined
              label="Device ID"
              id="deviceId"
              placeholder={formik.values.deviceId}
              value={formik.values.deviceId}
            />
            <div className={`d-flex flex-row gap-1 gap-lg-4`}>
              <Input
                label="*Age"
                id="age"
                type="text"
                placeholder="Yrs"
                value={formik.values.age}
                error={formik.errors.age}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                  const ageValue = parseInt(sanitizedValue, 10);
                  if (
                    sanitizedValue === "" ||
                    (ageValue >= 0 && ageValue <= 150)
                  ) {
                    formik.setFieldValue("age", sanitizedValue);
                  }
                }}
              />
              <Input
                label="*Weight"
                id="weight"
                type="text"
                placeholder="Kg"
                value={formik.values.weight}
                error={formik.errors.weight}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                  const ageValue = parseInt(sanitizedValue, 10);
                  if (
                    sanitizedValue === "" ||
                    (ageValue >= 0 && ageValue <= 300)
                  ) {
                    formik.setFieldValue("weight", sanitizedValue);
                  }
                }}
              />
            </div>
            <Input
              // icon={true}
              label="*Admission Date"
              id="date"
              type="date"
              max={new Date().toISOString().split("T")[0]}
              placeholder={
                formik.values.date ? formik.values.date : "dd/mm/yyyy"
              }
              //TODO should this be className?
              classname={
                formik.errors.date ? styles.datePicker1 : styles.datePicker
              }
              value={formik.values.date}
              error={formik.errors.date}
              onChange={formik.handleChange}
            />
            <Input
              highPriority
              label={`*Emergency Contact`}
              id="contactNumber"
              type="tel"
              placeholder="+91 00000 00000"
              value={formik.values.contactNumber}
              error={formik.errors.contactNumber}
              onChange={formik.handleChange}
            />
          </div>
          <div className={styles.left}></div>
          <div className="col-12 col-md-6">
            <div className="p-4 p-lg-5 d-flex flex-column gap-1 gap-lg-4">
              <h6 className="typography--class-h6 font-face-gr grey70">
                Preferences
              </h6>
              <Input
                dropDown={[
                  { name: "30 min" },
                  { name: "1 hrs" },
                  { name: "2 hrs" },
                  { name: "3 hrs" },
                  { name: "4 hrs" },
                ]}
                selected={selectedPeriod}
                label="Reposition Period"
                id="repositionPeriod"
                placeholder="2 hrs (Recommended)"
                error={formik.errors.repositionPeriod}
                onChange={(e) => {
                  setSelectedPeriod(
                    document.getElementById("repositionPeriod").value
                  );
                  formik.setFieldValue(
                    "repositionPeriod",
                    document.getElementById("repositionPeriod").value
                  );
                }}
              />
              <Input
                dropDown={[
                  { name: "None" },
                  { name: "Left" },
                  { name: "Right" },
                  { name: "Both" },
                ]}
                selected={selectedPosition}
                label="Patient Restricted Side"
                id="restrictedSide"
                placeholder="None"
                error={formik.errors.restrictedSide}
                onChange={(e) => {
                  setSelectedPosition(
                    document.getElementById("restrictedSide").value
                  );
                  formik.setFieldValue(
                    "restrictedSide",
                    document.getElementById("restrictedSide").value
                  );
                }}
              />
              <Input
                dropDown={[{ name: "Enable" }, { name: "Disable" }]}
                selected={selectedNurseCall}
                label="Nurse Assistance Call"
                id="nurseAssistanceCall"
                error={formik.errors.nurseAssistanceCall}
                onChange={(e) => {
                  setSelectedNurseCall(
                    document.getElementById("nurseAssistanceCall").value
                  );
                  formik.setFieldValue(
                    "nurseAssistanceCall",
                    document.getElementById("nurseAssistanceCall").value ===
                      "Enable"
                      ? true
                      : false
                  );
                }}
              />
              <Input
                dropDown={[{ name: "Enable" }, { name: "Disable" }]}
                selected={selectedSittingUpAlert}
                label="Device Side Alarm"
                id="sitingUpAlert"
                error={formik.errors.sitingUpAlert}
                onChange={(e) => {
                  setSelectedSittingUpAlert(
                    document.getElementById("sitingUpAlert").value
                  );
                  formik.setFieldValue(
                    "sitingUpAlert",
                    document.getElementById("sitingUpAlert").value === "Enable"
                      ? true
                      : false
                  );
                }}
              />
              <div className="d-flex justify-content-evenly mt-3 mb-0">
                {bedStatus == "Offline" ? (
                  <p style={{ color: "#eb5500", marginTop: "5px" }}>
                    Device Offline
                  </p>
                ) : (
                  <>
                    <Button
                      onClick={formik.handleSubmit}
                      type="submit"
                      text="Update"
                    />
                    <Button
                      text="Cancel"
                      onClick={() => {
                        formik.handleReset();
                        setError("");
                        handleClose();
                      }}
                      variant="secondary"
                    />
                  </>
                )}
              </div>
              {error ? (
                <small className={`typography--class-footer red`}>
                  {error}
                </small>
              ) : (
                <></>
              )}
            </div>
            <div
              className={`d-flex justify-content-center align-items-end py-3 mt-0 ${styles.borderTop}`}
            >
              <button
                onClick={handleDischargeButtonClick}
                className={`typography--class-body ${styles.buttonDischarge}`}
                hidden={
                  !location.state
                    ?.isBedOccupied /*Show the Discharge button only when the bed is occupied*/
                }
              >
                <img src={discharge} className="me-3" alt="" />
                Discharge Patient
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <DischargeConfirmationModal
        showDischargeConfirmationModal={showDischargeConfirmationModal}
        setShowDischargeConfirmationModal={setShowDischargeConfirmationModal}
        patient={patient}
        handleCloseDischargeConfirmationModal={
          handleCloseDischargeConfirmationModal
        }
      />{" "}
    </>
  );
};

export default Settings;
