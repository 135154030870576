import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../Button";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./shareFeedback.module.scss";
import Input from "../../../Input";
import InfoIcon from "../../../../assets/images/infoIcon.png";
import TooltipComponent from "../../../Tooltip";

function ShareFeedback({ show, setShow }) {
  const [title, setTitle] = useState("");
  const [feedback, setFeedback] = useState("");

  const handleSubmit = () => {
    if (title && feedback) {
      const mailtoLink = `mailto:support@cureous.in?subject=${encodeURIComponent(
        title
      )}&body=${encodeURIComponent(feedback)}`;
      window.location.href = mailtoLink;
      setTitle("");
      setFeedback("");
      setShow(false);
    }
  };

  const handleCancel = () => {
    setTitle("");
    setFeedback("");
    setShow(false);
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        scrollable={true}
        size="xl"
        centered
        className={styles.Offcanvas1AddNurse1}
      >
        <Modal.Header className={styles.OffcanvasHeaderAddNurse}>
          <div className="d-flex ">
            <Modal.Title
              className={`typography--class-h1 font-face-gr ${styles.offCanvasTitleAddNurse}`}
            >
              Share Feedback
            </Modal.Title>
            <TooltipComponent
              title={
                "Allows you to share app related feedback with the Cureous team."
              }
              infoIcon={styles.infoIcon}
            />
          </div>
          <CloseIcon
            onClick={() => {
              setShow(false);
            }}
            style={{
              cursor: "pointer",
              color: "#8D8D8D",
            }}
          />
        </Modal.Header>
        <div className={`${styles.modelBody}`}>
          <Input
            className={`${styles.modelBodyHeaderAddNurseInput}`}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            label="Title"
            id="title"
            type="text"
          />
          <Input
            textarea
            className={`${styles.modelBodyHeaderAddNurseInput1}`}
            value={feedback}
            onChange={(e) => {
              setFeedback(e.target.value);
            }}
            label="Feedback"
            id="feedback"
            type="text"
          />
        </div>
        <Modal.Footer
          className={`d-flex justify-content-evenly  ${styles.footer}`}
        >
          <Button type="submit" text="Send" onClick={handleSubmit} />
          <Button text="Cancel" variant="secondary" onClick={handleCancel} />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ShareFeedback;
