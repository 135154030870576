import React, { useEffect, useState } from "react";
import styles from "./bedDetails.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi } from "../../utils/ServiceManger";
import "react-calendar/dist/Calendar.css";
import Header from "../../components/Header";
import WardHeader from "../../components/WardHeader";
import { socket } from "../../App";
import PatientDetails from "./components/PatientDetails";
import MobilityGraph from "./components/MobilityGraph";
import PatientStats from "./components/PatientStats";
import AssignNurse from "../Home/components/AssignNurse";

const BedDetails = ({ onData }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState({});
  const [patient, setPatient] = useState({});
  const [loading, setLoading] = useState(false);

  const [bedStatus, setBedStatus] = useState();
  const [bedSeconds, setBedSeconds] = useState();

  useEffect(() => {
    location?.state?.id ? getData() : navigate("/login");
    socket.on("web/patient/status", (socketData) => {
      setBedStatus(
        JSON.parse(socketData).bed_id === location?.state?.id
          ? JSON.parse(socketData).status[0]
          : data?.status[0]
      );
      setBedSeconds(
        JSON.parse(socketData).bed_id === location?.state?.id
          ? JSON.parse(socketData).timerStartTime
          : data?.timerStartTime
      );
    });
  }, [bedSeconds]);

  const [showPatientSettingsModal, setShowPatientSettingsModal] = useState(
    !location.state.isBedOccupied || false
  );

  const getData = () => {
    setLoading(true);
    getApi(
      `beds/info?room_id=${location.state.room.id}&bed_id=${location.state.id}`,
      (res) => {
        setLoading(false);
        if (res?.status) {
          setData(res?.data?.bed);
          setBedStatus(res?.data?.bed?.status[0]);
          setBedSeconds(res?.data?.bed?.timerStartTime);
          setPatient(res?.data?.bed?.patient);
        }
      },
      (err) => {
        console.log(err?.message);
        if (err?.message === "Request failed with status code 403") {
          navigate("/login");
        }
      }
    );
  };

  return (
    <>
      <Header />
      <section>
        <WardHeader
          roomName={location.state.room.name}
          loading={loading}
          patient={patient}
          showPatientSettingsModal={showPatientSettingsModal}
          setShowPatientSettingsModal={setShowPatientSettingsModal}
        />
        <div className={`d-flex flex-column flex-md-row  ${styles.bgWhite}`}>
          <PatientDetails
            room_id={location.state.room.id}
            patient={patient}
            bed_id={data.bed_id}
            internalBedId={data.id}
            bedStatus={bedStatus}
            bedSeconds={bedSeconds}
          />
          <div className={`col-12 col-md-8 p-14 ${styles.bgGrey}`}>
            <PatientStats
              room={location.state.room}
              onData={onData}
              patient={patient}
              internalBedId={data.id}
              bedStatus={bedStatus}
            />
            <MobilityGraph bedStatus={bedStatus} patient={patient} />

            <div style={{ display: "none" }}>
              <AssignNurse roomData={location.state.room} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BedDetails;
