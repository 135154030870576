import React, { useEffect, useState } from "react";
import styles from "./login.module.scss";
import logo from "../../assets/images/logo.png";
import dashboard1 from "../../assets/images/Dashboard-Ward 1.png";
import dashboard2 from "../../assets/images/Dashboard-Ward 2.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Carousel, Modal } from "react-bootstrap";
import Input from "../../components/Input";
import ForgotPassword from "../../components/Header/components/ForgotPassword";
import Button from "../../components/Button";
import { useFormik } from "formik";
import { postApi } from "../../utils/ServiceManger";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Email Required";
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    //   errors.email = "Invalid email address";
  }
  if (!values.password) {
    errors.password = "Password Required";
  }
  return errors;
};

const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      email: "",
      password: "",
      is_remember: false,
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("is_remember", values.is_remember);
      formData.append("fcmToken", localStorage.getItem("fcmToken"));
      postApi(
        "login",
        formData,
        (res) => {
          if (res?.status) {
            if (res?.data?.id) {
              //admin
              localStorage.setItem("Id", res?.data?.id);
            }
            if (res?.data?.room) {
              //nurse
              localStorage.setItem("room_id", res?.data?.room.id);
              localStorage.setItem("hospital_id", res?.data?.room?.hospital_id);
              localStorage.setItem("room_name", res?.data?.room?.name);
            }
            localStorage.setItem("token", res?.data?.token?.token);
            localStorage.setItem("expiry", res?.data?.token?.expires_at);
            window.location.reload();
            navigate("/");
            resetForm({ values: "" });
          } else {
            setError(res?.message);
          }
        },
        (err) =>
          setError(
            err?.message.toString() == "Network Error"
              ? "Network Error"
              : "Wrong Credentials!"
          )
      );
    },
  });

  const [show, setShow] = useState("");
  const [showForgotPass, setShowForgotPass] = useState(false);

  function ContactUs({ show, setShow }) {
    const handleCancel = () => {
      setShow(false);
    };

    return (
      <div>
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          scrollable={true}
          size="lg"
          centered
          className={styles.Offcanvas1AddNurse1}
        >
          <Modal.Header className={styles.OffcanvasHeaderAddNurse}>
            <Modal.Title
              className={`typography--class-h1 font-face-gr ${styles.offCanvasTitleAddNurse}`}
            >
              Contact us
            </Modal.Title>
            <CloseIcon
              onClick={() => {
                setShow(false);
              }}
              style={{
                cursor: "pointer",
                color: "#8D8D8D",
              }}
            />
          </Modal.Header>
          <div className={`${styles.modelBody}`}>
            <h5 className={`typography--class-caption ${styles.keyPara}`}>
              Email ID:{" "}
              <a
                target="_blank"
                href="mailto:support@cureous.in?subject=&body="
              >
                support@cureous.in
              </a>
            </h5>
            <h5 className={`typography--class-caption ${styles.keyPara}`}>
              Phone Number:{" "}
              <span className={` ${styles.valuePara}`}>+91 8590153969</span>
            </h5>
            <h5 className={`typography--class-caption ${styles.keyPara}`}>
              Address:{" "}
              <span className={` ${styles.valuePara}`}>
                IKP EDEN, 16, Bhuvanappa Layout, Tavarekere Main Rd, Bengaluru,
                Karnataka 560029
              </span>
            </h5>
            <h5 className={`typography--class-caption ${styles.keyPara}`}>
              Website:{" "}
              <a target="_blank" href="https://www.cureous.in/">
                www.cureous.in
              </a>
            </h5>
          </div>
        </Modal>
      </div>
    );
  }

  const expiry = localStorage.getItem("expiry");

  return (
    <>
      {new Date().getTime() > new Date(expiry).getTime() ? (
        <section>
          <div className="d-flex flex-column flex-md-row h-100">
            <div className={`col-12 col-md-7 ${styles.left} container-fluid`}>
              <Link to="/">
                <img
                  className={`m-3 m-md-4 ${styles.logo}`}
                  src={logo}
                  alt=""
                />
              </Link>
              <Carousel
                controls={false}
                indicators={true}
                className={`d-flex flex-column-reverse justify-content-center align-items-center gap-3 gap-lg-0 ${styles.carousel}`}
              >
                <Carousel.Item>
                  <div
                    className={`d-flex flex-column justify-content-evenly align-items-center gap-2 gap-md-4 ${styles.item}`}
                  >
                    <div>
                      <img
                        className="d-block"
                        style={{ height: "266px", width: "372px" }}
                        src={dashboard1}
                        alt="First slide"
                      />
                    </div>
                    <h2 className="typography--class-h1 text-center font-face-gr">
                      <span>Monitor patients and get</span> Real-Time Alerts
                    </h2>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div
                    className={`d-flex flex-column justify-content-evenly align-items-center gap-4 ${styles.item}`}
                  >
                    <div>
                      <img
                        className="d-block"
                        style={{ width: "386.74px", height: "310px" }}
                        src={dashboard2}
                        alt="First slide"
                      />
                    </div>
                    <h2 className="typography--class-h1 text-center font-face-gr">
                      <span>Personalize Patients</span> Preferences
                    </h2>
                  </div>
                </Carousel.Item>
              </Carousel>
              <a
                href="https://www.cureous.in/"
                target="_blank"
                className={`typography--class-footer  ${styles.center}  grey50 text-center`}
              >
                www.cureous.in
              </a>
            </div>
            <div className={`col-12 col-md-5 ${styles.right} container-fluid`}>
              <div className={`mx-auto my-4 my-xxl-5 ${styles.containerRight}`}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <p className={`typography--class-caption ${styles.topText}`}>
                    ORGANISATION LOG IN
                  </p>
                  <p className={` ${styles.topText1}`}>Version 1.0.4</p>
                </div>

                <h2 className="typography--class-h1 font-face-gr">Welcome</h2>
                <p
                  className={`typography--class-caption mb-4 mb-lg-5 ${styles.location}`}
                >
                  {/* Location */}
                </p>
                <h5 className="typography--class-h5 font-face-gr">Log In</h5>
                <p
                  className={`typography--class-caption mb-4 ${styles.location}`}
                >
                  Please use the Username and Password provided for your
                  organization
                </p>
                <form
                  onSubmit={formik.handleSubmit}
                  className="d-flex flex-column gap-4"
                >
                  <Input
                    id="email"
                    value={formik.values.email}
                    error={formik.errors.email}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setError("");
                    }}
                    onBlur={formik.handleBlur}
                    label="Username"
                    type="text"
                    placeholder="username"
                  />
                  <Input
                    id="password"
                    value={formik.values.password}
                    error={formik.errors.password}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setError("");
                    }}
                    onBlur={formik.handleBlur}
                    label="Password"
                    placeholder="**********"
                  />
                  <div className="d-flex gap-2 align-items-center">
                    <label
                      onClick={() => {
                        setShowForgotPass(true);
                      }}
                      className={`typography--class-caption ${styles.location1}`}
                    >
                      Forgot Password?
                    </label>
                  </div>
                  {error ? (
                    <small className={`typography--class-footer red`}>
                      {error}
                    </small>
                  ) : (
                    <></>
                  )}
                  <Button
                    text="Login"
                    classnames="w-100"
                    onClick={formik.handleSubmit}
                    type="submit"
                  />
                </form>
              </div>
              <div className={`mx-auto ${styles.footerRight}`}>
                <ul className="list-unstyled d-flex justify-content-between">
                  <a
                    href="https://www.cureous.in/privacy-policy"
                    target="_blank"
                    style={{ cursor: "pointer" }}
                    className="typography--class-footer grey50"
                  >
                    Privacy Policy
                  </a>
                  <a
                    href="https://www.cureous.in/terms-and-conditions"
                    target="_blank"
                    style={{ cursor: "pointer" }}
                    className="typography--class-footer grey50"
                  >
                    Terms & Conditions
                  </a>
                  <a
                    onClick={() => setShow(true)}
                    target="_blank"
                    style={{ cursor: "pointer" }}
                    className="typography--class-footer grey50"
                  >
                    Contact Us
                  </a>
                </ul>
              </div>
            </div>
          </div>
          <ForgotPassword
            showForgotPass={showForgotPass}
            setShowForgotPass={setShowForgotPass}
          />
          <ContactUs show={show} setShow={setShow} />
        </section>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default Login;
