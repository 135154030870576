import React from "react";
import styles from "./button.module.scss";

// variant=['primary','secondary']
const Button = (props) => {
  return (
    <button
      onClick={props?.onClick}
      className={`typography--class-body ${
        props?.variant === "secondary" ? styles.secondary : styles.primary
      } ${props?.classnames}`}
      {...props}
    >
      {props.text}
    </button>
  );
};

export default Button;
