import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import blueTick from "../../assets/images/blueTick.png";
import redTick from "../../assets/images/redTick.png";
import yellowTick from "../../assets/images/yellowTick.png";
import { Popover } from "react-bootstrap";
import styles from "./history.module.scss";
import "react-calendar/dist/Calendar.css";
import { getApi } from "../../utils/ServiceManger";
import moment from "moment";
import Header from "../../components/Header";
import WardHeader from "../../components/WardHeader";
import { socket } from "../../App";
import PatientDetails from "./components/PatientDetails";
import PatientLogHistory from "./components/PatientLogHistory";
import AssignNurse from "../Home/components/AssignNurse";

const History = () => {
  const yesterdayDate = new Date();
  yesterdayDate.setHours(yesterdayDate.getHours() - 12);

  const navigate = useNavigate();
  const location = useLocation();
  const [reposition, setReposition] = useState(null);
  const [blue, setBlue] = useState(false);
  const [red, setRed] = useState(false);
  const [yellow, setYellow] = useState(false);
  const [startDate, setStartDate] = useState(yesterdayDate);
  const [endDate, setEndDate] = useState(new Date());
  const [dateRange, setDateRange] = useState(endDate);
  const [data, setData] = useState({});
  const [patient, setPatient] = useState({});
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [bedStatus, setBedStatus] = useState();
  const [bedSeconds, setBedSeconds] = useState();

  useEffect(() => {
    location?.state?.id ? getData() : navigate("/login");
    socket.on("web/patient/status", (socketData) => {
      setBedStatus(
        JSON.parse(socketData).bed_id === location?.state?.id
          ? JSON.parse(socketData).status[0]
          : data?.status[0]
      );
      setBedSeconds(
        JSON.parse(socketData).bed_id === location?.state?.id
          ? JSON.parse(socketData).timerStartTime
          : data?.timerStartTime
      );
    });
  }, [bedSeconds]);

  //setting updates should change
  let bedOccupied = location.state;
  if (location?.state?.id) {
    bedOccupied.isBedOccupied = true;
  }

  const [showPatientSettingsModal, setShowPatientSettingsModal] = useState(
    !location.state.isBedOccupied || false
  );
  const handleClose = () => setShowPatientSettingsModal(false);
  const handleShow = () => setShowPatientSettingsModal(true);

  const diffMs = endDate - startDate;
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours

  useEffect(() => {
    setEndDate(new Date());
  }, [diffHrs == 12 && bedStatus]);

  useEffect(() => {
    if (patient?.id) {
      getHistory(patient?.id, startDate, endDate);
      getReposition(patient?.id, startDate, endDate);
    }
  }, [blue, red, yellow, startDate, endDate, patient?.id]);

  const getReposition = (id, startDate, endDate) => {
    getApi(
      `patients/logs/reposition?id=${id}&start_date=${moment(startDate).format(
        "DD/MM/YYYY hh:mm:ss a"
      )}&end_date=${moment(endDate).format("DD/MM/YYYY hh:mm:ss a")}`,
      (res) => {
        if (res?.status) {
          setReposition(res.data.logs);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  let previousDate = new Date();
  previousDate.setDate(previousDate.getDate() - 1);

  const getData = () => {
    setLoading(true);
    getApi(
      `beds/info?room_id=${location.state.room.id}&bed_id=${location?.state?.id}`,
      (res) => {
        setLoading(false);
        if (res?.status) {
          setData(res?.data?.bed);
          setPatient(res?.data?.bed?.patient);
          setBedStatus(res?.data?.bed?.status[0]);
          setBedSeconds(res?.data?.bed?.timerStartTime);
          getHistory(res?.data?.bed?.patient?.id, startDate, endDate);
        }
      },
      (err) => {
        console.log(err?.message);
        if (err?.message === "Request failed with status code 403") {
          navigate("/login");
        }
      }
    );
  };
  const getHistory = (id, startDate, endDate) => {
    if (!bedStatus) setLoading(true);
    getApi(
      `patients/logs/history?id=${id}&start_date=${moment(startDate).format(
        "DD/MM/YYYY hh:mm:ss a"
      )}&end_date=${moment(endDate).format("DD/MM/YYYY hh:mm:ss a")}${
        blue ? "&filter[]=reposition" : ""
      }${red ? "&filter[]=position" : ""}${yellow ? "&filter[]=call" : ""}${
        blue === false && red === false && yellow === false
          ? "&filter[]=all"
          : ""
      }`,
      (res) => {
        setLoading(false);
        if (res?.status) {
          setHistory(res?.data?.logs);
        }
      },
      (err) => console.log(err)
    );
  };

  const popoverFilter = (
    <Popover id="popover-basic" className={styles.filterPop}>
      <Popover.Body className="d-flex gap-3">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setBlue(!blue)}
          className={`${styles.correctDivBlue}`}
        >
          {blue ? <img src={blueTick} alt="" /> : <></>}
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setRed(!red)}
          className={`${styles.correctDivRed}`}
        >
          {red ? <img src={redTick} alt="" /> : <></>}
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setYellow(!yellow)}
          className={`${styles.correctDivYellow}`}
        >
          {yellow ? <img src={yellowTick} alt="" /> : <></>}
        </div>
      </Popover.Body>
    </Popover>
  );

  const onFilterChange = (res) => {
    setDateRange(res);
    setStartDate(res[0]);
    setEndDate(res[1]);
  };

  return (
    <>
      <Header />
      <section>
        <WardHeader
          roomName={location.state.room.name}
          loading={loading}
          patient={patient}
          showPatientSettingsModal={showPatientSettingsModal}
          setShowPatientSettingsModal={setShowPatientSettingsModal}
        />
        <div className={`d-flex flex-column flex-md-row ${styles.bgWhite}`}>
          <PatientDetails
            room_id={location.state.room.id}
            patient={patient}
            bed_id={data.bed_id}
            internalBedId={data.id}
            bedStatus={bedStatus}
            bedSeconds={bedSeconds}
          />
          <PatientLogHistory
            onChange={onFilterChange}
            reposition={reposition}
            popoverFilter={popoverFilter}
            dateRange={dateRange}
            history={history}
          />
        </div>
        <div style={{ display: "none" }}>
          <AssignNurse roomData={location.state.room} />
        </div>
      </section>
    </>
  );
};

export default History;
