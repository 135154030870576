import React, { useState } from "react";
import "./SideDrawer.css";
import styles from "../../header.module.scss";
import logout from "../../../../assets/images/logout.png";
import { getApi } from "../../../../utils/ServiceManger";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import Button from "../../../Button/index";
import vector from "../../../../assets/images/vector.png";
import dash from "../../../../assets/images/dash.png";
import vector1 from "../../../../assets/images/vector1.png";
import dash1 from "../../../../assets/images/dash1.png";

const SideDrawer = (props) => {
  let drawerClasses = ["side-drawer"];
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const logoutAccount = () => {
    setLoading(true);
    const fcmToken = localStorage.getItem("fcmToken");
    getApi(
      `logout/${fcmToken}`,
      (res) => {
        setLoading(false);
        if (res?.status) {
          localStorage.clear();
          window.location.reload();
          navigate("/login");
        }
      },
      (err) => console.log(err)
    );
  };

  const MyVerticallyCenteredModal = (props) => {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName={`p-3 gap-3 ${styles.logoutModal}`}
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Modal.Header bsPrefix="d-flex justify-content-center">
              <p className="typography--class-caption  grey70 m-0">
                Do you want to Log Out?
              </p>
            </Modal.Header>
            <Modal.Body bsPrefix="d-flex justify-content-center gap-4">
              <Button
                style={{
                  fontSize: "14px",
                  width: "98px",
                }}
                onClick={logoutAccount}
                text="Yes"
              />
              <Button
                style={{
                  fontSize: "14px",
                  width: "98px",
                }}
                text="No"
                variant="secondary"
                onClick={() => setModalShow(false)}
              />
            </Modal.Body>
          </>
        )}
      </Modal>
    );
  };

  if (props.show) {
    drawerClasses = ["side-drawer", "open"];
  }
  const room_id = localStorage.getItem("room_id");

  return (
    <>
      <nav className={drawerClasses.join(" ")}>
        <ul>
          {!room_id && (
            <>
              <li>
                {location.pathname == "/" ? (
                  <div
                    className={`d-flex align-items-center gap-2 ${styles.logout1}`}
                  >
                    <div className="d-flex align-items-center">
                      <img src={dash1} alt="" />
                    </div>
                    <a style={{ color: "#0762C8" }} href="/">
                      Dashboard
                    </a>
                  </div>
                ) : (
                  <div
                    className={`d-flex align-items-center gap-2 ${styles.logout1}`}
                  >
                    <div className="d-flex align-items-center">
                      <img src={dash} alt="" />
                    </div>
                    <a style={{ color: "#8D8D8D" }} href="/">
                      Dashboard
                    </a>
                  </div>
                )}
              </li>
              <li>
                {location.pathname == "/kpi" ? (
                  <div
                    className={`d-flex align-items-center gap-2 ${styles.logout1}`}
                  >
                    <div className="d-flex align-items-center">
                      <img src={vector} alt="" />
                    </div>
                    <a style={{ color: "#0762C8" }} href="/kpi">
                      Statistics
                    </a>
                  </div>
                ) : (
                  <div
                    className={`d-flex align-items-center gap-2 ${styles.logout1}`}
                  >
                    <div className="d-flex align-items-center">
                      <img src={vector1} alt="" />
                    </div>
                    <a style={{ color: "#8D8D8D" }} href="/kpi">
                      Statistics
                    </a>
                  </div>
                )}
              </li>
            </>
          )}
          <li>
            <a>
              <div
                onClick={() => setModalShow(true)}
                className={`d-flex align-items-center gap-2 ${styles.logout}`}
              >
                <div className="d-flex align-items-center">
                  <img src={logout} alt="" />
                </div>
                <a>Log Out</a>
              </div>
            </a>
          </li>
        </ul>
      </nav>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};
export default SideDrawer;
