/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import plus from "../../assets/images/plus.png";
import errorTriangle from "../../assets/images/errorTriangle.png";
import outOfBed from "../../assets/images/outOfBed.png";
import call from "../../assets/images/call.png";
import cross from "../../assets/images/cross.png";
import care from "../../assets/images/care.png";
import fallAlert from "../../assets/images/fallAlert.png";
import styles from "./card.module.scss";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";

const Card = (props) => {
  let timeString;
  let timeStringRep;
  // console.log(props);
  const [timerOfBed, setTimerOfBed] = useState();
  const [timerOfBedRep, setTimerOfBedRep] = useState();

  let timer1 = props.timerStartTime;
  let timer = props.secondLeft;
  // console.log(timer1, timer);

  useEffect(() => {
    const timerEffect = setInterval(() => {
      let now = new Date().getTime();
      let distance = (now - timer) / 1000;
      let time = 600 - distance;

      if (time > 0) {
        let date = new Date(0);
        date.setUTCSeconds(time);
        timeString = date.toISOString().slice(14, 19);
      } else {
        timeString = "DELAYED";
      }

      if (timer) {
        setTimerOfBed(timeString);
      }
    }, 1000);
    return () => clearInterval(timerEffect);
  }, [props?.status?.toLowerCase() !== "out of bed" && timeString]);

  useEffect(() => {
    const timerEffect = setInterval(() => {
      let now = new Date().getTime();
      let distance = (now - timer1) / 1000;
      let time = 600 - distance;

      if (time > 0) {
        let date = new Date(0);
        date.setUTCSeconds(time);
        timeStringRep = date.toISOString().slice(14, 19);
      } else {
        timeStringRep = "DELAYED";
      }

      if (timer1) {
        setTimerOfBedRep(timeStringRep);
      }
    }, 1000);
    return () => clearInterval(timerEffect);
  }, [props.timerStartTime]);

  // console.log(timerOfBedRep)

  return (
    <>
      {props?.status === "Unoccupied" ? (
        <Link
          to={props?.link}
          state={props?.linkState}
          className={`d-flex justify-content-center align-items-center ${
            styles.primary
          } ${props?.big ? styles.bigCard : styles.smallCard} ${styles.border}`}
        >
          <h6 className="typography--class-h1 font-face-gr grey20">+</h6>
        </Link>
      ) : props?.status === "On Bed" ? (
        <Link
          to={props?.link}
          state={props?.linkState}
          className={`d-flex justify-content-center align-items-center ${
            styles.primary
          } ${props?.big ? styles.bigCard : styles.smallCard} ${styles.border}`}
        >
          <h6 className="typography--class-h6 font-face-gr grey20">ON BED</h6>
        </Link>
      ) : props?.status === "Disconnected" ? (
        <Link
          to={props?.link}
          style={{ paddingTop: "5px" }}
          state={props?.linkState}
          className={`d-flex flex-column justify-content-center align-items-center gap-2 ${
            styles.primary
          } ${props?.big ? styles.bigCard : styles.smallCard} ${styles.error}`}
        >
          <img className={styles.errorTriangle} src={errorTriangle} alt="" />
          <h6
            style={{
              color: "#EB5500",
              fontSize: "0.875rem",
              lineHeight: "1.375rem",
              letterSpacing: "-0.02em",
              paddingTop: "5px",
            }}
            className="font-face-gr red text-center"
          >
            SENSOR <br />
            ERROR
          </h6>
        </Link>
      ) : props?.status === "Caremode" ? (
        <Link
          to={props?.link}
          state={props?.linkState}
          style={{ paddingTop: "5px" }}
          className={`d-flex flex-column justify-content-center align-items-center gap-2 ${
            styles.primary
          } ${props?.big ? styles.bigCard : styles.smallCard} ${
            styles.careDiv
          }`}
        >
          <img className={styles.care} src={care} alt="" />
          <h6
            style={{
              fontSize: "0.875rem",
              lineHeight: "1.375rem",
              letterSpacing: "-0.02em",
              paddingTop: "5px",
            }}
            className="typography--class-h6 font-face-gr green text-center"
          >
            CARE <br />
            MODE
          </h6>
        </Link>
      ) : props?.status === "Offline" ? (
        <Link
          to={props?.link}
          state={props?.linkState}
          style={{ paddingTop: "5px" }}
          className={`d-flex flex-column justify-content-center align-items-center gap-2 ${
            styles.primary
          } ${props?.big ? styles.bigCard : styles.smallCard} ${styles.error}`}
        >
          <img className={styles.errorTriangle} src={errorTriangle} alt="" />
          <h6
            style={{
              color: "#EB5500",
              fontSize: "0.875rem",
              lineHeight: "1.375rem",
              letterSpacing: "-0.02em",
              paddingTop: "5px",
            }}
            className="font-face-gr red text-center"
          >
            DEVICE <br />
            OFFLINE
          </h6>
        </Link>
      ) : props?.status === "Sensor Disconnected" ? (
        <Link
          to={props?.link}
          state={props?.linkState}
          style={{ paddingTop: "5px" }}
          className={`d-flex flex-column justify-content-center align-items-center gap-2 ${
            styles.primary
          } ${props?.big ? styles.bigCard : styles.smallCard} ${styles.error}`}
        >
          <h6
            style={{
              color: "#EB5500",
              fontSize: "0.875rem",
              lineHeight: "1.375rem",
              letterSpacing: "-0.02em",
              paddingTop: "5px",
            }}
            className="font-face-gr red text-center"
          >
            Sensor <br />
            Disconnected
          </h6>
        </Link>
      ) : props?.status === "Edge" ? (
        <Link
          to={props?.link}
          state={props?.linkState}
          className={`d-flex justify-content-between align-items-center gap-2 ${
            styles.primary
          } ${props?.big ? styles.bigCard : styles.smallCard} ${styles.error1}`}
        >
          <div className={styles.stripe1} />
          <h6
            style={{
              fontSize: "0.875rem",
              lineHeight: "1.375rem",
              letterSpacing: "-0.02em",
              paddingTop: "5px",
            }}
            className="typography--class-h6 font-face-gr red text-center"
          >
            BED <br />
            EDGE
          </h6>
          <div className={styles.stripe2} />
        </Link>
      ) : props?.status === "Sitting Up" ? (
        <Link
          to={props?.link}
          state={props?.linkState}
          className={`d-flex flex-column justify-content-center align-items-center gap-2 ${
            styles.primary
          } ${props?.big ? styles.bigCard : styles.smallCard} ${styles.bgRed}`}
        >
          <h6 className="typography--class-h6 font-face-gr text-white text-center">
            SITTING UP
          </h6>
        </Link>
      ) : props?.status?.toLowerCase() === "out of bed" ? (
        <Link
          to={props?.link}
          state={props?.linkState}
          className={`d-flex flex-column justify-content-between align-items-center ${
            styles.primary
          } ${props?.big ? styles.bigCard : styles.smallCard} ${styles.bgRed}`}
        >
          <div
            className={`d-flex flex-column align-items-center justify-content-center ${
              props?.big ? "gap-4" : "gap-3"
            }`}
            style={{ flex: 2 }}
          >
            <div className={styles.outOfBedDiv}>
              <img src={outOfBed} alt="" />
            </div>
            <h6 className="typography--class-h6 font-face-gr text-white text-center">
              BED EXIT
            </h6>
          </div>
          {timerOfBed ? (
            <div className={styles.footerCard}>
              <h5
                style={{ fontSize: "20px", color: "black" }}
                className="typography--class-h5 font-face-gr text-white text-center m-0"
              >
                {timerOfBed}
              </h5>
            </div>
          ) : (
            <div className={styles.footerCard}>
              <h5
                style={{ fontSize: "20px" }}
                className="typography--class-h5 font-face-gr text-white text-center m-0"
              >
                {timerOfBedRep}
              </h5>
            </div>
          )}
        </Link>
      ) : props?.status?.toLowerCase() === "fall alert" ? (
        <Link
          to={props?.link}
          state={props?.linkState}
          className={`d-flex flex-column justify-content-between align-items-center ${
            styles.primary
          } ${props?.big ? styles.bigCard : styles.smallCard} ${styles.bgRed1}`}
        >
          <div
            className={`d-flex flex-column align-items-center justify-content-center ${
              props?.big ? "gap-4" : "gap-3"
            }`}
            style={{ flex: 2 }}
          >
            <div className={styles.fallAlert}>
              <img src={fallAlert} alt="" />
            </div>
            <h6 className="typography--class-h6 font-face-gr text-white text-center">
              FALL ALERT
            </h6>
          </div>
        </Link>
      ) : props?.status === "Leaving" ? (
        <Link
          to={props?.link}
          state={props?.linkState}
          className={`d-flex flex-column justify-content-center align-items-center gap-2 ${
            styles.primary
          } ${props?.big ? styles.bigCard : styles.smallCard} ${styles.bgRed}`}
        >
          <h6 className="typography--class-h6 font-face-gr text-white text-center">
            LEAVING
          </h6>
        </Link>
      ) : props?.status === "Patient Call" ? (
        <Link
          to={props?.link}
          state={props?.linkState}
          className={`d-flex flex-column justify-content-between align-items-center ${
            styles.primary
          } ${props?.big ? styles.bigCard : styles.smallCard} ${
            styles.bgYellow
          }`}
        >
          <div
            className={`d-flex flex-column align-items-center justify-content-center ${
              props?.big ? "gap-4" : "gap-3"
            }`}
            style={{ flex: 0.77 }}
          >
            <div className={styles.callDiv}>
              <img src={call} alt="" />
            </div>
            <h6 className="typography--class-h6 font-face-gr text-white text-center">
              CALL
            </h6>
          </div>
          {/*<div className={styles.footerCard}>*/}
          {/*  <h5 className="typography--class-h5 font-face-gr text-white text-center m-0">*/}
          {/*    {timerOfBed}*/}
          {/*  </h5>*/}
          {/*</div>*/}
        </Link>
      ) : props?.status === "Reposition Alert" ? (
        <Link
          to={props?.link}
          state={props?.linkState}
          className={`d-flex flex-column justify-content-between align-items-center ${
            styles.primary
          } ${props?.big ? styles.bigCard : styles.smallCard} ${styles.bgBlue}`}
        >
          <div
            className={`d-flex flex-column align-items-center justify-content-center ${
              props?.big ? "gap-4" : "gap-3"
            }`}
            style={{ flex: 2 }}
          >
            <div className={`d-flex ${styles.reposition}`}>
              <div
                style={{
                  paddingLeft: "22px",
                  paddingRight: "7px",
                  paddingBottom: "3px",
                }}
                className={`${styles.crossDiv} `}
              >
                <img className={styles.cross} src={cross} alt="" />
              </div>
              <p
                style={{ paddingRight: "10px", fontSize: "20px" }}
                className="typography--class-h5 font-face-gr red m-0"
              >
                {props.patient.restricted_side == "Left" ? "R" : "L"}
              </p>
            </div>
            <h6 className="typography--class-h6 font-face-gr text-white text-center">
              REPOSITION
            </h6>
          </div>
          {timerOfBed ? (
            <div className={styles.footerCard}>
              <h5
                style={{ fontSize: "20px", color: "black" }}
                className="typography--class-h5 font-face-gr text-white text-center m-0"
              >
                {timerOfBed}
              </h5>
            </div>
          ) : (
            <div className={styles.footerCard}>
              <h5
                style={{ fontSize: "20px" }}
                className="typography--class-h5 font-face-gr text-white text-center m-0"
              >
                {timerOfBedRep}
              </h5>
            </div>
          )}
        </Link>
      ) : props?.status === undefined || props.big ? (
        <Skeleton
          variant="rounder"
          animation="wave"
          sx={{
            fontSize: "1rem",
            borderRadius: "8px",
            // background: "gray",
          }}
          width="181px"
          height="274px"
        />
      ) : (
        <Skeleton
          variant="rounder"
          animation="wave"
          sx={{
            fontSize: "1rem",
            borderRadius: "8px",
            // background: "gray",
          }}
          width="132px"
          height="180px"
        />
      )}
    </>
  );
};

export default Card;
