import React, { useEffect, useState } from "react";
import styles from "./shift.module.scss";
import { Modal } from "react-bootstrap";
import Button from "../../../../../components/Button";
import morningToEvening from "../../../../../assets/images/morningToEvening.png";
import eveningToNight from "../../../../../assets/images/eveningToNight.png";
import nightToMorning from "../../../../../assets/images/nightToMorning.png";
import morningToNight from "../../../../../assets/images/morningToNight.png";
import moment from "moment";

function Shift({ shift, handleShow }) {
  const [show, setShow] = useState(false);

  const handleShiftClose = () => setShow(false);
  const handleShiftShow = () => setShow(true);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = moment().format("hh:mm:ss A");
      const morningTime = moment(shift?.morning_time, "hh:mm A").format(
        "hh:mm:ss A"
      );
      const eveningTime = moment(shift?.evening_time, "hh:mm A").format(
        "hh:mm:ss A"
      );
      const nightTime = moment(shift.night_time, "hh:mm A").format(
        "hh:mm:ss A"
      );
      if (
        currentTime == morningTime ||
        currentTime == eveningTime ||
        currentTime == nightTime
      ) {
        handleShiftShow();
      }
    }, 1000); // Check every second

    return () => {
      clearInterval(interval);
    };
  }, [shift]);

  return (
    <div>
      <Modal
        show={show}
        scrollable={true}
        size="xl"
        centered
        className={styles.OffcanvasShift}
      >
        <Modal.Header className={styles.OffcanvasHeaderShift}>
          <Modal.Title
            className={`typography--class-h5 font-face-gr ${styles.offCanvasTitleShift}`}
          >
            Shift Change
          </Modal.Title>
        </Modal.Header>
        <div className={`${styles.modelBody}`}>
          <div className={` ${styles.divMorningToEvening}`}>
            {shift?.name == "Morning" ? (
              <img
                src={nightToMorning}
                className={` ${styles.morningToEvening}`}
              />
            ) : (
              shift?.name == "Night" && (
                <img
                  src={
                    shift?.total_shift == 3 ? eveningToNight : morningToNight
                  }
                  className={` ${styles.morningToEvening}`}
                />
              )
            )}
            {shift?.total_shift == 3 && shift?.name == "Evening" && (
              <img
                src={morningToEvening}
                className={` ${styles.morningToEvening}`}
              />
            )}
          </div>
          <div className={`${styles.divMorningToEvening}`}>
            <p className={`${styles.OffcanvasShiftText}`}>
              The {shift?.name} shift has started. Please allot nurses to the
              respective patients.
            </p>
          </div>
        </div>

        <Modal.Footer
          className={`d-flex justify-content-evenly  ${styles.footer}`}
        >
          <Button
            type="submit"
            text="Allot"
            onClick={() => {
              handleShiftClose();
              handleShow();
            }}
          />
          <Button
            text="Cancel"
            variant="secondary"
            onClick={() => {
              handleShiftClose();
            }}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Shift;
