import React, { useState } from "react";
import styles from "./input.module.scss";
import TooltipComponent from "../Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Input = (props) => {
  const [isActive, setIsActive] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {props.icon ? (
        <div
          className={`d-flex flex-column align-items-baseline ${
            props?.error ? styles.errorParent : styles.primaryParent
          } `}
        >
          <label
            htmlFor={props?.id}
            className={`typography--class-caption ${styles.primaryLabel} ${
              isActive ? styles.primaryLabelActive : ""
            }`}
          >
            {props?.label}
          </label>
          <div
            className={` ${
              props?.error ? styles.inputGroup1 : styles.inputGroup
            }`}
          >
            <input
              value={props?.value}
              onFocus={() => setIsActive(true)}
              onBlur={() => setIsActive(false)}
              id={props?.id}
              name={props?.id}
              type={props?.type ?? "text"}
              placeholder={props?.placeholder}
              {...props}
            />

            <span style={{ height: "40px" }}>
              <img src={props.icon} alt="" />
            </span>
          </div>
          <small className={`typography--class-footer ${styles.errorText}`}>
            {props?.error}
          </small>
        </div>
      ) : props.highPriority ? (
        <div
          className={`d-flex flex-column align-items-baseline ${
            props?.error ? styles.errorParent : styles.primaryParent
          } ${props?.classname}`}
        >
          <label
            htmlFor={props?.id}
            className={`typography--class-caption ${styles.highPriorityLabel}`}
          >
            {props?.label}
          </label>
          <input
            value={props?.value}
            onFocus={() => setIsActive(true)}
            onBlur={() => setIsActive(false)}
            id={props?.id}
            name={props?.id}
            type={props?.type ?? "text"}
            placeholder={props?.placeholder}
            className={`typography--class-body ${
              props?.error ? styles.errorInput : styles.primaryInput
            }`}
            {...props}
          />
          <small className={`typography--class-footer ${styles.errorText}`}>
            {props?.error}
          </small>
        </div>
      ) : props.predefined ? (
        <div
          className={`d-flex flex-column align-items-baseline ${
            props?.error ? styles.errorParent : styles.primaryParent
          } ${props?.classname}`}
        >
          <label
            htmlFor={props?.id}
            className={`typography--class-caption ${styles.primaryLabel}`}
          >
            {props?.label}
          </label>
          <input
            value={props?.value}
            disabled
            id={props?.id}
            name={props?.id}
            placeholder={props?.placeholder}
            className={`typography--class-body ${
              props?.error ? styles.errorInput : styles.predefined
            }`}
            {...props}
          />
          <small className={`typography--class-footer ${styles.errorText}`}>
            {props?.error}
          </small>
        </div>
      ) : props.dropDown ? (
        <div
          className={`d-flex flex-column align-items-baseline ${
            props?.error ? styles.errorParent : styles.primaryParent
          } ${props?.classname}`}
        >
          <div
            className={`d-flex  align-items-baseline ${
              props?.error ? styles.errorParent : styles.primaryParent
            } ${props?.classname}`}
          >
            <label
              htmlFor={props?.id}
              className={`typography--class-caption ${styles.primaryLabel} ${
                isActive ? styles.primaryLabelActive : ""
              }`}
            >
              {props?.label}
            </label>
            <TooltipComponent
              title={
                props?.id == "repositionPeriod"
                  ? "Choose the required base reposition interval based on the patient’s need. This will be adjusted depending on the patients activity."
                  : props?.id == "restrictedSide"
                  ? "If a patient is restricted from turning to a specific side, selecting the side will allow the Reposition card to display it when the alert pops up."
                  : props?.id == "nurseAssistanceCall"
                  ? "The Nurse Assistance device can be enabled/disabled depending on the patient’s need."
                  : "Enabling the device side alarm will trigger a sound alert from the device."
              }
              index={props?.id}
              infoIcon={styles.infoIcon}
            />
          </div>
          <select
            value={props?.selected}
            onFocus={() => setIsActive(true)}
            onBlur={() => setIsActive(false)}
            className={`typography--class-body ${
              props?.error ? styles.errorInput : styles.primaryInput
            }`}
            name={props?.placeholder}
            id={props?.id}
            {...props}
          >
            {props?.dropDown?.map((x, index) => (
              <option id={index} value={x?.name}>
                {x?.name}
              </option>
            ))}
          </select>
          <small className={`typography--class-footer ${styles.errorText}`}>
            {props?.error}
          </small>
        </div>
      ) : props.textarea ? (
        <div
          className={`d-flex flex-column align-items-baseline ${
            props?.error ? styles.errorParent : styles.primaryParent
          } ${props?.classname}`}
        >
          <label
            htmlFor={props?.id}
            className={`typography--class-caption ${styles.primaryLabel} ${
              isActive ? styles.primaryLabelActive : ""
            }`}
          >
            {props?.label}
          </label>
          <textarea
            style={{ resize: "none" }}
            onFocus={() => setIsActive(true)}
            onBlur={() => setIsActive(false)}
            rows={5}
            cols={35}
            className={`typography--class-body ${
              props?.error ? styles.errorInput : styles.primaryInput
            }`}
            {...props}
          />
        </div>
      ) : (
        <div
          className={`d-flex flex-column align-items-baseline ${
            props?.error ? styles.errorParent : styles.primaryParent
          } ${props?.classname}`}
        >
          <div
            className={`d-flex  align-items-baseline ${
              props?.error ? styles.errorParent : styles.primaryParent
            } ${props?.classname}`}
          >
            <label
              htmlFor={props?.id}
              className={`typography--class-caption ${styles.primaryLabel} ${
                isActive ? styles.primaryLabelActive : ""
              }`}
            >
              {props?.label}
            </label>
            {props?.label == "*Patient ID" && (
              <TooltipComponent
                title={
                  "Please enter the Patient ID allotted by your organization."
                }
                infoIcon={styles.infoIcon}
              />
            )}
          </div>
          {props.label == "Password" ||
          props.label == "Old Password" ||
          props.label == "New Password" ||
          props.label == "Confirm New Password" ? (
            <div
              className={`typography--class-body ${
                props?.error ? styles.errorInput1 : styles.primaryInput1
              }`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "10px",
              }}
            >
              <input
                className={`${styles.passwordInput}`}
                onFocus={() => setIsActive(true)}
                onBlur={() => setIsActive(false)}
                id={props?.id}
                name={props?.id}
                type={!showPassword ? "password" : "text"}
                placeholder={props?.placeholder}
                {...props}
                style={{
                  width: "95%",
                  paddingLeft: "16px",
                  border: "none",
                }}
              />
              <div
                style={{ cursor: "pointer" }}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <VisibilityIcon
                    style={{ color: "#8D8D8D", fontSize: "20px" }}
                  />
                ) : (
                  <VisibilityOffIcon
                    style={{ color: "#8D8D8D", fontSize: "20px" }}
                  />
                )}
              </div>
            </div>
          ) : (
            <input
              onFocus={() => setIsActive(true)}
              onBlur={() => setIsActive(false)}
              id={props?.id}
              name={props?.id}
              type={props?.type ?? "text"}
              placeholder={props?.placeholder}
              className={`typography--class-body ${
                props?.error ? styles.errorInput : styles.primaryInput
              }`}
              {...props}
            />
          )}

          <small className={`typography--class-footer ${styles.errorText}`}>
            {props?.error}
          </small>
        </div>
      )}
    </>
  );
};

export default Input;
