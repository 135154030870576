import { TextField, Typography, Button } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { postApi } from "../../utils/ServiceManger";

function DeviceWifiSetting() {
  const navigate = useNavigate();
  const [error, setError] = useState();

  const formik = useFormik({
    initialValues: {
      dev_id: "",
      wifi_name: "",
      wifi_password: "",
      textarea: "",
    },
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("dev_id", values.dev_id);
      formData.append("wifi_name", values.wifi_name);
      formData.append("wifi_password", values.wifi_password);
      formData.append("textarea", values.textarea);

      postApi(
        "device/credential",
        formData,
        (res) => {
          console.log(res);

          if (res?.status) {
            resetForm({ values: "" });
          } else {
            setError(res?.message);
          }
        },
        (err) => setError(err?.message.toString())
      );
    },
  });

  return (
    <div
      style={{
        padding: "20px",
      }}
    >
      <label htmlFor="select-image">
        <Button
          onClick={() => navigate("/")}
          style={{ marginRight: "41%" }}
          variant="contained"
          color="primary"
          component="span"
        >
          Back
        </Button>
      </label>
      <div
        style={{
          padding: "20px",
          textAlign: "center",
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-column gap-4"
        >
          <div>
            <Typography
              style={{
                marginRight: "48%",
                marginBottom: "10px",
                color: "black",
              }}
            >
              Device Id
            </Typography>
            <TextField
              variant="standard"
              id="dev_id"
              rows={1}
              InputProps={{ disableUnderline: true }}
              value={formik.values.dev_id}
              onChange={formik.handleChange}
              name="dev_id"
              style={{
                width: "50%",
                padding: "8px",
                marginBottom: "20px",
                background: "rgb(238, 238, 238)",
                border: "1px solid lightgrey",
              }}
              maxRows={4}
            />
            <Typography
              style={{
                marginRight: "48%",
                marginBottom: "10px",
                color: "black",
              }}
            >
              Username
            </Typography>
            <TextField
              variant="standard"
              id="wifi_name"
              rows={1}
              InputProps={{ disableUnderline: true }}
              value={formik.values.wifi_name}
              onChange={formik.handleChange}
              name="wifi_name"
              style={{
                width: "50%",
                padding: "8px",
                marginBottom: "20px",
                background: "rgb(238, 238, 238)",
                border: "1px solid lightgrey",
              }}
              maxRows={4}
            />
            <Typography
              style={{
                marginRight: "48%",
                marginBottom: "10px",
                color: "black",
              }}
            >
              Password
            </Typography>
            <TextField
              variant="standard"
              id="wifi_password"
              rows={1}
              InputProps={{ disableUnderline: true }}
              value={formik.values.wifi_password}
              onChange={formik.handleChange}
              name="wifi_password"
              style={{
                width: "50%",
                padding: "8px",
                marginBottom: "20px",
                background: "rgb(238, 238, 238)",
                border: "1px solid lightgrey",
              }}
              maxRows={4}
            />
            <Typography
              style={{
                marginRight: "48%",
                marginBottom: "10px",
                color: "black",
              }}
            >
              TextArea
            </Typography>
            <TextField
              variant="standard"
              multiline
              id="textarea"
              rows={10}
              InputProps={{ disableUnderline: true, border: "none" }}
              value={formik.values.textarea}
              onChange={formik.handleChange}
              name="textarea"
              style={{
                width: "50%",
                marginBottom: "20px",
                padding: "8px",
                background: "rgb(238, 238, 238)",
                border: "1px solid lightgrey",
              }}
              maxRows={4}
            />
          </div>
          <label htmlFor="select-image">
            <Button
              onClick={formik.handleSubmit}
              style={{ alignContent: "centre", width: "50%" }}
              variant="contained"
              color="primary"
              component="span"
            >
              Send
            </Button>
          </label>
        </form>
      </div>
    </div>
  );
}

export default DeviceWifiSetting;
