import React, { useEffect, useState } from "react";
import styles from "./wardheader.module.scss";
import heatmap from "../../assets/images/humanbody.png";
import humanbody1 from "../../assets/images/humanbody1.png";
import logo from "../../assets/images/logoPressure.png";
import { Offcanvas, Modal, Button as RbButton } from "react-bootstrap";
import { Skeleton } from "@mui/material";
import { socket } from "../../App";
import Plot from "react-plotly.js";
import { useLocation } from "react-router-dom";
import { getApi } from "../../utils/ServiceManger";
import moment from "moment";
import TooltipComponent from "../Tooltip";

const WardHeader = ({
  roomName,
  loading,
  setShowPatientSettingsModal,
  showPatientSettingsModal,
  patient,
}) => {
  const handleClose = () => setShowPatientSettingsModal(false);
  const handleShow = () => setShowPatientSettingsModal(true);
  const [heatmapData, setHeatmapData] = useState();
  const [regions, setRegions] = useState();
  const location = useLocation();

  useEffect(() => {
    socket.on("device/status/sensor", (socketData) => {
      let data = JSON.parse(socketData);
      if (data.bed_id == location?.state?.id) {
        setHeatmapData(data.matrix);
      }
    });
  }, []);

  useEffect(() => {
    socket.on("device/plot/region", (socketData) => {
      let data = JSON.parse(socketData);
      if (data.bed_id == location?.state?.id) {
        setRegions(data.regions);
      }
    });
  }, []);

  //for testing only later remove
  const repo =
    patient.reposition_period == 30 ? 30 : patient.reposition_period * 60;

  const arr = regions ? JSON.parse(regions) : [];

  const data = [];

  const newArr = arr.map((subArr) => {
    if (subArr[0][0] === subArr[0][1] && subArr[1][0] === subArr[1][1]) {
      return [
        [subArr[0][0] + 0.5, subArr[0][1] - 0.5],
        [subArr[1][0] + 0.5, subArr[1][1] - 0.5],
        Math.floor(subArr[2] / 60),
      ];
    } else if (subArr[0][0] !== subArr[0][1] && subArr[1][0] == subArr[1][1]) {
      return [
        [subArr[0][0] - 0.5, subArr[0][1]],
        [subArr[1][0] + 0.5, subArr[1][1] - 0.5],
        Math.floor(subArr[2] / 60),
      ];
    } else if (subArr[0][0] == subArr[0][1] && subArr[1][0] !== subArr[1][1]) {
      return [
        [subArr[0][0] - 0.5, subArr[0][1] + 0.5],
        [subArr[1][0], subArr[1][1] + 0.5],
        Math.floor(subArr[2] / 60),
      ];
    } else {
      return [
        [subArr[0][0] - 0.5, subArr[0][1] + 0.5],
        [subArr[1][0] - 0.5, subArr[1][1] + 0.5],
        Math.floor(subArr[2] / 60),
      ];
    }
  });

  for (const [x, y, z] of newArr) {
    data.push({
      x: [x[0], x[1], x[1], x[0], x[0]],
      y: [y[0], y[0], y[1], y[1], y[0]],
      type: "scatter",
      mode: "lines+text",
      text: [`${z.toString()} Minutes`],
      textposition: "top left",
      textfont: {
        size: 15,
        color: z >= repo ? "red" : "#FF6600",
        family: "Gilroy",
      },
      line: {
        width: z >= repo ? 2 : 1,
        color: z >= repo ? "red" : "black",
      },
      showlegend: false,
    });
  }

  const newHeatMap =
    heatmapData &&
    heatmapData.map((row) =>
      row.map((val) => (val !== 0 && val <= 100 ? val : val))
    );

  // create a trace for each box corner marker
  data.push({
    z: newHeatMap,
    zsmooth: "best",
    type: "contour",
    contours: {
      showlabels: true,
      showlines: false,
    },
    hovertemplate: "X: %{x}<br>Y: %{y}<br>Z: %{z}<extra></extra>",
    colorscale: [
      [0, "#ffffff"],
      [0.05, "#000066"],
      [0.1, "#000099"],
      [0.2, "#0033FF"],
      [0.25, "#0066FF"],
      [0.3, "#0099FF"],
      [0.4, "#00CCFF"],
      [0.45, "#00FFFF"],
      [0.5, "#33FF99"],
      [0.6, "#00FF00"],
      [0.65, "#99FF00"],
      [0.7, "#CCFF00"],
      [0.75, "#FFFF00"],
      [0.8, "#FFCC33"],
      [0.9, "#FF9900"],
      [0.95, "#FF6600"],
      [1, "#FF0000"],
    ],
    showscale: true,
  });

  const layout = {
    dragmode: false,
    margin: { l: 30, r: 10, t: 0, b: 0 },
    borderRadius: "15px",
    images: [
      {
        source: heatmap,
        xref: "x",
        yref: "y",
        x: 15.5,
        y: 9,
        sizex: 16,
        sizey: 9,
        opacity: 1,
        sizing: "stretch",
        opacity: 1,
        layer: "above",
      },
      {
        source: humanbody1,
        xref: "x",
        yref: "y",
        x: 15.5,
        y: 9,
        sizex: 16.5,
        sizey: 9,
        layer: "above",
      },
    ],
    xaxis: {
      range: [16, 0],
      visible: false,
      tickvals: [16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
      showgrid: false,
    },
    yaxis: { range: [-0.7, 10.7], visible: false, showgrid: false },
  };

  const config = {
    displayModeBar: false, // Disable the modebar
    responsive: true, // Enable responsive layout
    updatemenus: [
      {
        // Disable the updatemenus
        type: "buttons",
        showactive: false,
        buttons: [],
      },
    ],
  };

  const yesterdayDate = new Date();
  yesterdayDate.setHours(yesterdayDate.getHours() - 12);

  const [startDateGraph, setStartDateGraph] = useState(yesterdayDate);

  const [endDateGraph, setEndDateGraph] = useState(new Date());

  const [lastData, setLastData] = useState("");

  const getLastHistory = (id, startDateGraph, endDateGraph) => {
    getApi(
      `patients/logs/history?id=${id}&start_date=${moment(
        startDateGraph
      ).format("DD/MM/YYYY hh:mm:ss a")}&end_date=${moment(endDateGraph).format(
        "DD/MM/YYYY hh:mm:ss a"
      )}&filter[]=first`,
      (res) => {
        if (res?.status) {
          setLastData(res?.data?.logs);
        }
      },
      (err) => console.log(err)
    );
  };

  useEffect(() => {
    if (patient?.id) {
      getLastHistory(patient?.id, startDateGraph, endDateGraph);
    }
  }, [startDateGraph, endDateGraph, patient?.id, lastData]);

  function formatTimeDifference(lastAlertTime, currentTime) {
    const timeDifference = moment.duration(currentTime.diff(lastAlertTime));

    if (timeDifference.asSeconds() < 60) {
      return `${Math.floor(timeDifference.asSeconds())} sec`;
    } else if (timeDifference.asMinutes() < 60) {
      return `${Math.floor(timeDifference.asMinutes())} min`;
    } else if (timeDifference.asHours() < 24) {
      return `${Math.floor(timeDifference.asHours())} hr`;
    } else {
      return `${Math.floor(timeDifference.asDays())} days`;
    }
  }

  const lastAlertTime = moment(lastData?.alert_time);
  const currentTime = moment();

  const formattedTimeDifference = formatTimeDifference(
    lastAlertTime,
    currentTime
  );

  return (
    <div className="d-flex flex-column flex-md-row">
      <div
        className={`col-12 col-md-3 py-4 border-end-0 ${styles.headingLeft}`}
      >
        <h5
          className={`typography--class-h5 font-face-gr text-center cureousGrey`}
        >
          {roomName}
        </h5>
      </div>
      <div className={`col-12 col-md-9 ${styles.heading}`}>
        <div className="d-flex flex-column flex-md-row px-4 py-3 py-md-0 align-items-center justify-content-between h-100">
          <div className="d-flex flex-column mt-2">
            <p
              className={`typography--class-caption fw-normal mb-1 mb-md-2 grey50`}
            >
              Detailed View
            </p>
            <h5
              className={`typography--class-h5 font-face-gr text-center cureousGrey`}
            >
              Dashboard
            </h5>
          </div>
          {loading ? (
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: "1rem",
                borderRadius: "8px",
              }}
              width="150px"
            />
          ) : (
            <>
              <button
                onClick={handleShow}
                className={`d-flex align-items-center gap-1 typography--class-caption grey50`}
              >
                <div className="d-flex align-items-center">
                  <img src={logo} alt="" className={` ${styles.logo}`} />
                </div>
                Show Pressure Map
              </button>
            </>
          )}
        </div>
      </div>
      <>
        <Offcanvas
          placement="end"
          show={showPatientSettingsModal}
          onHide={() => {
            handleClose();
          }}
          className={styles.Offcanvas1}
          backdropClassName={styles.Offcanvas}
        >
          <Offcanvas.Header bsPrefix={styles.OffcanvasHeader} closeButton>
            <div className="d-flex ">
              <Offcanvas.Title
                className={`typography--class-h5 font-face-gr ${styles.offCanvasTitle}`}
              >
                Pressure Map
              </Offcanvas.Title>
              <TooltipComponent
                title={
                  "The Pressure Map provides real-time information about the patient's sacral region's relative high pressure points and it’s contact duration."
                }
                infoIcon={styles.infoIcon}
              />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body className={`${styles.box}`}>
            <div className="d-flex mb-0 justify-content-between px-4">
              <div className="d-flex gap-2 align-items-center pt-0">
                <div
                  className={
                    lastData?.log == "Offline"
                      ? styles.redDot1
                      : styles.greenDot
                  }
                />
                <p className="typography--class-footer grey70 m-0">
                  {lastData?.log == "Offline"
                    ? "Last Updated " + formattedTimeDifference + " ago"
                    : "Live"}
                </p>
              </div>
              <div className={`d-flex flex-column pt-0`}>
                <p className="typography--class-caption grey70 m-0">
                  Head Side
                </p>
              </div>
              <div className={`d-flex flex-column pt-0`}></div>
            </div>
            <div className="d-flex justify-content-between px-2 m-0">
              <Plot
                className={styles.heatmap}
                data={data}
                layout={layout}
                config={config}
                useResizeHandler
              />
            </div>
            <div
              className={`d-flex mt-0 justify-content-between px-5 ${styles.footer}`}
            >
              <div className="d-flex gap-2 align-items-center pt-0">
                <div className={styles.redDot} />
                <p className="typography--class-footer grey70 m-0">
                  High Pressure
                </p>
              </div>
              <div className="d-flex gap-2 align-items-center pt-0">
                <div className={styles.green1Dot} />
                <p className="typography--class-footer grey70 m-0">
                  Medium Pressure
                </p>
              </div>
              <div className="d-flex gap-2 align-items-center pt-0">
                <div className={styles.blueDot} />
                <p className="typography--class-footer grey70 m-0">
                  Low Pressure
                </p>
              </div>
              <div className="d-flex gap-2 align-items-center pt-0">
                <div className={styles.redBox} />
                <p className="typography--class-footer grey70 m-0">
                  High Pressure High Contact Time
                </p>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    </div>
  );
};

export default WardHeader;
