import React from "react";
import styles from "../../history.module.scss";
import backButton from "../../../../assets/images/backButton.png";
import downArrow from "../../../../assets/images/downArrow.png";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Skeleton } from "@mui/material";
import moment from "moment";
import { Calendar } from "react-calendar";
import { useNavigate } from "react-router-dom";

function PatientLogHistory({
  popoverFilter,
  onChange,
  reposition,
  dateRange,
  history,
}) {
  const navigate = useNavigate();

  const popoverDate = (
    <Popover id="popover-basic">
      <Popover.Body className={styles.calendar}>
        <Calendar
          className={"border-0 rounded-3"}
          onChange={onChange}
          selectRange={true}
          allowPartialRange={true}
          value={dateRange}
          tileClassName={"typography--class-body grey60"}
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <div className={`w-100 ${styles.bgGrey}`}>
        <>
          <div className="d-flex gap-2">
            <button
              style={{ display: "contents" }}
              onClick={() => navigate(-1)}
            >
              <img className={styles.backButton1} src={backButton} alt="" />
            </button>
            <p
              className={`typography--class-caption grey70 ${styles.statistics}`}
            >
              Patient Statistics <span style={{ height: "20px" }}>{">"}</span>{" "}
              History
            </p>
          </div>
          <div className="d-flex flex-column flex-lg-row w-100 mt-2">
            <div className="w-100">
              <div className="d-flex justify-content-between align-items-center px-3 mt-0">
                <h6 className="typography--class-h6 font-face-gr grey70 m-0">
                  Reposition
                </h6>
                <div className="d-flex gap-md-5">
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popoverFilter}
                    rootClose
                  >
                    <button className="typography--class-caption grey50">
                      Filter
                      <img
                        src={downArrow}
                        className={`ms-1 ${styles.downArrow}`}
                      />
                    </button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popoverDate}
                    //   onToggle={() => setShowDate(!showDate)}
                    rootClose
                  >
                    <button className="typography--class-caption grey50 px-0">
                      Date Range
                      <img
                        // src={showDate ? upArrow : downArrow}
                        src={downArrow}
                        className={`ms-1 ${styles.downArrow}`}
                      />
                    </button>
                  </OverlayTrigger>
                </div>
              </div>
              <div className={`d-flex flex-column align-items-center mt-3  `}>
                <div
                  className={`d-flex bg-white w-100 ${styles.boxHeder} ${styles.borderBHeader} ${styles.boxFixHeader} justify-content-between`}
                >
                  <div className="col-3 my-1 ps-4">
                    <p
                      className={`typography--class-caption opacity-50 cureousGrey m-0 ${styles.alertHeader}`}
                    >
                      Alert Name
                    </p>
                  </div>
                  <div className="col-3 my-1 ps-3">
                    <p
                      className={`typography--class-caption opacity-50 cureousGrey m-0 ${styles.alertHeader}`}
                    >
                      Alert Time
                    </p>
                  </div>
                  <div className="col-3 my-1 ps-0">
                    <p
                      className={`typography--class-caption opacity-50 cureousGrey m-0 ${styles.alertHeader}`}
                    >
                      Responded by
                    </p>
                  </div>
                  <div className="col-2 my-1 ps-0">
                    <p
                      className={`typography--class-caption opacity-50 cureousGrey mb-4 ${styles.alertHeader}`}
                    >
                      Response Time
                    </p>
                  </div>
                </div>
                <div className={`w-100 ${styles.box}`}>
                  {!reposition ? (
                    <div
                      className={`d-flex w-100 justify-content-center ${styles.borderB} ${styles.bgGrey05}`}
                    >
                      <p
                        style={{ margin: "0.1rem 0" }}
                        className="typography--class-body cureousGrey opacity-50"
                      >
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{
                            fontSize: "1rem",
                          }}
                          width={100}
                          height="30px"
                        />
                      </p>
                    </div>
                  ) : (
                    <>
                      {history.length === 0 && (
                        <div
                          className={`d-flex w-100 justify-content-center ${styles.borderB} ${styles.bgGrey05}`}
                        >
                          <p
                            style={{ margin: "0.1rem 0" }}
                            className="typography--class-body cureousGrey opacity-50"
                          >
                            -
                          </p>
                        </div>
                      )}
                    </>
                  )}

                  {history?.length === 0
                    ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((x) => (
                        <div
                          key={x}
                          className={`d-flex bg-white w-100 ${styles.borderB} justify-content-between`}
                        >
                          {reposition ? (
                            <div className="col-4 ps-5">
                              <p className="typography--class-body cureousGrey m-0">
                                -
                              </p>
                            </div>
                          ) : (
                            <div className="col-3 ps-4">
                              <Skeleton
                                variant="text"
                                animation="wave"
                                sx={{
                                  fontSize: "1rem",
                                }}
                                width={100}
                                height="30px"
                              />
                            </div>
                          )}
                          {reposition ? (
                            <div className="col-3 ps-1">
                              <p className="typography--class-body cureousGrey m-0">
                                -
                              </p>
                            </div>
                          ) : (
                            <div className="col-3 ">
                              <Skeleton
                                variant="text"
                                animation="wave"
                                sx={{
                                  fontSize: "1rem",
                                }}
                                width={100}
                                height="30px"
                              />
                            </div>
                          )}
                          {reposition ? (
                            <div className="col-3 ps-4">
                              <p className="typography--class-body cureousGrey m-0">
                                -
                              </p>
                            </div>
                          ) : (
                            <div className="col-3 ps-1">
                              <Skeleton
                                variant="text"
                                animation="wave"
                                sx={{
                                  fontSize: "1rem",
                                }}
                                width={100}
                                height="30px"
                              />
                            </div>
                          )}

                          {reposition ? (
                            <div className="col-2 ps-3">
                              <p className="typography--class-body red mb-0">
                                -
                              </p>
                            </div>
                          ) : (
                            <div className="col-2 pe-1">
                              <Skeleton
                                variant="text"
                                animation="wave"
                                sx={{
                                  fontSize: "1rem",
                                }}
                                width={100}
                                height="30px"
                              />
                            </div>
                          )}
                        </div>
                      ))
                    : history?.map((x) => {
                        const currentDate = moment().format("DD/MM/YYYY");
                        const dateMoment = moment(x?.date, "DD/MM/YYYY");
                        const currentDateMoment = moment(
                          currentDate,
                          "DD/MM/YYYY"
                        );
                        return (
                          <>
                            <div
                              className={`d-flex w-100 justify-content-center ps-4 ${styles.borderB} ${styles.bgGrey05}`}
                            >
                              <p
                                className={`typography--class-caption cureousGrey opacity-50 ${styles.offline1}`}
                              >
                                {dateMoment.isSame(currentDateMoment, "day")
                                  ? "Today"
                                  : dateMoment.isSame(
                                      moment().subtract(1, "day"),
                                      "day"
                                    )
                                  ? "Yesterday"
                                  : x?.date}
                              </p>
                            </div>
                            {x?.logs?.map((y) => (
                              <>
                                {y.log !== "Online" && (
                                  <div
                                    className={`d-flex bg-white w-100 ${styles.borderB} justify-content-between`}
                                  >
                                    <div
                                      className={`${
                                        y?.log === "Patient Call"
                                          ? styles.bgYellow
                                          : y?.log === "Reposition Alert"
                                          ? styles.bgBlue
                                          : y?.log === "Caremode"
                                          ? styles.bgGreen1
                                          : y?.log === "On Bed"
                                          ? styles.bgGray1
                                          : y?.log === "Reposition Done"
                                          ? styles.bgGreen1
                                          : y?.log === "Call Attended"
                                          ? styles.bgGreen1
                                          : y?.log === "Offline"
                                          ? styles.bgWhite1
                                          : styles.bgRed
                                      }`}
                                      style={{ width: "2%" }}
                                    />

                                    {y?.log !== "Offline" ? (
                                      <div className="col-3 ps-1">
                                        <p
                                          className={`typography--class-caption cureousGrey ${styles.offline1}`}
                                        >
                                          {y?.log == "Out of Bed"
                                            ? "Bed Exit"
                                            : y?.log}
                                        </p>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    {y?.log !== "Offline" ? (
                                      <div className="col-3 ps-0">
                                        <p
                                          className={`typography--class-caption cureousGrey ${styles.offline1}`}
                                        >
                                          {moment(y?.alert_time).format(
                                            "hh:mm A"
                                          )}
                                        </p>
                                      </div>
                                    ) : (
                                      <p className={`${styles.offline}`}>
                                        Device Offline (
                                        {moment(y?.alert_time).format(
                                          "hh:mm A"
                                        )}{" "}
                                        -{" "}
                                        {y?.response_dateTime
                                          ? moment(y?.response_dateTime).format(
                                              "hh:mm A"
                                            )
                                          : "NA"}
                                        )
                                      </p>
                                    )}

                                    {y?.nurse_name ? (
                                      <div className="col-3 ps-0">
                                        <p
                                          className={`typography--class-footer `}
                                        >
                                          <div
                                            className={` ${styles.nurseNameDiv} `}
                                          >
                                            <p
                                              className={` ${styles.nurseName} `}
                                            >
                                              {y?.nurse_name}
                                            </p>
                                          </div>
                                        </p>
                                      </div>
                                    ) : (
                                      y?.log !== "Offline" && (
                                        <div className="col-3 ps-4">
                                          <p
                                            style={{ color: "gray" }}
                                            className={`typography--class-caption cureousGrey ${styles.offline1}`}
                                          >
                                            -
                                          </p>
                                        </div>
                                      )
                                    )}

                                    {y?.log !== "Offline" ? (
                                      <div
                                        className={`col-2 ${styles.response_time}`}
                                      >
                                        <p
                                          className={`typography--class-caption  mb-0 text-end`}
                                        >
                                          {y?.log === "Reposition Alert" ||
                                          y?.log === "Out of Bed" ? (
                                            <>
                                              {Number(
                                                y.response_time
                                                  .toString()
                                                  .slice(14, 16) > 9
                                              ) ? (
                                                <div
                                                  className={`typography--class-caption red mb-0 text-end ${styles.alertTime}`}
                                                >
                                                  {y.response_time
                                                    .toString()
                                                    .slice(12, 13) == "0"
                                                    ? y.response_time
                                                        .toString()
                                                        .slice(14, 19)
                                                    : y.response_time
                                                        .toString()
                                                        .slice(11, 19)}
                                                </div>
                                              ) : (
                                                <div>
                                                  {y.response_time
                                                    .toString()
                                                    .slice(12, 13) > "0" ? (
                                                    <div
                                                      className={`typography--class-caption red mb-0 text-end ${styles.alertTime}`}
                                                    >
                                                      {y.response_time
                                                        .toString()
                                                        .slice(11, 19)}
                                                    </div>
                                                  ) : (
                                                    <p
                                                      className={`typography--class-caption cureousGrey ${styles.offline1}`}
                                                    >
                                                      {y.response_time ===
                                                        0 && (
                                                        <div
                                                          style={{
                                                            color: "gray",
                                                          }}
                                                        >
                                                          -
                                                        </div>
                                                      )}
                                                      {y.response_time
                                                        .toString()
                                                        .slice(14, 19)}
                                                    </p>
                                                  )}
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <div
                                              className={`typography--class-caption cureousGrey ${styles.offline1}`}
                                              style={{ color: "gray" }}
                                            >
                                              <>-</>
                                            </div>
                                          )}
                                        </p>
                                      </div>
                                    ) : (
                                      <div>
                                        <></>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            ))}
                          </>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
}

export default PatientLogHistory;
