import React, { useEffect, useState } from "react";
import styles from "./setting.module.scss";
import { Offcanvas } from "react-bootstrap";
import dropLeftIcon from "../../../../assets/images/dropLeftIcon.png";
import copyIcon from "../../../../assets/images/copyIcon.png";
import successIcon from "../../../../assets/images/successIcon.png";
import logout from "../../../../assets/images/logout1.png";
import ShareFeedback from "../ShareFeedback";
import ChangePassword from "../ChangePassword";

const Settings = ({ showSetting, setShowSetting, setModalShow }) => {
  const [show, setShow] = useState(false);
  const [showChangePass, setShowChangePass] = useState(false);

  const handleClose = () => {
    setShowSetting(false);
  };

  const [copiedText, setCopiedText] = useState(null);
  const [showSuccessIcon1, setShowSuccessIcon1] = useState(false);
  const [showSuccessIcon2, setShowSuccessIcon2] = useState(false);

  const copyText = (textToCopy, target) => {
    if (textToCopy) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setCopiedText(textToCopy);
          if (target === 1) {
            setShowSuccessIcon1(true);
            setTimeout(() => {
              setShowSuccessIcon1(false);
            }, 1000);
          } else if (target === 2) {
            setShowSuccessIcon2(true);
            setTimeout(() => {
              setShowSuccessIcon2(false);
            }, 1000);
          }
        })
        .catch((error) => {
          console.error("Failed to copy text: ", error);
        });
    }
  };

  useEffect(() => {
    if (showSuccessIcon1 || showSuccessIcon2) {
      // Reset the success icons after 5 seconds
      const timer = setTimeout(() => {
        setShowSuccessIcon1(false);
        setShowSuccessIcon2(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showSuccessIcon1, showSuccessIcon2]);

  return (
    <>
      <Offcanvas
        placement="end"
        show={showSetting}
        onHide={() => {
          handleClose();
        }}
        className={styles.Offcanvas1}
        backdropClassName={styles.Offcanvas}
      >
        <Offcanvas.Header bsPrefix={styles.OffcanvasHeader} closeButton>
          <Offcanvas.Title
            className={`typography--class-h5 font-face-gr  d-flex flex-row ${styles.offCanvasTitle}`}
          >
            Settings
          </Offcanvas.Title>
        </Offcanvas.Header>
        <div className={` ${styles.offCanvasBody}`}>
          <div className={` ${styles.offCanvasContainer}`}>
            <h5
              className={`typography--class-body font-face-gr ${styles.offCanvasText}`}
            >
              Privacy Settings
            </h5>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setShowChangePass(true)}
              className={` ${styles.offCanvasBody1}`}
            >
              <h6
                className={`typography--class-caption ${styles.offCanvasBodyText}`}
              >
                Change Password
              </h6>
              <img className={styles.dropLeftIcon} src={dropLeftIcon} />
            </div>
          </div>

          <div className={` ${styles.offCanvasContainer}`}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setShow(true)}
              className={` ${styles.offCanvasBody2}`}
            >
              <h5
                className={`typography--class-body font-face-gr ${styles.offCanvasText}`}
              >
                Share Feedback
              </h5>
              <img
                onClick={() => setShow(true)}
                className={styles.dropLeftIcon1}
                src={dropLeftIcon}
              />
            </div>
          </div>

          <div className={` ${styles.offCanvasContainer}`}>
            <h5
              className={`typography--class-body font-face-gr ${styles.offCanvasText}`}
            >
              Contact Us
            </h5>
            <div>
              <div>
                <div className={` ${styles.offCanvasBody1}`}>
                  <h6
                    className={`typography--class-caption ${styles.offCanvasBodyText}`}
                  >
                    support@cureous.in
                  </h6>
                  <img
                    onClick={() => copyText("support@cureous.in", 1)}
                    className={styles.dropLeftIcon}
                    src={showSuccessIcon1 ? successIcon : copyIcon}
                  />
                </div>
                <div className={` ${styles.offCanvasBody1}`}>
                  <h6
                    className={`typography--class-caption ${styles.offCanvasBodyText}`}
                  >
                    +91 8590153969
                  </h6>
                  <img
                    onClick={() => copyText("+91 8590153969", 2)}
                    className={styles.dropLeftIcon}
                    src={showSuccessIcon2 ? successIcon : copyIcon}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={` ${styles.offCanvasContainer}`}>
            <h5
              className={`typography--class-body font-face-gr ${styles.offCanvasText}`}
            >
              About
            </h5>
            <div className={` ${styles.offCanvasBody1}`}>
              <h6
                className={`typography--class-caption ${styles.offCanvasBodyText}`}
              >
                Software version 1.0.4
              </h6>
            </div>
          </div>
        </div>
        <Offcanvas.Body bsPrefix="d-flex flex-column flex-md-row h-100"></Offcanvas.Body>
        <div>
          <button
            onClick={() => setModalShow(true)}
            className={`${styles.button}`}
          >
            <img className={styles.logoutImage} src={logout} />
            <span className={`${styles.buttonText}`}>Log Out</span>
          </button>
        </div>
      </Offcanvas>
      <ShareFeedback show={show} setShow={setShow} />
      <ChangePassword
        showChangePass={showChangePass}
        setShowChangePass={setShowChangePass}
      />
    </>
  );
};

export default Settings;
