import React, { useEffect, useState } from "react";
import { getApi } from "../../../../utils/ServiceManger";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import Reposition from "./Reposition";
import History from "./History";

const PatientStats = ({ patient, internalBedId, bedStatus, onData, room }) => {
  const yesterdayDate = new Date();
  yesterdayDate.setHours(yesterdayDate.getHours() - 12);

  const [reposition, setReposition] = useState(null);
  const [history, setHistory] = useState([]);
  const [startDate, setStartDate] = useState(yesterdayDate);
  const [endDate, setEndDate] = useState(new Date());
  const [startDateHistory, setStartDateHistory] = useState(yesterdayDate);
  const [endDateHistory, setEndDateHistory] = useState(new Date());
  const [dateRange, setDateRange] = useState(endDate);
  const [loading, setLoading] = useState(false);

  const onFilterChange = (res) => {
    setDateRange(res);
    setStartDate(res[0]);
    setEndDate(res[1]);
  };

  useEffect(() => {
    if (patient?.id) {
      getReposition(patient?.id, startDate, endDate);
    }
  }, [startDate, endDate, patient?.id]);

  useEffect(() => {
    if (patient.id) {
      getHistory(patient?.id, startDateHistory, endDateHistory);
    }
  }, [startDateHistory, endDateHistory, patient.id]);

  useEffect(() => {
    setEndDate(new Date());
    setEndDateHistory(new Date());
  }, [bedStatus]);

  const getReposition = (id, startDate, endDate) => {
    if (!reposition) setLoading(true);
    getApi(
      `patients/logs/reposition?id=${id}&start_date=${moment(startDate).format(
        "DD/MM/YYYY hh:mm:ss a"
      )}&end_date=${moment(endDate).format("DD/MM/YYYY hh:mm:ss a")}`,
      (res) => {
        setLoading(false);
        if (res?.status) {
          setReposition(res.data.logs);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getHistory = (id, startDateHistory, endDateHistory) => {
    getApi(
      `patients/logs/history?id=${id}&start_date=${moment(
        startDateHistory
      ).format("DD/MM/YYYY hh:mm:ss a")}&end_date=${moment(
        endDateHistory
      ).format("DD/MM/YYYY hh:mm:ss a")}&filter[]=short`,
      (res) => {
        if (res?.status) {
          setHistory(res.data.logs);
        }
      },
      (err) => console.log(err)
    );
  };

  return (
    <>
      <>
        <p className="typography--class-caption grey70">Patient Statistics</p>
        <div className="d-flex flex-column flex-lg-row mt-4">
          <>
            <Reposition
              onData={onData}
              reposition={reposition}
              patient={patient}
            />
            <History
              room={room}
              reposition={reposition}
              history={history}
              internalBedId={internalBedId}
            />
          </>
        </div>
      </>
    </>
  );
};

export default PatientStats;
