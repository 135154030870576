import axios from "axios";
import { BACKEND_SERVER_MAP } from "./constants";

const instance = axios.create({
  baseURL: `${BACKEND_SERVER_MAP[window.location.origin]}/api/v1`,
});
export const getApi = async (url, onSuccess, onFailure) => {
  const token = localStorage.getItem("token");
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  instance
    .get(url)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onFailure(error);
    });
};
export const postApi = async (url, params, onSuccess, onFailure) => {
  const token = localStorage.getItem("token");
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  instance
    .post(url, params)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onFailure(error);
    });
};
export const deleteApi = async (url, onSuccess, onFailure) => {
  const token = localStorage.getItem("token");
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  instance
    .delete(url)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onFailure(error);
    });
};
