// noinspection UnnecessaryLocalVariableJS

import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import refreshIcon from "../../assets/images/refreshIcon.png";
import SideMenu from "../../assets/images/SideMenu.png";
import logout from "../../assets/images/logout.png";
import { getApi } from "../../utils/ServiceManger";
import { useNavigate, Link } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import Button from "../Button/index";
import styles from "./header.module.scss";
import ShareFeedback from "./components/ShareFeedback";
import Settings from "./components/Settings";

const Header = () => {
  const [modalShow, setModalShow] = useState(false);
  const [showSetting, setShowSetting] = useState(false);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const logoutAccount = () => {
    setLoading(true);
    const fcmToken = localStorage.getItem("fcmToken");
    getApi(
      `logout/${fcmToken}`,
      (res) => {
        setLoading(false);
        if (res?.status) {
          localStorage.clear();
          window.location.reload();
          navigate("/login");
        }
      },
      (err) => console.log(err)
    );
  };

  const DateAndTime = () => {
    function calcFormattedDate(date) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      let time = date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      const formattedDate = `${
        months[date.getMonth()]
      }-${date.getDate()}-${date.getFullYear()} | ${time}`;
      // return "Oct-20-2021 | 3:53 PM";
      return formattedDate;
    }

    const [formattedDate, setFormattedDate] = React.useState(
      calcFormattedDate(new Date())
    );

    React.useEffect(() => {
      const timer = setInterval(() => {
        // Creates an interval which will update the current data every minute
        // This will trigger a rerender every component that uses this component
        setFormattedDate(calcFormattedDate(new Date()));
      }, 30 * 1000); //timeout is in milliseconds
      return () => {
        clearInterval(timer); // Return a function to clear the timer so that it will stop being called on unmount
      };
    });

    const view = (
      <>
        <p
          className={`typography--class-caption fw-normal mb-1 mb-md-2 grey50`}
        >
          {formattedDate}
        </p>
      </>
    );
    return view;
  };

  const MyVerticallyCenteredModal = (props) => {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName={`p-3 gap-3 ${styles.logoutModal}`}
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Modal.Header bsPrefix="d-flex justify-content-center">
              <p className="typography--class-caption  grey70 m-0">
                Do you want to Log Out?
              </p>
            </Modal.Header>
            <Modal.Body bsPrefix="d-flex justify-content-center gap-4">
              <Button
                style={{
                  fontSize: "14px",
                  width: "98px",
                }}
                onClick={logoutAccount}
                text="Yes"
              />
              <Button
                style={{
                  fontSize: "14px",
                  width: "98px",
                }}
                text="No"
                variant="secondary"
                onClick={() => setModalShow(false)}
              />
            </Modal.Body>
          </>
        )}
      </Modal>
    );
  };

  const [isRotating, setIsRotating] = useState(false);

  const handleSendClick = () => {
    setIsRotating(true);

    setTimeout(() => {
      window.innerWidth >= 768 && window.location.reload(false);
    }, 1000);
  };

  const room_id = localStorage.getItem("room_id");

  return (
    <>
      <header className={`${styles.header} `}>
        <nav className="d-flex justify-content-between">
          {!room_id ? (
            <div className="d-flex justify-content-between px-4 ">
              <Link to="/">
                <img src={logo} className={styles.logo} alt="" />
              </Link>
              <div className={` ${styles.nextTime} `}>
                <h5
                  className={`typography--class-footer ${styles.nextTimeData} `}
                >
                  Admin View
                </h5>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-between px-4">
              <Link to="/">
                <img src={logo} className={styles.logo} alt="" />
              </Link>
            </div>
          )}
          <div
            className={`dateWidth ${
              !room_id ? styles.dateAndTime1 : styles.dateAndTime
            } `}
          >
            <DateAndTime />
          </div>
          <div
            className={`d-flex `}
            style={{
              padding: "0 1.11rem",
            }}
          >
            {room_id && (
              <div
                onClick={handleSendClick}
                className={`d-flex  ${styles.refreshContainer}  `}
              >
                <img
                  className={`d-flex ${isRotating ? styles.rotate : ""} ${
                    styles.refreshImage
                  }`}
                  src={refreshIcon}
                  alt=""
                />
                <h5
                  className={` typography--class-footer ${styles.refreshText}`}
                >
                  Refresh
                </h5>
              </div>
            )}

            <div className={` ${styles.backgroundData2} `}>
              <img
                onClick={() => setShowSetting(true)}
                className={` ${styles.SideMenu}`}
                src={SideMenu}
                alt=""
              />
            </div>
            <div
              onClick={() => setModalShow(true)}
              className={`d-flex align-items-center gap-2 ${styles.logout}`}
            >
              <div className="d-flex align-items-center">
                <img src={logout} alt="" />
              </div>
              <h5 className={` typography--class-footer ${styles.logoutText}`}>
                Log Out
              </h5>
            </div>
          </div>
        </nav>
      </header>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Settings
        showSetting={showSetting}
        setShowSetting={setShowSetting}
        setModalShow={setModalShow}
      />
    </>
  );
};

export default Header;
