import React from "react";
import styles from "../../../bedDetails.module.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import { Skeleton } from "@mui/material";
import TooltipComponent from "../../../../../components/Tooltip";

const History = ({ history, internalBedId, reposition, room }) => {
  return (
    <div className="col-12 col-lg-7 mt-5 mt-lg-0">
      <>
        <div
          style={{ margin: "2px" }}
          className="d-flex justify-content-between align-items-center px-3"
        >
          <div className="d-flex  align-items-center ">
            <h6 className="typography--class-h6 font-face-gr grey70 m-0">
              History
            </h6>
            <TooltipComponent
              title={
                "The History section displays a 12-hour history of alerts along with the alert time, response time, and name of the responded nurse. Helps in bringing accountability."
              }
              infoIcon={styles.infoIcon}
            />
          </div>
          <Link
            style={{ color: "#8d8d8d" }}
            className="typography--class-caption grey50"
            to={`/bed/${internalBedId}/history`}
            state={{ id: internalBedId, room: room }}
          >
            View all
          </Link>
        </div>

        <div
          className={`d-flex flex-column align-items-center mt-3 ${styles.box}`}
        >
          <div className="w-100">
            {history?.length === 0 ? (
              <>
                <div
                  className={`d-flex w-100 justify-content-center ${styles.borderB} ${styles.bgGrey05}`}
                >
                  {reposition ? (
                    <p
                      style={{ margin: "0.1rem 0" }}
                      className="typography--class-body cureousGrey opacity-50"
                    >
                      -
                    </p>
                  ) : (
                    <Skeleton
                      className={`${styles.skeleton}`}
                      variant="text"
                      animation="wave"
                      sx={{
                        fontSize: "1rem",
                        marginBottom: "2px",
                      }}
                      height="31px"
                    />
                  )}
                </div>
                <div
                  className={`d-flex bg-white w-100 ${styles.borderB} justify-content-between`}
                >
                  <div className="col-8 ps-4">
                    {reposition ? (
                      <p className="typography--class-body cureousGrey m-0">
                        -
                      </p>
                    ) : (
                      <Skeleton
                        className={`${styles.skeleton}`}
                        variant="text"
                        animation="wave"
                        sx={{
                          fontSize: "1rem",
                          marginTop: "1px",
                        }}
                        height="28px"
                      />
                    )}
                  </div>
                  <div className="col-3">
                    {reposition ? (
                      <p className="typography--class-body cureousGrey m-0">
                        -
                      </p>
                    ) : (
                      <Skeleton
                        className={`${styles.skeleton}`}
                        variant="text"
                        animation="wave"
                        sx={{
                          fontSize: "1rem",
                          marginTop: "1px",
                        }}
                        height="28px"
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`d-flex bg-white w-100 ${styles.borderB} justify-content-between`}
                >
                  <div className="col-8 ps-4">
                    {reposition ? (
                      <p className="typography--class-body cureousGrey m-0">
                        -
                      </p>
                    ) : (
                      <Skeleton
                        className={`${styles.skeleton}`}
                        variant="text"
                        animation="wave"
                        sx={{
                          marginTop: "1px",
                          fontSize: "1rem",
                        }}
                        height="28px"
                      />
                    )}
                  </div>
                  <div className="col-3 ">
                    {reposition ? (
                      <p className="typography--class-body cureousGrey m-0">
                        -
                      </p>
                    ) : (
                      <Skeleton
                        className={`${styles.skeleton}`}
                        variant="text"
                        animation="wave"
                        sx={{
                          marginTop: "1px",
                          fontSize: "1rem",
                        }}
                        height="28px"
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`d-flex bg-white w-100 ${styles.borderB} justify-content-between`}
                >
                  <div className="col-8 ps-4">
                    {reposition ? (
                      <p className="typography--class-body cureousGrey m-0">
                        -
                      </p>
                    ) : (
                      <Skeleton
                        className={`${styles.skeleton}`}
                        variant="text"
                        animation="wave"
                        sx={{
                          fontSize: "1rem",
                          marginTop: "1px",
                        }}
                        height="28px"
                      />
                    )}
                  </div>
                  <div className="col-3">
                    {reposition ? (
                      <p className="typography--class-body cureousGrey m-0">
                        -
                      </p>
                    ) : (
                      <Skeleton
                        className={`${styles.skeleton}`}
                        variant="text"
                        animation="wave"
                        sx={{
                          fontSize: "1rem",
                          marginTop: "1px",
                        }}
                        height="28px"
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`d-flex bg-white w-100 ${styles.borderB} justify-content-between`}
                >
                  <div className="col-8  ps-4">
                    {reposition ? (
                      <p className="typography--class-body cureousGrey m-0">
                        -
                      </p>
                    ) : (
                      <Skeleton
                        className={`${styles.skeleton}`}
                        variant="text"
                        animation="wave"
                        sx={{
                          fontSize: "1rem",
                          marginTop: "1px",
                        }}
                        height="28px"
                      />
                    )}
                  </div>
                  <div className="col-3">
                    {reposition ? (
                      <p className="typography--class-body cureousGrey m-0">
                        -
                      </p>
                    ) : (
                      <Skeleton
                        className={`${styles.skeleton}`}
                        variant="text"
                        animation="wave"
                        sx={{
                          fontSize: "1rem",
                          marginTop: "1px",
                        }}
                        height="28px"
                      />
                    )}
                  </div>
                </div>
              </>
            ) : (
              history?.slice(Math.max(history.length - 2, 0))?.map((x) => {
                const currentDate = moment().format("DD/MM/YYYY");
                const dateMoment = moment(x?.date, "DD/MM/YYYY");
                const currentDateMoment = moment(currentDate, "DD/MM/YYYY");
                return (
                  <>
                    <div
                      className={`d-flex w-100 justify-content-center ${styles.borderB} ${styles.bgGrey05}`}
                    >
                      <p
                        className={`typography--class-body cureousGrey opacity-50 ${styles.offline1}`}
                      >
                        {dateMoment.isSame(currentDateMoment, "day")
                          ? "Today"
                          : dateMoment.isSame(
                              moment().subtract(1, "day"),
                              "day"
                            )
                          ? "Yesterday"
                          : x?.date}
                      </p>
                    </div>
                    {x?.logs?.slice(0, 5).map((y, index) => {
                      return (
                        <>
                          {y.log !== "Offline" ? (
                            <>
                              <div
                                className={`d-flex bg-white w-100 ${styles.borderB} justify-content-between`}
                              >
                                <span
                                  className={`${
                                    y?.log === "Patient Call"
                                      ? styles.bgYellow
                                      : y?.log === "Reposition Alert"
                                      ? styles.bgBlue
                                      : y?.log === "Caremode"
                                      ? styles.bgGreen1
                                      : y?.log === "On Bed"
                                      ? styles.bgGray1
                                      : y?.log === "Reposition Done"
                                      ? styles.bgGreen1
                                      : y?.log === "Call Attended"
                                      ? styles.bgGreen1
                                      : y?.log === "Offline"
                                      ? styles.bgWhite1
                                      : styles.bgRed
                                  }`}
                                  style={{ width: "4%" }}
                                />
                                <div className="col-8 ps-2">
                                  <p
                                    className={`typography--class-body cureousGrey ${styles.offline1}`}
                                  >
                                    {y?.log == "Out of Bed"
                                      ? "Bed Exit"
                                      : y?.log}
                                  </p>
                                </div>
                                <div className="col-3">
                                  <p
                                    className={`typography--class-body cureousGrey ${styles.offline1}`}
                                  >
                                    {moment(y?.alert_time).format("hh:mm A")}
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              className={`d-flex bg-white w-100 ${styles.borderB} justify-content-center`}
                            >
                              <div>
                                <p
                                  className={`typography--class-body cureousGrey ${styles.offline}`}
                                >
                                  Device Offline (
                                  {moment(y?.alert_time).format("hh:mm A")} -{" "}
                                  {y?.response_dateTime
                                    ? moment(y?.response_dateTime).format(
                                        "hh:mm A"
                                      )
                                    : "NA"}
                                  )
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </>
                );
              })
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default History;
