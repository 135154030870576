import SideMenu from "./components/sidemenu/SideMenu";
import "./ToolBar.css";
import SideMenu1 from "../../assets/images/SideMenu.png";
import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import logout from "../../assets/images/logout.png";
import vector from "../../assets/images/vector.png";
import dash from "../../assets/images/dash.png";
import vector1 from "../../assets/images/vector1.png";
import dash1 from "../../assets/images/dash1.png";
import { getApi } from "../../utils/ServiceManger";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import Button from "../Button/index";
import styles from "./header.module.scss";
import Settings from "./components/Settings";

const Toolbar = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [showSetting, setShowSetting] = useState(false);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const logoutAccount = () => {
    setLoading(true);
    const fcmToken = localStorage.getItem("fcmToken");
    getApi(
      `logout/${fcmToken}`,
      (res) => {
        setLoading(false);
        if (res?.status) {
          localStorage.clear();
          window.location.reload();
          navigate("/login");
        }
      },
      (err) => console.log(err)
    );
  };

  const DateAndTime = () => {
    function calcFormattedDate(date) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      let time = date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      const formattedDate = `${
        months[date.getMonth()]
      }-${date.getDate()}-${date.getFullYear()} | ${time}`;
      // return "Oct-20-2021 | 3:53 PM";
      return formattedDate;
    }

    const [formattedDate, setFormattedDate] = React.useState(
      calcFormattedDate(new Date())
    );

    React.useEffect(() => {
      const timer = setInterval(() => {
        // Creates an interval which will update the current data every minute
        // This will trigger a rerender every component that uses this component
        setFormattedDate(calcFormattedDate(new Date()));
      }, 30 * 1000); //timeout is in milliseconds
      return () => {
        clearInterval(timer); // Return a function to clear the timer so that it will stop being called on unmount
      };
    });

    const view = (
      <>
        <p
          className={`typography--class-caption fw-normal mb-1 mb-md-2 grey50`}
        >
          {formattedDate}
        </p>
      </>
    );
    return view;
  };

  const MyVerticallyCenteredModal = (props) => {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName={`p-3 gap-3 ${styles.logoutModal}`}
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Modal.Header bsPrefix="d-flex justify-content-center">
              <p className="typography--class-caption grey70 m-0">
                Do you want to Log Out?
              </p>
            </Modal.Header>
            <Modal.Body bsPrefix="d-flex justify-content-center gap-4">
              <Button
                style={{
                  fontSize: "14px",
                  width: "98px",
                }}
                onClick={logoutAccount}
                text="Yes"
              />
              <Button
                style={{
                  fontSize: "14px",
                  width: "98px",
                }}
                text="No"
                variant="secondary"
                onClick={() => setModalShow(false)}
              />
            </Modal.Body>
          </>
        )}
      </Modal>
    );
  };

  const room_id = localStorage.getItem("room_id");

  return (
    <>
      <header className={`header `}>
        <nav className="d-flex justify-content-between">
          {!room_id ? (
            <div className="d-flex justify-content-between px-4">
              <Link to="/">
                <img src={logo} className={"appLogo"} alt="" />
              </Link>
              <div className={` nextTime`}>
                <h5 className={`typography--class-footer nextTimeData`}>
                  Admin View
                </h5>
              </div>
            </div>
          ) : (
            <Link to="/">
              <img src={logo} className={"appLogo"} alt="" />
            </Link>
          )}
          <div
            className={`${
              !room_id
                ? "dateWidth dateTimeWidth  dateAndTime"
                : " dateWidth  dateAndTime1"
            } `}
          >
            <DateAndTime />
          </div>
          <div className="toolbar_navigation-items ">
            <ul className="backgroundData3">
              {!room_id && (
                <>
                  {location.pathname === "/" && (
                    <>
                      <li
                        className="backgroundData"
                        style={{ paddingTop: "24px" }}
                      >
                        <div
                          className={`d-flex align-items-center gap-2 ${styles.logout1}`}
                        >
                          <div className="d-flex align-items-center">
                            <img src={dash1} alt="" />
                          </div>
                          <Link style={{ color: "#0762C8" }} to="/">
                            Dashboard
                          </Link>
                        </div>
                      </li>

                      <li
                        className="backgroundData1"
                        style={{ paddingTop: "22px" }}
                      >
                        <div
                          className={`d-flex align-items-center gap-2 ${styles.logout1}`}
                        >
                          <div className="d-flex align-items-center">
                            <img src={vector1} alt="" />
                          </div>
                          <a href="/kpi">Statistics</a>
                        </div>
                      </li>
                    </>
                  )}
                  {location.pathname == "/kpi" && (
                    <>
                      <li
                        className="backgroundData1"
                        style={{ paddingTop: "24px" }}
                      >
                        <div
                          className={`d-flex align-items-center gap-2 ${styles.logout1}`}
                        >
                          <div className="d-flex align-items-center">
                            <img src={dash} alt="" />
                          </div>
                          <Link to="/">Dashboard</Link>
                        </div>
                      </li>
                      <li
                        className="backgroundData"
                        style={{ paddingTop: "22px" }}
                      >
                        <div
                          className={`d-flex align-items-center gap-2 ${styles.logout1}`}
                        >
                          <div className="d-flex align-items-center">
                            <img src={vector} alt="" />
                          </div>
                          <a style={{ color: "#0762C8" }} href="/kpi">
                            Statistics
                          </a>
                        </div>
                      </li>
                    </>
                  )}
                </>
              )}

              <li
                className={location.pathname === "/" && "backgroundData2"}
                style={{ paddingTop: "20px" }}
              >
                <img
                  onClick={() => setShowSetting(true)}
                  className={`d-flex  ${styles.SideMenu1}`}
                  src={SideMenu1}
                  alt=""
                />
                <a>
                  <button
                    onClick={() => setModalShow(true)}
                    className={`d-flex align-items-center gap-2 ${styles.logout}`}
                  >
                    <div className="d-flex align-items-center">
                      <img src={logout} alt="" />
                    </div>
                    <h5
                      className={` typography--class-footer ${styles.logoutText}`}
                    >
                      Log Out
                    </h5>
                  </button>
                </a>
              </li>
            </ul>
          </div>
          <div className="toggle-btn px-4">
            <SideMenu click={props.drawerToggleClickHandler} />
          </div>
        </nav>
      </header>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Settings
        showSetting={showSetting}
        setShowSetting={setShowSetting}
        setModalShow={setModalShow}
      />
    </>
  );
};
export default Toolbar;
