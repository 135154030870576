import React, { useEffect, useState } from "react";
import styles from "./assignNurse.module.scss";
import { getApi } from "../../../../utils/ServiceManger";
import { useNavigate } from "react-router-dom";
import MorningIcon from "../../../../assets/images/morningIcon.png";
import EveningIcon from "../../../../assets/images/EveningIcon.png";
import NightIcon from "../../../../assets/images/night.png";
import AllotNurse from "./AllotNurse";
import { Skeleton } from "@mui/material";
import Shift from "./Shift";
import { toast } from "react-toastify";

function AssignNurse({ roomData }) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [beds, setBeds] = useState([]);
  const [nurses, setNurses] = useState([]);
  const [shift, setShift] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  //FOR ADD NURSE
  const room_id = localStorage.getItem("room_id");

  const getBeds = () => {
    getApi(
      `rooms/beds?room_id=${room_id ? room_id : roomData?.id}`,
      (res) => {
        if (res?.status) {
          setBeds(res?.data?.beds);
        } else if (res?.message === "Not valid token, Please log in first") {
          navigate("/login");
        }
      },
      (err) => {
        if (err?.message === "Request failed with status code 403") {
          navigate("/login");
        }
      }
    );
  };

  const getAllNurse = () => {
    setLoading(true);
    getApi(
      `nurses/room/${room_id ? room_id : roomData?.id}`,
      (res) => {
        setLoading(false);
        if (res?.status) {
          setNurses(res?.data?.nurses);
        } else if (res?.message === "Not valid token, Please log in first") {
          navigate("/login");
        }
      },
      (err) => {
        if (err?.message === "Request failed with status code 403") {
          navigate("/login");
        }
      }
    );
  };

  //for shift
  const hospital_id = localStorage.getItem("hospital_id");
  const Id = localStorage.getItem("Id");

  const changeShift = () => {
    getApi(
      `shifts/edit/${hospital_id ? hospital_id : Id}`,
      (res) => {
        if (res) {
          setShift(res?.data?.shift);
        } else if (res?.message === "Not valid token, Please log in first") {
          navigate("/login");
        }
      },
      (err) => {
        if (err?.message === "Request failed with status code 403") {
          navigate("/login");
        }
      }
    );
  };

  useEffect(() => {
    changeShift();
    const timer = setInterval(changeShift, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    getBeds();
    getAllNurse();
  }, [nurses.length, beds.length, roomData]);

  const nursesInBeds = beds.map((bed) => bed.nurse_name);

  const unassignedLength = nursesInBeds.filter(
    (value) => value === undefined || value === null
  ).length;

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    setSelectedOption(shift?.name);
  }, [shift]);

  useEffect(() => {
    if (unassignedLength > 0 && room_id) {
      const intervalId = setInterval(() => {
        allocationToast();
      }, 15 * 60 * 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [unassignedLength]);

  const allocationToast = () => {
    toast.success(
      <div className="d-flex align-items-center gap-3">
        <div className="w-100" style={{ paddingLeft: "10px" }}>
          <div className="justify-content-between border-0 pb-0 mb-1">
            <div className="d-flex align-items-center gap-2">
              <p className="typography--class-body cureousGrey fw-bold m-0">
                Nurse Allocation Reminder
              </p>
              <div className={`rounded-2 ${styles.toastStatusNurse}`}>
                {unassignedLength == 1 ? (
                  <p className="typography--class-footer toastRed m-1">
                    {unassignedLength} PATIENT
                  </p>
                ) : (
                  <p className="typography--class-footer toastRed m-1">
                    {unassignedLength} PATIENTS
                  </p>
                )}
              </div>
            </div>
          </div>
          <div>
            <p className={`typography--class-caption  m-0`}>
              Please allot nurses to the remaining patients
            </p>
          </div>
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        icon: false,
        toastId: 8,
        className: styles.customToastNurse,
        progressClassName: styles.customProgressBar,
      }
    );
  };

  return (
    <div>
      <div
        className={`d-flex flex-wrap justify-content-between ${styles.addNurse} `}
      >
        <div
          style={{ display: "flex", paddingLeft: "20px", paddingTop: "8px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              paddingLeft: "12px",
              marginRight: "20px",
            }}
          >
            {capitalizeFirstLetter(selectedOption) === "Morning" ? (
              <div>
                <img src={MorningIcon} className={styles.morningSmallIcon} />
              </div>
            ) : capitalizeFirstLetter(selectedOption) === "Evening" ? (
              <div>
                <img src={EveningIcon} className={styles.morningSmallIcon} />
              </div>
            ) : (
              capitalizeFirstLetter(selectedOption) === "Night" && (
                <div style={{ paddingTop: "1px" }}>
                  <img src={NightIcon} className={styles.morningSmallIcon} />
                </div>
              )
            )}

            {selectedOption ? (
              <button
                style={{ cursor: "auto" }}
                className={`${styles.addNursePara} `}
              >
                {capitalizeFirstLetter(selectedOption)} Shift
              </button>
            ) : (
              <button
                style={{ cursor: "auto" }}
                className={`${styles.addNursePara} `}
              >
                <Skeleton width={100} />
              </button>
            )}
          </div>
        </div>

        {unassignedLength > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              display: "flex",
              paddingLeft: "12px",
              marginRight: "20px",
              paddingTop: "4px",
              marginTop: "2px",
            }}
          >
            <div className={styles.redDot} />
            {unassignedLength == 1 ? (
              <button
                style={{ cursor: "auto" }}
                className={`${styles.addNursePara2} `}
              >
                {unassignedLength} Patient - Nurses Not Assigned
              </button>
            ) : (
              <button
                style={{ cursor: "auto" }}
                className={`${styles.addNursePara2} `}
              >
                {unassignedLength} Patients - Nurses Not Assigned
              </button>
            )}
          </div>
        )}

        <div
          onClick={handleShow}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
            display: "flex",
            paddingLeft: "12px",
            marginRight: "20px",
            paddingTop: "4px",
            marginTop: "1px",
            border: "1px solid #0762C8",
            borderRadius: "8px",
            height: "29px",
          }}
        >
          <button className={`${styles.addNursePara1} `}>Allot Nurse</button>
        </div>
      </div>
      <AllotNurse
        beds={beds}
        setBeds={setBeds}
        nurses={nurses}
        setNurses={setNurses}
        handleClose={handleClose}
        show={show}
        shift={shift}
        roomData={roomData}
        selectedOption={selectedOption}
        handleOptionClick={handleOptionClick}
      />
      {room_id && (
        <>
          <Shift handleShow={handleShow} shift={shift} />
        </>
      )}
    </div>
  );
}

export default AssignNurse;
