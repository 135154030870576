import React, { useState } from "react";
import styles from "../../history.module.scss";
import backButton from "../../../../assets/images/backButton.png";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "../../../../components/Card";
import cross from "../../../../assets/images/cross.png";
import correct from "../../../../assets/images/correct.png";
import "react-calendar/dist/Calendar.css";
import { Skeleton } from "@mui/material";
import Settings from "../Settings";
import TooltipComponent from "../../../../components/Tooltip";

const PatientDetails = ({
  room_id,
  patient,
  bed_id,
  bedStatus,
  bedSeconds,
  internalBedId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  //don't show the Modal if bed is unoccupied
  const [showPatientSettingsModal, setShowPatientSettingsModal] = useState(
    !location.state.isBedOccupied || false
  );
  const handleClose = () => setShowPatientSettingsModal(false);
  const handleShow = () => setShowPatientSettingsModal(true);

  if (!patient) {
    return <></>;
  }

  return (
    <>
      <div
        className={`col-12 col-md-3 d-flex flex-column mt-1 align-items-center ${styles.paddingLeft}`}
      >
        <button
          onClick={() => navigate(-2)}
          // className="d-flex align-self-baseline"
          style={{ marginRight: "200px" }}
        >
          <img className={styles.backButton} src={backButton} alt="" />
        </button>
        <h3
          className={`typography--class-h3 font-face-gr text-center mb-1  ${
            bedStatus === "On Bed" || !bedStatus ? "grey20" : "cureousGrey"
          }`}
        >
          {bedStatus ? (
            bed_id
          ) : (
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: "1rem",
                //   background: "gray",
              }}
              width={60}
              height="44px"
            />
          )}
        </h3>
        <h5 className="typography--class-h5 font-face-gr mb-0 mt-1 text-center cureousGrey">
          {bedStatus ? (
            patient?.name
          ) : (
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: "1rem",
              }}
              width={90}
            />
          )}
        </h5>
        <p className="typography--class-body text-center grey50">
          {bedStatus ? (
            `Patient ID: ${patient?.patient_id}`
          ) : (
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                fontSize: "1rem",
                //   background: "gray",
              }}
              width={120}
              // height="44px"
            />
          )}
        </p>
        <div className={`mb-3 ${styles.separator}`} />
        <Card
          patient={patient}
          link={""}
          status={bedStatus}
          timerStartTime={bedSeconds}
          big
        />
        <div className="d-flex gap-3 my-3 mt-4">
          {bedStatus ? (
            <div
              className={`d-flex gap-2 ${
                patient?.restricted_side === "Left" ||
                patient?.restricted_side === "Both"
                  ? styles.bgLightRed
                  : styles.bgGreen
              }`}
            >
              {patient?.restricted_side === "Left" ||
              patient?.restricted_side === "Both" ? (
                <div className={`${styles.crossDiv}`}>
                  <img src={cross} alt="" />
                </div>
              ) : (
                <div className={`${styles.correctDiv}`}>
                  <img src={correct} alt="" />
                </div>
              )}
              <p
                className={`font-face-gr ${
                  patient?.restricted_side === "Left" ||
                  patient?.restricted_side === "Both"
                    ? "red"
                    : "green"
                } m-0`}
              >
                L
              </p>
            </div>
          ) : (
            <Skeleton
              variant="rounder"
              animation="wave"
              sx={{
                borderRadius: "4px",
                fontSize: "1rem",
              }}
              width={60}
              height={26}
            />
          )}
          {bedStatus ? (
            <div
              className={`d-flex gap-2 ${
                patient?.restricted_side === "Right" ||
                patient?.restricted_side === "Both"
                  ? styles.bgLightRed
                  : styles.bgGreen
              }`}
            >
              {patient?.restricted_side === "Right" ||
              patient?.restricted_side === "Both" ? (
                <div className={`${styles.crossDiv}`}>
                  <img src={cross} alt="" />
                </div>
              ) : (
                <div className={`${styles.correctDiv}`}>
                  <img src={correct} alt="" />
                </div>
              )}
              <p
                className={`font-face-gr ${
                  patient?.restricted_side === "Right" ||
                  patient?.restricted_side === "Both"
                    ? "red"
                    : "green"
                } m-0`}
              >
                R
              </p>
            </div>
          ) : (
            <Skeleton
              variant="rounder"
              animation="wave"
              sx={{
                borderRadius: "4px",
                fontSize: "1rem",
              }}
              width={60}
              height={26}
            />
          )}
          {bedStatus && (
            <TooltipComponent
              title={"Displays which side the patient can/cannot be turned"}
              infoIcon={styles.infoIcon1}
            />
          )}
        </div>
        {bedStatus ? (
          <div>
            <button
              onClick={handleShow}
              className="typography--class-caption grey50"
            >
              Edit Patient Details
            </button>
          </div>
        ) : (
          <Skeleton
            variant="rounder"
            animation="wave"
            sx={{
              borderRadius: "4px",
              fontSize: "1rem",
            }}
            width={100}
          />
        )}
      </div>
      <Settings
        room_id={room_id}
        internalBedId={internalBedId}
        patient={patient}
        bedStatus={bedStatus}
        bedId={bed_id}
        showPatientSettingsModal={showPatientSettingsModal}
        handleClose={handleClose}
      />
    </>
  );
};

export default PatientDetails;
