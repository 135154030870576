import React, { useState } from "react";
import styles from "../../home.module.scss";
import { Offcanvas } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { postApi } from "../../../../utils/ServiceManger";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
const reload = () => window.location.reload();

function AddPatient({
  room_id,
  bedId,
  id,
  handleClose,
  show,
  navigate,
  deviceUniqueId,
}) {
  const [error, setError] = useState();

  const formik = useFormik({
    initialValues: {
      name: "",
      id: "",
      deviceId: "",
      age: "",
      weight: "",
      date: "",
      contactNumber: "",
      repositionPeriod: "2 hrs",
      restrictedSide: "None",
      sitingUpAlert: true,
      nurseAssistanceCall: true,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required("Please enter patient name"),
      id: Yup.string().required("Please provide patient's ID"),
      age: Yup.number().required("Please enter age of the patient"),
      weight: Yup.number().required("Please enter weight of the patient"),
      date: Yup.date().required("Please enter admission date of the patient"),
      contactNumber: Yup.string()
        .required("Please provide contact no")
        .matches(/^[6-9]{1}[0-9]{9}$/, "Please provide valid Indian no."),
      repositionPeriod: Yup.string().required(
        "Please select reposition period"
      ),
      restrictedSide: Yup.string().required("Please select restricted side"),
      sitingUpAlert: Yup.string().required("Please select sitingUpAlert"),
      nurseAssistanceCall: Yup.string().required(
        "Please select nurseAssistanceCall"
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("room_id", room_id);
      formData.append("bed_id", id);
      formData.append("name", values.name);
      formData.append("patient_id", values.id);
      formData.append("contact_no", values.contactNumber);
      formData.append("age", values.age);
      formData.append("weight", values.weight);
      formData.append(
        "reposition_period",
        values.repositionPeriod?.match(/\d+/)[0]
      );
      formData.append("restricted_side", values.restrictedSide);
      formData.append("is_siting_up_alert", values.sitingUpAlert);
      formData.append("nurse_assistance_call", values.nurseAssistanceCall);
      formData.append(
        "admission_date",
        moment(values?.date).format("DD/MM/YYYY")
      );
      postApi(
        "patients/add",
        formData,
        (res) => {
          if (res?.status) {
            resetForm({ values: "" });
            handleClose();
          } else if (res?.message === "Not valid token, Please log in first") {
            navigate("/login");
          } else {
            setError(res?.message);
          }
        },
        (err) => setError(err?.message.toString())
      );
      reload();
    },
  });

  function capitalizeFirstLetter(sentence) {
    const words = sentence.split(" ");

    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return "";
    });

    return capitalizedWords.join(" ");
  }

  return (
    <div>
      <Offcanvas
        placement="end"
        show={show}
        onHide={() => {
          formik.handleReset();
          setError("");
          handleClose();
        }}
        className={styles.Offcanvas1}
        backdropClassName={styles.Offcanvas}
      >
        <Offcanvas.Header bsPrefix={styles.OffcanvasHeader} closeButton>
          <Offcanvas.Title
            className={`typography--class-h5 font-face-gr ${styles.offCanvasTitle}`}
          >
            {bedId}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body bsPrefix="d-flex flex-column flex-md-row h-100">
          <div
            className={`col-12 col-md-6 p-4 p-lg-5 d-flex flex-column gap-1 gap-lg-4`}
          >
            <h6 className="typography--class-h6 font-face-gr grey70">
              Patient Details
            </h6>
            <Input
              label={`*Patient Name`}
              id="name"
              type="text"
              placeholder="Name"
              value={capitalizeFirstLetter(formik.values.name)}
              error={formik.errors.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Input
              label="*Patient ID"
              id="id"
              type="text"
              placeholder="Enter Patient ID"
              value={formik.values.id}
              error={formik.errors.id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Input
              predefined
              value={deviceUniqueId}
              label="Device ID"
              id="deviceId"
              placeholder={formik.values.deviceId}
            />
            <div className={`d-flex flex-row gap-1 gap-lg-4`}>
              <Input
                label="*Age"
                id="age"
                type="text"
                placeholder="Yrs"
                value={formik.values.age}
                error={formik.errors.age}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                  const ageValue = parseInt(sanitizedValue, 10);
                  if (
                    sanitizedValue === "" ||
                    (ageValue >= 0 && ageValue <= 150)
                  ) {
                    formik.setFieldValue("age", sanitizedValue);
                  }
                }}
                onBlur={formik.handleBlur}
              />
              <Input
                label="*Weight"
                id="weight"
                type="text"
                placeholder="Kg"
                value={formik.values.weight}
                error={formik.errors.weight}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                  const ageValue = parseInt(sanitizedValue, 10);
                  if (
                    sanitizedValue === "" ||
                    (ageValue >= 0 && ageValue <= 300)
                  ) {
                    formik.setFieldValue("weight", sanitizedValue);
                  }
                }}
                onBlur={formik.handleBlur}
              />
            </div>
            <Input
              // icon={true}
              label="*Admission Date"
              id="date"
              type="date"
              placeholder="dd/mm/yyyy"
              //TODO should this be className?
              max={new Date().toISOString().split("T")[0]}
              classname={
                formik.errors.date ? styles.datePicker1 : styles.datePicker
              }
              value={formik.values.date}
              error={formik.errors.date}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Input
              highPriority
              label={`*Emergency Contact`}
              id="contactNumber"
              type="tel"
              placeholder="+91 00000 00000"
              value={formik.values.contactNumber}
              error={formik.errors.contactNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className={styles.left}></div>
          <div className="col-12 col-md-6 p-4 p-lg-5 d-flex flex-column gap-1 gap-lg-4">
            <h6 className="typography--class-h6 font-face-gr grey70">
              Preferences
            </h6>

            <Input
              dropDown={[
                { name: "2 hrs" },
                { name: "3 hrs" },
                { name: "4 hrs" },
              ]}
              label="Reposition Period"
              id="repositionPeriod"
              placeholder="2 hrs (Recommended)"
              error={formik.errors.repositionPeriod}
              onChange={(e) =>
                formik.setFieldValue(
                  "repositionPeriod",
                  document.getElementById("repositionPeriod").value
                )
              }
            />
            <Input
              dropDown={[
                { name: "None" },
                { name: "Left" },
                { name: "Right" },
                { name: "Both" },
              ]}
              label="Patient Restricted Side"
              id="restrictedSide"
              placeholder="None"
              error={formik.errors.restrictedSide}
              onChange={(e) => {
                const selectedValue =
                  document.getElementById("restrictedSide").value;
                formik.setFieldValue("restrictedSide", selectedValue || "None");
              }}
            />
            <Input
              dropDown={[{ name: "Enable" }, { name: "Disable" }]}
              label="Nurse Assistance Call"
              id="nurseAssistanceCall"
              error={formik.errors.nurseAssistanceCall}
              onChange={(e) => {
                formik.setFieldValue(
                  "nurseAssistanceCall",
                  document.getElementById("nurseAssistanceCall").value ===
                    "Enable"
                    ? true
                    : false
                );
              }}
            />
            <Input
              dropDown={[{ name: "Enable" }, { name: "Disable" }]}
              label="Device Side Alarm"
              id="sitingUpAlert"
              error={formik.errors.sitingUpAlert}
              onChange={(e) => {
                formik.setFieldValue(
                  "sitingUpAlert",
                  document.getElementById("sitingUpAlert").value === "Enable"
                    ? true
                    : false
                );
              }}
            />
            <div className="d-flex justify-content-evenly mt-2">
              <Button
                onClick={formik.handleSubmit}
                type="submit"
                text="Admit"
              />
              <Button
                text="Cancel"
                onClick={() => {
                  formik.handleReset();
                  setError("");
                  handleClose();
                }}
                variant="secondary"
              />
            </div>
            {error ? (
              <small className={`typography--class-footer red`}>{error}</small>
            ) : (
              <></>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default AddPatient;
