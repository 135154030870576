import moment from "moment";
import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...props }) => {
  const token = localStorage.getItem("token");
  const expiry = localStorage.getItem("expiry");

  return token && new Date().getTime() < new Date(expiry).getTime() ? (
    <Component {...props} />
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
